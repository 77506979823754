'use client'

import type React from 'react'

import { getTestProps, type TestIds } from '../../../utils/testing.ts'
import { CardProvider, useCard, type UseCardProps } from '../use-card.ts'
import { ImageCardContent } from './image-card-content.tsx'
import { ImageCardDescription } from './image-card-description.tsx'
import { ImageCardFooter } from './image-card-footer.tsx'
import { ImageCardImage } from './image-card-image.tsx'
import { ImageCardSubtitle } from './image-card-subtitle.tsx'
import { ImageCardTitle } from './image-card-title.tsx'

type ImageCardType = 'div' | 'li'

type ImageCardProps = Readonly<
  UseCardProps &
    TestIds & {
      as?: React.ElementType
      children: React.ReactNode
    }
>

const ImageCardComponent = ({
  as: Component = 'div',
  children,
  dataTestId,
  dataTrackingId,
  ...useCardProps
}: ImageCardProps) => {
  const context = useCard(useCardProps)
  const { id, titleId } = context
  return (
    <CardProvider value={context}>
      <Component
        id={id}
        aria-describedby={titleId}
        role="group"
        className="flex w-[550px] flex-col overflow-hidden rounded-xl bg-white"
        {...getTestProps({ dataTestId, dataTrackingId })}
      >
        {children}
      </Component>
    </CardProvider>
  )
}

const ImageCardNamespace = Object.assign(ImageCardComponent, {
  Image: ImageCardImage,
  Content: ImageCardContent,
  Description: ImageCardDescription,
  Title: ImageCardTitle,
  Subtitle: ImageCardSubtitle,
  Footer: ImageCardFooter,
})

export {
  ImageCardNamespace as ImageCard,
  type ImageCardProps,
  type ImageCardType,
}
