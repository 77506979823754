import type { PropsWithChildren } from 'react'

const BladeTag = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex shrink-0 flex-col items-end justify-center gap-2">
      {children}
    </div>
  )
}

export { BladeTag }
