import type { VideoDuration } from '@mntn-dev/domain-types'
import { Stack } from '../../stack/stack.tsx'
import { Text } from '../../text/text.tsx'
import { BladeMediaDuration } from './blade-media-duration.tsx'

type MediaInfo = {
  description?: string
  duration?: VideoDuration
  timeInterval: string
}

type BladeMediaInfoProps = {
  media: MediaInfo
  t: (key: 'or') => string
}

const BladeMediaInfo = ({
  media: { duration, description, timeInterval },
  t,
}: BladeMediaInfoProps) => {
  return (
    <Stack direction="col" gap="1">
      <Text textColor="primary" fontSize="med" fontWeight="bold">
        {description}
      </Text>
      <Stack gap="2">
        {duration !== undefined && (
          <Stack alignItems="baseline">
            <BladeMediaDuration
              duration={duration}
              timeInterval={timeInterval}
              t={t}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export { BladeMediaInfo, type BladeMediaInfoProps, type MediaInfo }
