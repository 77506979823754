'use client'

import { Description, Label, Radio } from '@headlessui/react'
import { cn } from '@mntn-dev/ui-utilities'

import { themeBackgroundMap, themeBorderColorMap } from '@mntn-dev/ui-theme'

import { type TestIds, getTestProps } from '../../utils'
import { Heading } from '../heading'
import { Text } from '../text'

export type RadioBlocksItemProps = TestIds & {
  disabled?: boolean
  value: string
  title: string
  subtitle?: string
}

export const RadioBlocksItem = ({
  disabled,
  value,
  title,
  subtitle,
  dataTestId,
  dataTrackingId,
}: RadioBlocksItemProps) => {
  return (
    <Radio
      value={value}
      disabled={disabled}
      className={({ checked, disabled }) =>
        cn(
          checked
            ? 'border-primary-blue shadow-glow-blue-lg z-10'
            : `${themeBorderColorMap.muted}`,
          disabled
            ? 'cursor-not-allowed opacity-30'
            : 'active:border-primary-blue hover:shadow-glow-blue-lg transform transition duration-200 hover:opacity-90 active:shadow-none',
          `inline-flex items-center justify-center gap-2 rounded border ${themeBackgroundMap.container} cursor-pointer p-6`
        )
      }
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {({ checked, disabled }) => (
        <span className="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-1">
          <span className="inline-flex items-center justify-start gap-2 self-stretch">
            <span className="relative flex h-4 w-4">
              <span
                className={cn(
                  checked ? 'bg-primary-blue' : 'border border-white/30',
                  'absolute left-0 top-0 h-4 w-4 rounded-full'
                )}
                aria-hidden="true"
              />
              {checked && (
                <span className="bg-primary-blue border-3 absolute left-px top-px h-3.5 w-3.5 rounded-full" />
              )}
            </span>

            <span>
              <Label>
                <Heading level="h4" textColor="primary">
                  {title}
                </Heading>
              </Label>
            </span>
          </span>

          <span
            className={cn(
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
              'inline-flex items-start justify-start self-stretch pl-6'
            )}
          >
            {subtitle && (
              <Description>
                <Text fontSize="sm" fontWeight="light" textColor="tertiary">
                  {subtitle}
                </Text>
              </Description>
            )}
          </span>
        </span>
      )}
    </Radio>
  )
}
