'use client'

import type { PropsWithChildren } from 'react'
import { Heading, type HeadingProps } from '../heading'

const ModalHeadingBreadcrumb = ({
  children,
  fontSize = '2xl',
}: PropsWithChildren<HeadingProps>) => {
  return (
    <Heading textColor="secondary" fontSize={fontSize} className="inline">
      {children}
    </Heading>
  )
}

export { ModalHeadingBreadcrumb }
