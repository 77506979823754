import type { PropsWithChildren } from 'react'

import { Heading } from '../../heading/heading.tsx'
import { Text } from '../../text/text.tsx'

type InformationalPriceProps = PropsWithChildren<
  Readonly<{
    title?: string
  }>
>

const InformationalPrice = ({ children, title }: InformationalPriceProps) => {
  return (
    <div className="flex items-end gap-1 self-stretch">
      <span className="flex items-end uppercase">
        <Text fontSize="xs" fontWeight="semibold" textColor="tertiary">
          {title}
        </Text>
      </span>
      <div className="flex items-center leading-none">
        <Heading fontSize="4xl" fontWeight="bold">
          {children}
        </Heading>
      </div>
    </div>
  )
}

export { InformationalPrice, type InformationalPriceProps }
