import { Icon } from '../icon/icon.tsx'
import { useFormFieldContext } from './use-form-field.ts'

type FormFieldErrorProps = Readonly<{
  children?: string
}>

const FormFieldError = ({ children }: FormFieldErrorProps) => {
  const context = useFormFieldContext()

  if (!context?.hasError) {
    return null
  }

  const errorProps = context?.getErrorProps() ?? {}

  return (
    <div className="flex gap-1 items-center">
      <Icon
        name="ExclamationCircleIcon"
        color="negative"
        fill="solid"
        size="sm"
      />
      <p {...errorProps} className="text-primary-red text-sm">
        {children}
      </p>
    </div>
  )
}
export { FormFieldError, type FormFieldErrorProps }
