import type React from 'react'
import * as OutlineHeroIcons from '@heroicons/react/24/outline'
import * as SolidHeroIcons from '@heroicons/react/24/solid'

import { isHeroIconName } from './hero/types/names.ts'
import { svgIconMap } from './svg/types/maps.ts'
import { isSvgIconName } from './svg/types/names.ts'
import type { IconProps } from './types/props.ts'

export const IconComponentFactory = ({
  name,
  fill,
  size,
  color,
}: IconProps): React.ComponentType<React.SVGProps<SVGSVGElement>> => {
  if (isHeroIconName(name)) {
    return fill === 'solid' ? SolidHeroIcons[name] : OutlineHeroIcons[name]
  }

  if (isSvgIconName(name)) {
    const IconComponent = svgIconMap[name]
    return (props) => (
      <IconComponent {...{ ...props, size, fill, name, color }} />
    )
  }

  return () => null
}
