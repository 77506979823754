import type { SvgIconProps } from '../types/props.ts'

const Projects = (props: SvgIconProps) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    aria-label="Projects icon"
    role="img"
    width="1em"
    height="1em"
  >
    <path
      d="M30.9,2.6v14.6c-1.3-0.8-2.8-1.2-4.5-1.2c-4.9,0-8.9,4-8.9,8.9c0,0.5,0.1,1,0.1,1.5H4.1c-0.8,0-1.5-0.3-2.1-0.9
	c-0.6-0.6-0.9-1.3-0.9-2.1V5.6C1.1,4.8,1.4,4,2,3.5s1.3-0.9,2.1-0.9h1.5l3,6H13l-3-6h3l3,6h4.5l-3-6h3l3,6h4.5l-3-6H30.9z
	 M23.4,29.4l7.4-4.5l-7.4-4.5V29.4z"
    />
  </svg>
)

export { Projects }
