'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type { AbstractImage } from '@mntn-dev/ui-utilities'
import { type PropsWithChildren, useState } from 'react'
import { LoadingSpinner } from '../loading/loading-spinner.tsx'

type BladeImageProps = Readonly<
  PropsWithChildren<{
    src: string
    alt: string
    width: number
    height: number
    image: AbstractImage
  }>
>
export const BladeImage = ({
  src,
  alt,
  width,
  height,
  image: Image,
}: BladeImageProps) => {
  const [loading, setLoading] = useState<boolean>(true)

  return (
    <span className="overflow-hidden rounded-l-lg bg-gray-800 opacity-90 relative">
      <>
        <Image
          className={cn(
            { invisible: loading },
            'h-[revert-layer] object-cover'
          )}
          src={src}
          alt={alt}
          width={width}
          height={height}
          onLoad={() => setLoading(false)}
        />
        {loading && (
          <div
            className={cn(
              'w-full h-full flex items-center justify-center absolute left-0 top-0'
            )}
          >
            <LoadingSpinner className="text-primary-blue h-12 w-12" />
          </div>
        )}
      </>
    </span>
  )
}
