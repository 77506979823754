'use client'

import { type TestIds, getTestProps } from '../../utils'
import { Button, type ButtonProps } from '../buttons/button.tsx'

type TextareaFormToolbarActionButtonProps = Readonly<
  TestIds & Omit<ButtonProps, 'size'>
>

const TextareaFormToolbarActionButton = ({
  children,
  dataTestId,
  dataTrackingId,
  ...otherProps
}: TextareaFormToolbarActionButtonProps) => {
  return (
    <Button
      type="submit"
      size="sm"
      {...otherProps}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
    </Button>
  )
}

export {
  TextareaFormToolbarActionButton,
  type TextareaFormToolbarActionButtonProps,
}
