'use client'

import type { PropsWithChildren } from 'react'

type ModalOverlineProps = Readonly<PropsWithChildren>

const ModalOverline = ({ children }: ModalOverlineProps) => {
  return (
    <div className="mb-8 flex flex-col gap-2 self-start text-left w-full">
      {children}
    </div>
  )
}

export { ModalOverline, type ModalOverlineProps }
