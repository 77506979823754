'use client'

import { cn } from '@mntn-dev/ui-utilities'

import { themeTextColorMap } from '@mntn-dev/ui-theme'
import { useMemo } from 'react'
import { type TestIds, getTestProps } from '../../../utils/index.ts'
import { Decorator } from '../../decorator/decorator.tsx'
import { Icon, type IconName, type IconProps } from '../../icon/index.ts'
import { NavigationSelectIndicator } from './navigation-select-indicator.tsx'

type NavigationIconItemProps = Readonly<
  {
    id: string
    iconName: IconName
    unreadNotificationCount?: number
    selected: (id: string) => boolean
    onClick: () => void
    title?: string
    disabled?: boolean
  } & TestIds
> &
  Pick<IconProps, 'fill'>

const NavigationIconItem = ({
  id,
  iconName,
  unreadNotificationCount = 0,
  selected: selectedFn,
  disabled,
  onClick,
  title,
  dataTestId,
  dataTrackingId,
  fill,
}: NavigationIconItemProps) => {
  const selected = useMemo(() => selectedFn(id), [id, selectedFn])

  const icon = useMemo(
    () => (
      <Icon
        name={iconName}
        disabled={disabled}
        fill={fill}
        size="xl"
        color={selected ? 'info' : 'secondary'}
      />
    ),
    [disabled, fill, iconName, selected]
  )

  return (
    <li className="group relative">
      <button
        id={id}
        disabled={disabled}
        onClick={onClick}
        className="flex h-16 w-16 flex-col items-center justify-center"
        type="button"
        {...getTestProps({ dataTestId, dataTrackingId })}
      >
        <div className={cn({ 'drop-shadow-glow-blue-light': selected })}>
          {unreadNotificationCount > 0 ? (
            <Decorator
              size="xs"
              offsetX="-1"
              offsetY="-1"
              anchor="top-right"
              value={unreadNotificationCount}
            >
              {icon}
            </Decorator>
          ) : (
            icon
          )}
        </div>
        {selected && <NavigationSelectIndicator />}
      </button>
      {title && (
        <span
          className={`absolute -bottom-1 left-1/2 -translate-x-2/4 whitespace-nowrap text-[10px] font-bold uppercase ${themeTextColorMap.secondary} overflow-none opacity-0 transition-all duration-300 ease-out group-hover:opacity-100`}
        >
          {title}
        </span>
      )}
    </li>
  )
}

export { NavigationIconItem, type NavigationIconItemProps }
