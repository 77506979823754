import type { IconSize } from './core.ts'

export const iconSizeClassMap: Record<IconSize, string> = {
  '5xs': 'h-1 w-1',
  '4xs': 'h-1.5 w-1.5',
  '3xs': 'h-2 w-2',
  '2xs': 'h-2.5 w-2.5',
  xs: 'h-3 w-3',
  sm: 'h-4 w-4',
  md: 'h-5 w-5',
  lg: 'h-6 w-6',
  xl: 'h-8 w-8',
  '2xl': 'h-12 w-12',
  '3xl': 'h-16 w-16',
  '4xl': 'h-20 w-20',
  '5xl': 'h-24 w-24',
  '6xl': 'h-28 w-28',
  '7xl': 'h-32 w-32',
  full: 'h-full w-full',
}
