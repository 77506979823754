'use client'

import type { ReactNode } from 'react'

type NavigationHeaderProps = {
  children: ReactNode
}

const NavigationHeader = ({ children }: NavigationHeaderProps) => {
  return (
    <div className="my-2.5 flex h-16 shrink-0 items-center">{children}</div>
  )
}

export { NavigationHeader, type NavigationHeaderProps }
