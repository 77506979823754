import { cn } from '@mntn-dev/ui-utilities'

import { themeTextColorMap } from '@mntn-dev/ui-theme'

import { Heading } from '../heading'

export const BladeCheckoutItem = ({
  price,
  priceText,
}: {
  price?: string
  priceText?: string
  onRemove?: () => void
}) => {
  return (
    <span className="min-w-4 items-center gap-2 pl-1">
      {priceText && (
        <span
          className={cn(
            `text-2xs font-semibold ${themeTextColorMap.tertiary} uppercase`,
            { 'relative bottom-[3px]': !price }
          )}
        >
          {priceText}
        </span>
      )}
      {price && (
        <span className="relative bottom-px flex items-center justify-end gap-px">
          <Heading fontSize="xl" fontWeight="bold">
            {price}
          </Heading>
        </span>
      )}
    </span>
  )
}
