'use client'

import { useCallback } from 'react'

import { createContext } from '../../../utils/context.ts'
import { getTestProps, type TestIds } from '../../../utils/testing.ts'

type UseConfirmationModalProps = TestIds & {
  onClose: () => void
  onConfirm: () => void
}

function useConfirmationModal(props: UseConfirmationModalProps) {
  const { onClose, onConfirm, dataTestId, dataTrackingId } = props || {}

  const getConfirmationModalProps = useCallback(
    () => ({
      onClose: onClose,
      onConfirm: onConfirm,
      ...getTestProps({
        dataTestId,
        dataTrackingId,
      }),
    }),
    [onClose, onConfirm, dataTestId, dataTrackingId]
  )

  return {
    getConfirmationModalProps,
  }
}

type UseConfirmationModalReturn = ReturnType<typeof useConfirmationModal>

const [ConfirmationModalProvider, useConfirmationModalContext] =
  createContext<UseConfirmationModalReturn>({
    name: 'ConfirmationModalContext',
  })

export {
  ConfirmationModalProvider,
  type UseConfirmationModalProps,
  type UseConfirmationModalReturn,
  useConfirmationModal,
  useConfirmationModalContext,
}
