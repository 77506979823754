import type { MouseEventHandler, PropsWithChildren } from 'react'

import { Button } from '../../buttons/button.tsx'
import { Text } from '../../text/text.tsx'

type InformationalFooterProps = PropsWithChildren<
  Readonly<{
    buttonText?: string
    onClick?: MouseEventHandler<HTMLButtonElement>
  }>
>

const InformationalFooter = ({
  children,
  buttonText,
  onClick,
}: InformationalFooterProps) => {
  return (
    <>
      <Button size="lg" iconRight="InformationCircleIcon" onClick={onClick}>
        {buttonText}
      </Button>
      <div className="flex items-center justify-end gap-2 self-stretch uppercase leading-[150%]">
        <Text textColor="tertiary" fontSize="sm" fontWeight="medium">
          {children}
        </Text>
        <div className="h-6 w-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            role="img"
            aria-label="eye"
          >
            <path
              d="M22.0832 11.3953C19.8613 6.71484 16.5027 4.35938 12.0004 4.35938C7.49569 4.35938 4.13944 6.71484 1.91757 11.3977C1.82845 11.5864 1.78223 11.7925 1.78223 12.0012C1.78223 12.2099 1.82845 12.416 1.91757 12.6047C4.13944 17.2852 7.49804 19.6406 12.0004 19.6406C16.5051 19.6406 19.8613 17.2852 22.0832 12.6023C22.2637 12.2227 22.2637 11.782 22.0832 11.3953ZM12.0004 17.9531C8.21991 17.9531 5.45194 16.0359 3.4996 12C5.45194 7.96406 8.21991 6.04688 12.0004 6.04688C15.7808 6.04688 18.5488 7.96406 20.5012 12C18.5512 16.0359 15.7832 17.9531 12.0004 17.9531ZM11.9066 7.875C9.6285 7.875 7.78163 9.72188 7.78163 12C7.78163 14.2781 9.6285 16.125 11.9066 16.125C14.1848 16.125 16.0316 14.2781 16.0316 12C16.0316 9.72188 14.1848 7.875 11.9066 7.875ZM11.9066 14.625C10.4558 14.625 9.28163 13.4508 9.28163 12C9.28163 10.5492 10.4558 9.375 11.9066 9.375C13.3574 9.375 14.5316 10.5492 14.5316 12C14.5316 13.4508 13.3574 14.625 11.9066 14.625Z"
              fill="#41C0FF"
            />
          </svg>
        </div>
      </div>
    </>
  )
}

export { InformationalFooter, type InformationalFooterProps }
