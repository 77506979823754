import { forwardRef as reactForwardRef } from 'react'
import type React from 'react'

export type ForwardRefComponent<T, P> = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<P> & React.RefAttributes<T>
> & {
  id?: string
}

export function forwardRef<T extends Element, P extends object>(
  component: React.ForwardRefRenderFunction<T, P>
) {
  return reactForwardRef(component) as ForwardRefComponent<T, P>
}
