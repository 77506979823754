import type { HTMLAttributes, PropsWithChildren } from 'react'

import { Text } from '../../text/text.tsx'

type InformationalStatusProps = PropsWithChildren<
  Readonly<HTMLAttributes<HTMLElement>>
>

const InformationalStatus = ({ children }: InformationalStatusProps) => {
  return (
    <div className="flex items-start gap-2 self-stretch">
      <div className="text-primary-blue shrink-0 grow basis-0 gap-2 uppercase leading-[150%]">
        <Text fontSize="xs" fontWeight="semibold">
          {children}
        </Text>
      </div>
    </div>
  )
}

export { InformationalStatus, type InformationalStatusProps }
