'use client'
import { themeBackgroundMap, themeBorderColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'

export const MediaCardAsset = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={cn(
        'relative border rounded-lg w-full h-full p-2',
        themeBackgroundMap['container-tertiary'],
        themeBorderColorMap.muted
      )}
    >
      {children}
    </div>
  )
}
