'use client'

import { Transition } from '@headlessui/react'
import { type PropsWithChildren, createContext, useContext } from 'react'
import { Toaster, resolveValue } from 'react-hot-toast'

import { showToast } from './functions.tsx'

export type ToasterContextType = {
  showToast: typeof showToast
}

const ToasterContext = createContext<ToasterContextType>({ showToast })

export const useToast = () => useContext(ToasterContext)

export const ToastProvider = ({ children }: PropsWithChildren) => {
  return (
    <ToasterContext.Provider value={{ showToast }}>
      {children}
      <Toaster position="top-right" containerStyle={{ zIndex: '9999999' }}>
        {(t) => (
          <Transition
            appear
            show={t.visible}
            as="div"
            className="flex transform"
            enter="transition-all duration-150"
            enterFrom="opacity-0 scale-50"
            enterTo="opacity-100 scale-100"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-75"
          >
            {resolveValue(t.message, t)}
          </Transition>
        )}
      </Toaster>
    </ToasterContext.Provider>
  )
}
