import { useMemo } from 'react'

import { themeIconColorMap, themeTextColorMap } from '@mntn-dev/ui-theme'

import { cn } from '@mntn-dev/ui-utilities'
import { getTestProps } from '../../utils'
import { IconComponentFactory } from './factory.tsx'
import { isHeroIconName } from './hero/types/names.ts'
import { iconSizeClassMap } from './types/maps.ts'
import type { IconProps } from './types/props.ts'

const Icon = ({
  fill,
  name,
  color,
  size,
  className,
  disabled,
  dataTestId,
  dataTrackingId,
  ...props
}: IconProps) => {
  const IconComponent = useMemo(
    () => IconComponentFactory({ name, fill, size, color }),
    [name, fill, size, color]
  )

  const colorName = disabled ? 'disabled' : color ?? 'primary'
  const colorClasses = isHeroIconName(name)
    ? themeTextColorMap[colorName]
    : themeIconColorMap[colorName]

  return (
    <IconComponent
      {...props}
      className={cn(
        'shrink-0',
        iconSizeClassMap[size],
        colorClasses,
        className
      )}
      {...getTestProps({ dataTestId, dataTrackingId })}
    />
  )
}

export { Icon, type IconProps }
