'use client'

type StatusDescriptionProps = Readonly<{
  description?: string
}>

const StatusDescription = ({ description }: StatusDescriptionProps) => {
  return <div>{description}</div>
}

export { StatusDescription, type StatusDescriptionProps }
