'use client'

import type { PropsWithChildren } from 'react'

import { Stack } from '../../stack/index.ts'

const DialogModalContent = ({ children }: PropsWithChildren) => {
  return (
    <Stack direction="col" gap="4">
      {children}
    </Stack>
  )
}

export { DialogModalContent }
