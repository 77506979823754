import { Icon } from '../icon'
import { Text } from '../text'

type BladeChatProps = Readonly<{
  numberOfComments?: number
}>
export const BladeChat = ({ numberOfComments = 0 }: BladeChatProps) => {
  const iconColor = numberOfComments > 0 ? 'info' : 'disabled'
  return (
    <span className="flex w-8 justify-center">
      <span className="text-med flex w-[30px] items-center justify-between gap-1 font-medium">
        <Text textColor={iconColor}>{numberOfComments}</Text>
        <Icon fill="solid" name="BoltIcon" size="sm" color={iconColor} />
      </span>
    </span>
  )
}
