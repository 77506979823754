import { MenuButton } from '@headlessui/react'
import { Fragment } from 'react'
import { type TestIds, getTestProps } from '../../utils'
import { Button } from '../buttons/button.tsx'

type PopoutButtonProps = {} & TestIds

export const PopoutButton = ({
  dataTestId,
  dataTrackingId,
}: PopoutButtonProps) => {
  return (
    <MenuButton as={Fragment}>
      <Button
        variant="text"
        size="sm"
        onClick={(e) => e.stopPropagation()}
        {...getTestProps({ dataTestId, dataTrackingId })}
        iconLeft="EllipsisVerticalIcon"
      />
    </MenuButton>
  )
}
