'use client'

import { useEffect, useState } from 'react'

import { makeRange } from '@mntn-dev/utilities'

import { StarIcon } from '../icon/custom'

type RatingStarsProps = Readonly<{
  total: number
  rating?: number
  onChange: (rating: number) => void
}>

const RatingStars = ({
  total,
  rating: initialRating,
  onChange,
}: RatingStarsProps) => {
  const [rating, setRating] = useState<number>(initialRating ?? 0)

  const handleStarClick = (i: number) => () => {
    setRating(i + 1)
  }

  useEffect(() => {
    onChange(rating)
  }, [onChange, rating])

  return (
    <div className="flex">
      {makeRange(total).map((i) => (
        <StarIcon
          key={i}
          className="cursor-pointer"
          size="lg"
          active={rating > i}
          onClick={handleStarClick(i)}
        />
      ))}
    </div>
  )
}

export { RatingStars, type RatingStarsProps }
