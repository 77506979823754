import { useState } from 'react'

import {
  type ThemeElevation,
  type ThemeGapValue,
  getElevationClassName,
  getGapClassName,
} from '@mntn-dev/ui-theme'

import { type UseSpacingProps, useSpacing } from '../../hooks/use-spacing.ts'
import { createContext } from '../../utils/context.ts'

type UseSurfaceProps = UseSpacingProps & {
  elevation?: ThemeElevation
  gap?: ThemeGapValue
}

function useSurface(props: UseSurfaceProps) {
  const [hasSurfaceChildren, setHasSurfaceChildren] = useState(false)
  const { marginClassName, paddingClassName } = useSpacing(props)

  const elevationClassName = getElevationClassName(props.elevation)
  const gapClassName = getGapClassName({ gap: props.gap })

  return {
    elevationClassName,
    gapClassName,
    hasSurfaceChildren,
    marginClassName,
    paddingClassName,
    setHasSurfaceChildren,
  }
}

const [SurfaceProvider, useSurfaceContext] = createContext<
  ReturnType<typeof useSurface>
>({ name: 'SurfaceContext' })

export { SurfaceProvider, useSurface, useSurfaceContext, type UseSurfaceProps }
