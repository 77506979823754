import type { SvgIconProps } from '../types/props.ts'

const BarChart = (props: SvgIconProps) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    aria-label="Bar chart icon"
    role="img"
    width="1em"
    height="1em"
  >
    <path
      d="M3,7.3C3,4.9,4.9,3,7.3,3h17.3C27.1,3,29,4.9,29,7.3v17.3c0,2.4-1.9,4.3-4.3,4.3H7.3C4.9,29,3,27.1,3,24.7V7.3z M7.3,5.2
	c-1.2,0-2.2,1-2.2,2.2v17.3c0,1.2,1,2.2,2.2,2.2h17.3c1.2,0,2.2-1,2.2-2.2V7.3c0-1.2-1-2.2-2.2-2.2H7.3z M22.5,8.4
	c0.6,0,1.1,0.5,1.1,1.1v13c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1v-13C21.4,8.9,21.9,8.4,22.5,8.4z M18.2,11.7
	c0.6,0,1.1,0.5,1.1,1.1v9.8c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1v-9.8C17.1,12.2,17.6,11.7,18.2,11.7z M13.8,14.9
	c0.6,0,1.1,0.5,1.1,1.1v6.5c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1V16C12.8,15.4,13.2,14.9,13.8,14.9z M9.5,18.2
	c0.6,0,1.1,0.5,1.1,1.1v3.2c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1v-3.2C8.4,18.7,8.9,18.2,9.5,18.2z"
    />
  </svg>
)

export { BarChart }
