'use client'

import type { HTMLProps } from 'react'

export const InfoModalBody = ({
  className = '',
  ...rest
}: HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className={`flex flex-col items-center gap-8 ${className}`}
      {...rest}
    />
  )
}
