'use client'

import { Text, type TextProps } from '../text/index.ts'

type FeedQuoteProps = TextProps

const FeedQuote = ({ children, ...props }: FeedQuoteProps) => (
  <Text className="italic break-words whitespace-normal" {...props}>
    {children}
  </Text>
)

export { FeedQuote, type FeedQuoteProps }
