'use client'

import {
  type ReactNode,
  type UIEvent,
  createContext,
  useContext,
  useState,
} from 'react'

type SidebarLayoutMainProps = {
  children: ReactNode
}

const ScrollContext = createContext<{ scrollY: number }>({
  scrollY: 0,
})

const useScroll = () => useContext(ScrollContext)

const SidebarLayoutMain = ({ children }: SidebarLayoutMainProps) => {
  const [scrollY, setScrollY] = useState<number>(0)

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    setScrollY(event.currentTarget.scrollTop)
  }

  return (
    <ScrollContext.Provider value={{ scrollY }}>
      <div
        onScroll={handleScroll}
        className="relative lg:pl-20 overflow-auto h-full stable-scroll"
      >
        {children}
      </div>
    </ScrollContext.Provider>
  )
}

export { SidebarLayoutMain, useScroll }
