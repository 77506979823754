import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'

import { type UseHeightProps, useHeight } from '../../hooks/use-height.ts'
import { type UseWidthProps, useWidth } from '../../hooks/use-width.ts'
import { FormFieldControl } from './form-field-control.tsx'
import { FormFieldError } from './form-field-error.tsx'
import { FormFieldHelp } from './form-field-help.tsx'
import { FormFieldLabel } from './form-field-label.tsx'
import { FormFieldOverline } from './form-field-overline.tsx'
import { FormFieldSuccess } from './form-field-success.tsx'
import { FormFieldSurface } from './form-field-surface.tsx'
import { FormFieldWarning } from './form-field-warning.tsx'
import {
  FormFieldProvider,
  type UseFormFieldProps,
  useFormField,
} from './use-form-field.ts'

type FormFieldGridColumnSize = '1' | '2' | '3' | '4' | '5' | '6'

type FormFieldProps = Readonly<
  UseFormFieldProps &
    Pick<UseWidthProps, 'width'> &
    Pick<UseHeightProps, 'height'> & {
      children: React.ReactNode
      columnSize?: FormFieldGridColumnSize
    }
>

const FormFieldComponent = ({
  children,
  columnSize,
  height,
  width = 'full',
  ...useFormFieldProps
}: FormFieldProps) => {
  const context = useFormField(useFormFieldProps)
  const { widthClassName } = useWidth({ width })
  const { heightClassName } = useHeight({ height })

  return (
    <FormFieldProvider value={context}>
      <div
        role="group"
        className={cn('flex flex-col gap-2', widthClassName, heightClassName, {
          'sm:col-span-1': columnSize === '1',
          'sm:col-span-2': columnSize === '2',
          'sm:col-span-3': columnSize === '3',
          'sm:col-span-4': columnSize === '4',
          'sm:col-span-5': columnSize === '5',
          'sm:col-span-6': columnSize === '6',
        })}
      >
        {children}
      </div>
    </FormFieldProvider>
  )
}

const FormFieldNamespace = Object.assign(FormFieldComponent, {
  Overline: FormFieldOverline,
  Label: FormFieldLabel,
  Help: FormFieldHelp,
  Error: FormFieldError,
  Control: FormFieldControl,
  Surface: FormFieldSurface,
  Success: FormFieldSuccess,
  Warning: FormFieldWarning,
})

export {
  FormFieldNamespace as FormField,
  type FormFieldGridColumnSize,
  type FormFieldProps,
}
