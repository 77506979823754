'use client'

import { themeBackgroundBlurMap, themeBackgroundMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { useResizeObserver } from '@mntn-dev/ui-utilities'
import { type PropsWithChildren, useRef } from 'react'
import { useSidebarLayoutContext } from './use-sidebar-layout.ts'

type SidebarLayoutFooterProps = Readonly<PropsWithChildren>

const SidebarLayoutFooter = ({ children }: SidebarLayoutFooterProps) => {
  const { clampWidthClassName, setFooterHeight } = useSidebarLayoutContext()
  const footerRef = useRef<HTMLElement>(null)

  useResizeObserver({
    ref: footerRef,
    box: 'border-box',
    onResize: ({ height }) => {
      if (height) {
        setFooterHeight(height)
      }
    },
  })

  return (
    <footer
      ref={footerRef}
      className={cn(
        'sticky bottom-0 right-0 w-full shadow-blur border-t border-white/10',
        themeBackgroundMap['container-secondary'],
        themeBackgroundBlurMap['blur-md']
      )}
    >
      <div className={cn('p-6 mx-auto', clampWidthClassName)}>{children}</div>
    </footer>
  )
}

export { SidebarLayoutFooter }
