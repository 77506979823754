import type { PropsWithChildren } from 'react'

type BladeLeftProps = Readonly<PropsWithChildren>
const BladeLeft = ({ children }: BladeLeftProps) => {
  return (
    <div className="flex w-fit max-w-full shrink-0 items-center gap-4 self-stretch overflow-hidden px-0 py-0">
      {children}
    </div>
  )
}
export { BladeLeft, type BladeLeftProps }
