'use client'

import type React from 'react'
import { useEffect } from 'react'

import { useTextareaFormContext } from './use-textarea-form.tsx'

type TextareaFormToolbarMenuProps = Readonly<{
  children: React.ReactNode
}>

const TextareaFormToolbarMenu = ({
  children,
}: TextareaFormToolbarMenuProps) => {
  const { setHasToolbarMenu } = useTextareaFormContext()

  useEffect(() => {
    setHasToolbarMenu(true)
  }, [setHasToolbarMenu])

  return <div className="flex items-center space-x-5">{children}</div>
}

export { TextareaFormToolbarMenu, type TextareaFormToolbarMenuProps }
