'use client'
import { cn } from '@mntn-dev/ui-utilities'
import { Button, type ButtonProps } from '../../buttons/button.tsx'

export const MediaCardRemoveButton = ({
  className = '',
  ...props
}: ButtonProps) => {
  return (
    <Button
      className={cn('absolute top-2 right-2 z-10', className)}
      variant="secondary"
      iconRight="TrashIcon"
      iconColor="negative"
      size="xs"
      {...props}
    />
  )
}
