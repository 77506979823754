import type { BorderRadius } from '../types/border-radius.ts'

export const borderRadiusMap: Record<BorderRadius, string> = {
  none: 'rounded-none',
  sm: 'rounded-sm',
  default: 'rounded',
  med: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
  full: 'rounded-full',
  'start-none': 'rounded-s-none',
  'start-sm': 'rounded-s-sm',
  'start-default': 'rounded-s',
  'start-med': 'rounded-s-md',
  'start-lg': 'rounded-s-lg',
  'start-xl': 'rounded-s-xl',
  'start-2xl': 'rounded-s-2xl',
  'start-3xl': 'rounded-s-3xl',
  'start-full': 'rounded-s-full',
  'end-none': 'rounded-e-none',
  'end-sm': 'rounded-e-sm',
  'end-default': 'rounded-e',
  'end-med': 'rounded-e-md',
  'end-lg': 'rounded-e-lg',
  'end-xl': 'rounded-e-xl',
  'end-2xl': 'rounded-e-2xl',
  'end-3xl': 'rounded-e-3xl',
  'end-full': 'rounded-e-full',
  'top-none': 'rounded-t-none',
  'top-sm': 'rounded-t-sm',
  'top-default': 'rounded-t',
  'top-med': 'rounded-t-md',
  'top-lg': 'rounded-t-lg',
  'top-xl': 'rounded-t-xl',
  'top-2xl': 'rounded-t-2xl',
  'top-3xl': 'rounded-t-3xl',
  'top-full': 'rounded-t-full',
  'right-none': 'rounded-r-none',
  'right-sm': 'rounded-r-sm',
  'right-default': 'rounded-r',
  'right-med': 'rounded-r-md',
  'right-lg': 'rounded-r-lg',
  'right-xl': 'rounded-r-xl',
  'right-2xl': 'rounded-r-2xl',
  'right-3xl': 'rounded-r-3xl',
  'right-full': 'rounded-r-full',
  'bottom-none': 'rounded-b-none',
  'bottom-sm': 'rounded-b-sm',
  'bottom-default': 'rounded-b',
  'bottom-med': 'rounded-b-md',
  'bottom-lg': 'rounded-b-lg',
  'bottom-xl': 'rounded-b-xl',
  'bottom-2xl': 'rounded-b-2xl',
  'bottom-3xl': 'rounded-b-3xl',
  'bottom-full': 'rounded-b-full',
  'left-none': 'rounded-l-none',
  'left-sm': 'rounded-l-sm',
  'left-default': 'rounded-l',
  'left-med': 'rounded-l-md',
  'left-lg': 'rounded-l-lg',
  'left-xl': 'rounded-l-xl',
  'left-2xl': 'rounded-l-2xl',
  'left-3xl': 'rounded-l-3xl',
  'left-full': 'rounded-l-full',
  'start-start-none': 'rounded-ss-none',
  'start-start-sm': 'rounded-ss-sm',
  'start-start-default': 'rounded-ss',
  'start-start-med': 'rounded-ss-md',
  'start-start-lg': 'rounded-ss-lg',
  'start-start-xl': 'rounded-ss-xl',
  'start-start-2xl': 'rounded-ss-2xl',
  'start-start-3xl': 'rounded-ss-3xl',
  'start-start-full': 'rounded-ss-full',
  'start-end-none': 'rounded-se-none',
  'start-end-sm': 'rounded-se-sm',
  'start-end-default': 'rounded-se',
  'start-end-med': 'rounded-se-md',
  'start-end-lg': 'rounded-se-lg',
  'start-end-xl': 'rounded-se-xl',
  'start-end-2xl': 'rounded-se-2xl',
  'start-end-3xl': 'rounded-se-3xl',
  'start-end-full': 'rounded-se-full',
  'end-start-none': 'rounded-es-none',
  'end-start-sm': 'rounded-es-sm',
  'end-start-default': 'rounded-es',
  'end-start-med': 'rounded-es-md',
  'end-start-lg': 'rounded-es-lg',
  'end-start-xl': 'rounded-es-xl',
  'end-start-2xl': 'rounded-es-2xl',
  'end-start-3xl': 'rounded-es-3xl',
  'end-start-full': 'rounded-es-full',
  'end-end-none': 'rounded-ee-none',
  'end-end-sm': 'rounded-ee-sm',
  'end-end-default': 'rounded-ee',
  'end-end-med': 'rounded-ee-md',
  'end-end-lg': 'rounded-ee-lg',
  'end-end-xl': 'rounded-ee-xl',
  'end-end-2xl': 'rounded-ee-2xl',
  'end-end-3xl': 'rounded-ee-3xl',
  'end-end-full': 'rounded-ee-full',
  'top-left-none': 'rounded-tl-none',
  'top-left-sm': 'rounded-tl-sm',
  'top-left-default': 'rounded-tl',
  'top-left-med': 'rounded-tl-md',
  'top-left-lg': 'rounded-tl-lg',
  'top-left-xl': 'rounded-tl-xl',
  'top-left-2xl': 'rounded-tl-2xl',
  'top-left-3xl': 'rounded-tl-3xl',
  'top-left-full': 'rounded-tl-full',
  'top-right-none': 'rounded-tr-none',
  'top-right-sm': 'rounded-tr-sm',
  'top-right-default': 'rounded-tr',
  'top-right-med': 'rounded-tr-md',
  'top-right-lg': 'rounded-tr-lg',
  'top-right-xl': 'rounded-tr-xl',
  'top-right-2xl': 'rounded-tr-2xl',
  'top-right-3xl': 'rounded-tr-3xl',
  'top-right-full': 'rounded-tr-full',
  'bottom-right-none': 'rounded-br-none',
  'bottom-right-sm': 'rounded-br-sm',
  'bottom-right-default': 'rounded-br',
  'bottom-right-med': 'rounded-br-md',
  'bottom-right-lg': 'rounded-br-lg',
  'bottom-right-xl': 'rounded-br-xl',
  'bottom-right-2xl': 'rounded-br-2xl',
  'bottom-right-3xl': 'rounded-br-3xl',
  'bottom-right-full': 'rounded-br-full',
  'bottom-left-none': 'rounded-bl-none',
  'bottom-left-sm': 'rounded-bl-sm',
  'bottom-left-default': 'rounded-bl',
  'bottom-left-med': 'rounded-bl-md',
  'bottom-left-lg': 'rounded-bl-lg',
  'bottom-left-xl': 'rounded-bl-xl',
  'bottom-left-2xl': 'rounded-bl-2xl',
  'bottom-left-3xl': 'rounded-bl-3xl',
  'bottom-left-full': 'rounded-bl-full',
}
