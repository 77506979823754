'use client'

import type { PropsWithChildren } from 'react'

type RatingRowProps = Readonly<PropsWithChildren>

const RatingRow = ({ children }: RatingRowProps) => {
  return <div className="flex items-center gap-2">{children}</div>
}

export { RatingRow, type RatingRowProps }
