'use client'

import type { UseSizingProps } from '../../../hooks'
import { Modal, type ModalProps } from '../modal.tsx'
import { DialogModalButton } from './dialog-modal-button.tsx'
import { DialogModalContent } from './dialog-modal-content.tsx'
import { DialogModalFooter } from './dialog-modal-footer.tsx'
import { DialogModalHeader } from './dialog-modal-header.tsx'

type DialogModalProps = Readonly<ModalProps & UseSizingProps>

const DialogModal = ({
  width = '5/12',
  children,
  open = true,
  ...props
}: DialogModalProps) => {
  return (
    <Modal open={open} {...props}>
      <Modal.Surface height="full" width={width}>
        {children}
      </Modal.Surface>
    </Modal>
  )
}

const DialogModalNamespace = Object.assign(DialogModal, {
  Header: DialogModalHeader,
  Content: DialogModalContent,
  Footer: DialogModalFooter,
  Button: DialogModalButton,
})

export { DialogModalNamespace as DialogModal }
