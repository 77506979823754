import { Icon, type IconProps } from '../icon'

type StarIconProps = Readonly<
  Omit<IconProps, 'name' | 'fill'> & {
    active: boolean
  }
>

const StarIcon = ({ active, ...props }: StarIconProps) => {
  return <Icon {...props} name="StarIcon" fill={active ? 'solid' : 'outline'} />
}

export { StarIcon, type StarIconProps }
