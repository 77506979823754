'use client'

import { Transition } from '@headlessui/react'
import { cn } from '@mntn-dev/ui-utilities'
import type { ReactNode } from 'react'

import { themeBackgroundBlurMap, themeBackgroundMap } from '@mntn-dev/ui-theme'

import { type TestIds, getTestProps } from '../../utils'

type Props = Readonly<
  TestIds & {
    children: ReactNode
    transparent?: boolean
  }
>

export function Card({
  children,
  dataTrackingId,
  dataTestId,
  transparent = false,
}: Props) {
  return (
    <div
      className={cn(
        transparent
          ? 'bg-transparent'
          : `${themeBackgroundMap['container-secondary']} ${themeBackgroundBlurMap['blur-md-shadow']}`,
        'rounded-lg text-left md:w-[500px]'
      )}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      <Transition
        enter="ease-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        appear={true}
        show={true}
      >
        <div className="inline-flex flex-col justify-start gap-8 self-stretch rounded-lg p-8 md:w-[500px]">
          {children}
        </div>
      </Transition>
    </div>
  )
}
