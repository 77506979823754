'use client'

import { type CSSProperties, useMemo, useState } from 'react'
import { useWidth } from '../../../hooks/use-width.ts'
import { createContext } from '../../../utils/context.ts'

const useSidebarLayout = () => {
  const { widthClassName } = useWidth({ maxWidth: 'screen-2xl', width: 'full' })
  const [footerHeight, setFooterHeight] = useState<number | null>(null)

  const contentHeightStyle: CSSProperties | undefined = useMemo(
    () =>
      footerHeight
        ? { minHeight: `calc(100vh - ${footerHeight}px)` }
        : undefined,
    [footerHeight]
  )

  return {
    clampWidthClassName: widthClassName,
    contentHeightStyle,
    setFooterHeight,
  }
}

const [SidebarLayoutProvider, useSidebarLayoutContext] = createContext<
  ReturnType<typeof useSidebarLayout>
>({ name: 'SidebarLayoutContext' })

export { SidebarLayoutProvider, useSidebarLayoutContext, useSidebarLayout }
