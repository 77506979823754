import { useMemo } from 'react'

import { type ThemeSize, getSizeClassName } from '@mntn-dev/ui-theme'

import { type UseHeightProps, useHeight } from './use-height.ts'
import { type UseWidthProps, useWidth } from './use-width.ts'

type UseSizingProps = ThemeSize & UseHeightProps & UseWidthProps

function useSizing({
  height,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  size,
  width,
}: UseSizingProps) {
  const sizeClassName = useMemo(() => getSizeClassName(size), [size])

  const { heightClassName } = useHeight({ height, maxHeight, minHeight })
  const { widthClassName } = useWidth({ width, maxWidth, minWidth })

  return {
    sizeClassName,
    heightClassName,
    widthClassName,
  }
}

export { useSizing, type UseSizingProps }
