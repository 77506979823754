import type { HTMLAttributes, PropsWithChildren } from 'react'

import { Heading } from '../../heading/heading.tsx'

type InformationalHeaderProps = PropsWithChildren<
  Readonly<HTMLAttributes<HTMLElement>>
>

const InformationalHeader = ({ children }: InformationalHeaderProps) => {
  return (
    <div className="self-stretch leading-tight opacity-90">
      <Heading fontSize="3xl" fontWeight="bold">
        {children}
      </Heading>
    </div>
  )
}

export { InformationalHeader, type InformationalHeaderProps }
