'use client'

import { Chip } from '../chip/chip.tsx'

type MultiselectPlusMoreProps = {
  count?: number
  offset?: number
}

const MultiselectPlusMore = ({ count, offset }: MultiselectPlusMoreProps) => {
  const moreCountString = `+${count} more`

  return (
    <div className="absolute top-0" style={{ left: offset }}>
      <Chip>{moreCountString}</Chip>
    </div>
  )
}

export { MultiselectPlusMore }
