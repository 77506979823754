import { themeTextColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { fontSizeMap } from '../../classes/font-size.ts'

const TabDivider = () => (
  <div
    className={cn(
      'flex items-center font-normal h-14 mt-2 border-b border-transparent pointer-events-none',
      fontSizeMap.med,
      themeTextColorMap.disabled
    )}
  >
    |
  </div>
)

export { TabDivider }
