import type { PropsWithChildren } from 'react'

import { useSizing, type UseSizingProps } from '../../hooks'

type BladeRightProps = Readonly<PropsWithChildren<UseSizingProps>>
const BladeRight = ({ children, ...props }: BladeRightProps) => {
  const { widthClassName } = useSizing({
    ...props,
  })

  return (
    <div
      className={`flex grow items-stretch justify-end gap-4 self-stretch ${widthClassName}`}
    >
      {children}
    </div>
  )
}
export { BladeRight, type BladeRightProps }
