import { Heading, type HeadingProps } from '../../heading'

export const ConfirmationModalTitle = ({
  children,
  ...props
}: HeadingProps) => {
  return (
    <Heading fontSize="xl" {...props}>
      {children}
    </Heading>
  )
}
