'use client'

import { ComboboxOption } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { cn } from '@mntn-dev/ui-utilities'
import type { ReactNode } from 'react'

import { themeTextColorMap } from '@mntn-dev/ui-theme'

import { getInputStyles } from '../input/get-input-styles.ts'
import { useSelectContext } from './use-select.ts'

export type SelectOptionProps = Readonly<{
  value: string
  label: string
  icon?: ReactNode
  isLastItem?: boolean
  isPlaceholder?: boolean
  isHidden?: boolean
}>

export const SelectOption = ({
  value,
  label,
  icon,
  isPlaceholder,
  isHidden = false,
}: SelectOptionProps) => {
  const { getSelectProps } = useSelectContext()
  const { displayableOptions } = getSelectProps()
  const thisOption = displayableOptions?.find(
    (option) => option?.props?.value === value
  )

  // If the option is not in the list of displayable options, don't display it
  if (!thisOption) {
    return
  }

  return (
    <ComboboxOption
      className={cn(isHidden ? 'hidden' : 'rounded-none last:rounded-b-md')}
      value={value}
    >
      {({ active, selected }) => {
        return (
          <div
            className={cn(
              `${getInputStyles()} cursor-pointer gap-4 rounded-[inherit]`,
              active && 'border-white/30'
            )}
          >
            {icon && (
              <span className="h-5 w-5">
                <span className="text-primary-blue shrink-0">{icon}</span>
              </span>
            )}

            <span
              className={cn(
                selected && !isPlaceholder ? 'font-bold' : 'font-normal',
                isPlaceholder && themeTextColorMap.disabled,
                'w-full truncate'
              )}
            >
              {label}
            </span>
            {selected && !isPlaceholder ? (
              <span className="relative inset-y-0 left-3 flex items-center pr-4">
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            ) : null}
          </div>
        )
      }}
    </ComboboxOption>
  )
}

SelectOption.displayName = 'SelectOption'
