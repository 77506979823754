import type React from 'react'

import { useFormFieldContext } from './use-form-field.ts'

type FormFieldControlProps = Readonly<{
  children: React.ReactNode
}>

const FormFieldControl = ({ children }: FormFieldControlProps) => {
  const context = useFormFieldContext()

  const className = context?.hasError ? 'relative' : ''

  // todo: determine toward end of designs: do we need this control component at all?
  //  (No more icon indicator, just error classes that occur at component levels)
  return <div className={className}>{children}</div>
}

export { FormFieldControl, type FormFieldControlProps }
