import { HeroIconNames, type HeroIconName } from '../hero/types/names.ts'
import {
  SvgIconNames,
  type NavIconName,
  type SvgIconName,
} from '../svg/types/names.ts'

export type IconName = HeroIconName | SvgIconName
export const IconNames = [...HeroIconNames, ...SvgIconNames]
export type { NavIconName }
