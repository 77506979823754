'use client'

import { themeBackgroundMap, themeBorderColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { useCallback, useMemo, useState } from 'react'
import { createContext } from '../../utils/context.ts'
import type { DecoratorProps } from '../decorator/decorator.tsx'
import {
  type UseAvatarProps,
  avatarDecoratorIconMap,
  avatarPixelSizeMap,
  avatarSizeMap,
  avatarTextSizeMap,
  badgePositionMap,
  badgeSizeMap,
} from './types.ts'

function useAvatar({
  borderColor,
  dataTestId,
  dataTrackingId,
  decoratorType,
  size = 'med',
}: UseAvatarProps) {
  const [hasUserImage, setHasUserImage] = useState<boolean>(false)

  const backgroundClassName = useMemo(
    () =>
      cn({
        [cn(themeBackgroundMap.transparent)]: hasUserImage,
        [themeBackgroundMap.container]: !hasUserImage,
      }),
    [hasUserImage]
  )

  const pixelSize = useMemo(() => avatarPixelSizeMap[size], [size])
  const borderClassName = useMemo(
    () =>
      borderColor
        ? cn('border-2', themeBorderColorMap[borderColor])
        : undefined,
    [borderColor]
  )
  const sizeClassName = useMemo(() => avatarSizeMap[size], [size])
  const textSizeClassName = useMemo(() => avatarTextSizeMap[size], [size])

  const getDecoratorProps = useCallback<() => DecoratorProps | null>(() => {
    if (!decoratorType) {
      return null
    }

    const { backgroundColor, color, name } =
      avatarDecoratorIconMap[decoratorType]

    return {
      anchor: 'top-right',
      size: badgeSizeMap[size],
      backgroundColor,
      color,
      icon: {
        name,
      },
      dataTestId: dataTestId && `${dataTestId}-decorator-${decoratorType}`,
      dataTrackingId:
        dataTrackingId && `${dataTrackingId}-decorator-${decoratorType}`,
      ...badgePositionMap[size],
    }
  }, [dataTestId, dataTrackingId, decoratorType, size])

  return {
    backgroundClassName,
    borderClassName,
    borderColor,
    dataTestId,
    dataTrackingId,
    decoratorType,
    getDecoratorProps,
    pixelSize,
    setHasUserImage,
    size,
    sizeClassName,
    textSizeClassName,
  }
}

const [AvatarProvider, useAvatarContext] = createContext<
  ReturnType<typeof useAvatar>
>({ name: 'AvatarContext' })

export { AvatarProvider, useAvatar, useAvatarContext, type UseAvatarProps }
