'use client'
import { themeBackgroundMap, themeTextColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { getTestProps } from '../../utils/testing.ts'
import { BadgeIcon } from './badge-icon.tsx'
import {
  type BadgeProps,
  type BadgeSize,
  badgeSizeNames,
  badgeSizeStyleMap,
} from './types.ts'

const Badge = ({
  ariaLabel,
  backgroundColor = 'negative',
  decorator,
  color = 'primary',
  dataTestId,
  dataTrackingId,
  icon,
  size = 'md',
  value,
}: BadgeProps) => {
  return (
    <div
      className={cn(
        'inline-flex justify-center items-center leading-none backdrop-blur-md',
        badgeSizeStyleMap[size],
        themeTextColorMap[color],
        themeBackgroundMap[backgroundColor],
        { 'p-0': !!icon }
      )}
      {...getTestProps({ dataTestId, dataTrackingId })}
      role={decorator ? 'status' : undefined}
      aria-label={ariaLabel}
    >
      {size !== '3xs' ? (
        icon ? (
          <BadgeIcon name={icon.name} fill={icon.fill} size={size} />
        ) : (
          value
        )
      ) : (
        ''
      )}
    </div>
  )
}

export { Badge, badgeSizeNames, type BadgeProps, type BadgeSize }
