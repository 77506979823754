'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type { ElementType, PropsWithChildren } from 'react'

import type { ThemeGridParent } from '@mntn-dev/ui-theme'

import { useGridParent } from '../../hooks/use-grid-parent.ts'
import { type UseSizingProps, useSizing } from '../../hooks/use-sizing.ts'
import { GridItem } from './grid-item.tsx'
import type { GridItemProps } from './grid-item.tsx'

type GridProps = Readonly<
  PropsWithChildren<
    ThemeGridParent &
      UseSizingProps & {
        as?: ElementType
        className?: string
      }
  >
>

const GridComponent = ({
  as: Component = 'div',
  children,
  className,
  ...props
}: GridProps) => {
  const { widthClassName, heightClassName } = useSizing(props)
  const { gridParentClassName } = useGridParent(props)

  return (
    <Component
      className={cn(
        gridParentClassName,
        widthClassName,
        heightClassName,
        className
      )}
    >
      {children}
    </Component>
  )
}

const GridNamespace = Object.assign(GridComponent, {
  Item: GridItem,
})

export { GridNamespace as Grid, type GridProps, type GridItemProps }
