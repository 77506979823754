'use client'

import { useRef } from 'react'
import type { FieldValues, UseFormTrigger } from 'react-hook-form'

import type { AnyFunction } from '@mntn-dev/utility-types'

import { createContext } from '../../utils/context.ts'

type FormGroupForm = {
  form: HTMLFormElement
  trigger: UseFormTrigger<FieldValues>
}

type UseFormGroupProps = {
  submitBehavior?: 'submit' | 'validate-only'
}

function useFormGroup({ submitBehavior = 'validate-only' }: UseFormGroupProps) {
  const forms = useRef<Record<string, FormGroupForm[]>>({})

  const registerForm =
    (trigger: AnyFunction, groupName = 'default') =>
    (form: HTMLFormElement) => {
      forms.current = {
        ...forms.current,
        [groupName]: [...(forms.current[groupName] || []), { form, trigger }],
      }
    }

  const handleSubmitClick = (groupName: string) => {
    if (submitBehavior === 'validate-only' && forms.current[groupName]) {
      for (const { trigger } of forms.current[groupName]) {
        trigger()
      }
    }

    if (submitBehavior === 'submit' && forms.current[groupName]) {
      for (const { form } of forms.current[groupName]) {
        form.requestSubmit()
      }
    }
  }

  return {
    onSubmit: handleSubmitClick,
    registerForm,
  }
}

const [FormGroupProvider, useFormGroupContext] = createContext<
  ReturnType<typeof useFormGroup>
>({
  name: 'FormGroupContext',
})

export { FormGroupProvider, useFormGroup, useFormGroupContext }
