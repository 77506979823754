'use client'

import type { PropsWithChildren } from 'react'

import {
  themeBackgroundMap,
  themeBorderColorMap,
  themeTextColorMap,
} from '@mntn-dev/ui-theme'

import { type TestIds, getTestProps } from '../../utils'
import { TabDivider } from './tab-divider.tsx'
import { TabHeader } from './tab-header.tsx'
import { Tab } from './tab.tsx'
import { type TabType, TabsProvider, useTabs } from './use-tabs.tsx'

type TabGroupProps<TId extends string> = PropsWithChildren<
  Readonly<
    {
      current: string
      type?: TabType
      onClick: (id: TId) => void
    } & TestIds
  >
>

const TabGroupClassMapByType: Record<TabType, string> = {
  simple: `flex items-center gap-10 font-normal ${themeTextColorMap.primary}`,
  bubble: `flex p-1 w-fit items-center gap-1 border rounded-lg ${themeBorderColorMap.muted} shadow-blur-inner ${themeBackgroundMap['container-secondary']}`,
}

const TabGroup = <TId extends string>({
  dataTestId,
  dataTrackingId,
  children,
  current,
  onClick,
  type = 'simple',
}: TabGroupProps<TId>) => {
  const context = useTabs<string>({
    // Unfortunately we need to widen the id type to string going into the TabsProvider because TabsProvider is a singleton and generic-agnostic.
    onClick: onClick as (id: string) => void,
    current,
    type,
  })
  const tabGroupClasses = TabGroupClassMapByType[type]
  return (
    <TabsProvider value={context}>
      <ul
        className={tabGroupClasses}
        {...getTestProps({ dataTestId, dataTrackingId })}
      >
        {children}
      </ul>
    </TabsProvider>
  )
}

const TabsComponent = Object.assign(TabGroup, {
  Header: TabHeader,
  Tab: Tab,
  Divider: TabDivider,
})

export { TabsComponent as Tabs, type TabGroupProps }
