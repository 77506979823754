'use client'

import { useCallback, useState } from 'react'
import type React from 'react'
import { useForm } from 'react-hook-form'

import { mergeRefs } from '@mntn-dev/utilities'

import { createContext } from '../../utils/context.ts'
import {
  type UseFormFieldControlProps,
  useFormFieldControl,
} from '../form-field/use-form-field-control.ts'

type TextareaFormValues = {
  comment: string
}

type UseTextareaFormProps = UseFormFieldControlProps<HTMLTextAreaElement> & {
  onSubmit?: (values: TextareaFormValues) => void
}

function useTextareaForm({
  onSubmit: onSubmitProp,
  ...props
}: UseTextareaFormProps) {
  const [hasToolbarMenu, setHasToolbarMenu] = useState(false)
  const formFieldControlProps = useFormFieldControl<HTMLTextAreaElement>(props)
  const { hasError, hasSuccess, hasWarning } = formFieldControlProps

  const { reset, register, handleSubmit } = useForm<TextareaFormValues>()

  const onSubmit = (data: TextareaFormValues) => {
    if (onSubmitProp) {
      onSubmitProp(data)
      reset()
    }
  }

  const registerProps = register('comment')

  const getTextareaProps = useCallback(
    (ref: React.Ref<HTMLTextAreaElement>) => {
      const { ref: registerRef, ...registerRestProps } = registerProps
      const { hasError, hasSuccess, hasWarning, ...controlRestProps } =
        formFieldControlProps

      return {
        ...registerRestProps,
        ...controlRestProps,
        ref: mergeRefs(ref, registerRef),
      }
    },
    [registerProps, formFieldControlProps]
  )

  return {
    id: formFieldControlProps.id,
    hasError,
    hasSuccess,
    hasWarning,
    getTextareaProps,
    hasToolbarMenu,
    setHasToolbarMenu,
    register,
    onFormSubmit: handleSubmit(onSubmit),
  }
}

type UseTextareaFormReturn = ReturnType<typeof useTextareaForm>

const [TextareaFormProvider, useTextareaFormContext] =
  createContext<UseTextareaFormReturn>({
    name: 'TextareaFormProvider',
  })

export {
  TextareaFormProvider,
  type TextareaFormValues,
  useTextareaForm,
  useTextareaFormContext,
  type UseTextareaFormProps,
}
