import { themeBackgroundMap, type ThemeBackground } from '@mntn-dev/ui-theme'

import { getTestProps, type TestIds } from '../../utils/testing.ts'

type BouncingBallsLoadingIndicatorProps = Readonly<
  TestIds & {
    color?: ThemeBackground
  }
>

const BouncingBallsLoadingIndicator = ({
  color = 'cta',
  dataTestId,
  dataTrackingId,
}: BouncingBallsLoadingIndicatorProps) => {
  return (
    <div
      className="flex items-center justify-center space-x-2 py-4"
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {['animate-delay-0', 'animate-delay-250', 'animate-delay-500'].map(
        (animateDelayClassName) => (
          <div
            key={animateDelayClassName}
            className={`${themeBackgroundMap[color]} ${animateDelayClassName} animate-duration-1000 h-4 w-4 animate-bounce rounded-full`}
          />
        )
      )}
    </div>
  )
}

export {
  BouncingBallsLoadingIndicator,
  type BouncingBallsLoadingIndicatorProps,
}
