'use client'

import { Stack, type StackProps } from '../stack/index.ts'

type FeedAnnotationsProps = StackProps

const FeedAnnotations = ({ children, ...props }: FeedAnnotationsProps) => (
  <Stack gap="2" {...props}>
    {children}
  </Stack>
)

export { FeedAnnotations, type FeedAnnotationsProps }
