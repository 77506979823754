'use client'

import { type ThemeBackground, themeBackgroundMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { Button, type ButtonProps } from '../../buttons/button.tsx'

type Props = ButtonProps & {
  hasError?: boolean
  background?: ThemeBackground
}

export const MediaCardUploadButton = ({
  className = '',
  hasError,
  background,
  ...props
}: Props) => {
  return (
    <Button
      className={cn(
        'w-full h-full rounded-lg',
        className,
        background && themeBackgroundMap[background]
      )}
      borderColor={hasError ? 'negative' : undefined}
      variant="secondary"
      iconRight="PlusIcon"
      {...props}
    />
  )
}
