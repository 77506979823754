import { themeTextColorMap, type ThemeTextColor } from '@mntn-dev/ui-theme'

import type {
  FontFamily,
  FontSize,
  FontWeight,
  TextTransform,
} from '../types/font.ts'
import { fontFamilyMap } from './font-family.ts'
import { fontSizeMap } from './font-size.ts'
import { fontWeightMap } from './font-weight.ts'
import { textTransformMap } from './text-transform.ts'

export type TextOptions = {
  fontSize?: FontSize
  fontWeight?: FontWeight
  textColor?: ThemeTextColor
  fontFamily?: FontFamily
  textTransform?: TextTransform
}

export const getTextBaseClasses = ({
  fontFamily,
  fontSize,
  fontWeight,
  textColor,
  textTransform,
}: TextOptions) => {
  return `${fontFamily ? fontFamilyMap[fontFamily] : ''} ${
    fontSize ? fontSizeMap[fontSize] : ''
  } ${fontWeight ? fontWeightMap[fontWeight] : ''} ${
    textColor ? themeTextColorMap[textColor] : ''
  } ${textTransform ? textTransformMap[textTransform] : ''}`.trim()
}
