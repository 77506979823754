'use client'

import { Text, type TextProps } from '../text/index.ts'

type FeedTextProps = TextProps

const FeedText = ({ children, ...props }: FeedTextProps) => (
  <Text {...props}>{children}</Text>
)

export { FeedText, type FeedTextProps }
