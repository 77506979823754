import type { DeliverableCategory } from '@mntn-dev/domain-types'
import { cn } from '@mntn-dev/ui-utilities'
import { Icon } from '../../icon'

export const BladeMediaIcon = ({
  category,
}: { category: DeliverableCategory }) => {
  return (
    <div className={cn('flex h-full items-center justify-center py-3')}>
      <Icon
        fill="outline"
        name={category === 'video' ? 'PlayIcon' : 'DocumentIcon'}
        size="xl"
        color="info"
      />
    </div>
  )
}
