'use client'

import type { PropsWithChildren } from 'react'

import { getTestProps, type TestIds } from '../../utils'
import { Text } from '../text'

export const FeedEmpty = ({
  children,
  dataTestId,
  dataTrackingId,
}: PropsWithChildren<TestIds>) => {
  return (
    <div
      className="flex h-32 flex-col items-center justify-center"
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      <Text textColor="disabled" fontSize="sm">
        {children}
      </Text>
    </div>
  )
}
