import type { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const BannerRight = ({ children }: Props) => {
  return (
    <div className="flex gap-6 xl:flex-col xl:items-end xl:justify-end">
      {children}
    </div>
  )
}
