import * as SolidHeroIcons from '@heroicons/react/24/solid'

export type HeroIconName = keyof typeof SolidHeroIcons

export const HeroIconNames = Object.keys(
  SolidHeroIcons
) as ReadonlyArray<HeroIconName>

export const isHeroIconName = (name: unknown): name is HeroIconName => {
  return HeroIconNames.includes(name as HeroIconName)
}
