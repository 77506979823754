import { themeTextColorMap } from '@mntn-dev/ui-theme'

type TabHeaderProps = Readonly<{
  name: string
}>

const TabHeader = ({ name }: TabHeaderProps) => {
  return (
    <li>
      <span className={`font-bold ${themeTextColorMap.primary}`}>{name}</span>
    </li>
  )
}

export { TabHeader, type TabHeaderProps }
