'use client'

import { useCallback, useId } from 'react'

import { createContext } from '../../utils/context.ts'
import { getTestProps, type TestIds } from '../../utils/testing.ts'

type UseModalProps = TestIds & {
  id?: string
  onClose: () => void
  open?: boolean
  suppressUserClose?: boolean
}

function useModal(props: UseModalProps) {
  const autoId = useId()
  const {
    id: idProp,
    dataTestId,
    dataTrackingId,
    onClose,
    open,
    suppressUserClose,
  } = props

  const id = idProp || `modal-${autoId}`

  const handleClose = useCallback(() => {
    if (!suppressUserClose) {
      onClose()
    }
  }, [onClose, suppressUserClose])

  const getDialogProps = useCallback(
    () => ({
      id,
      ...getTestProps({
        dataTestId,
        dataTrackingId,
      }),
    }),
    [id, dataTestId, dataTrackingId]
  )

  const getPanelProps = useCallback(
    () => ({
      id: `${id}-panel`,
      ...getTestProps({
        dataTestId: dataTestId ? `${dataTestId}-panel` : undefined,
        dataTrackingId: dataTrackingId ? `${dataTrackingId}-panel` : undefined,
      }),
    }),
    [id, dataTestId, dataTrackingId]
  )

  const getCloseProps = useCallback(
    () => ({
      id: `${id}-close`,
      onClose,
      ...getTestProps({
        dataTestId: dataTestId ? `${dataTestId}-close` : undefined,
        dataTrackingId: dataTrackingId ? `${dataTrackingId}-close` : undefined,
      }),
    }),
    [id, dataTestId, dataTrackingId, onClose]
  )

  return {
    id,
    getCloseProps,
    getDialogProps,
    getPanelProps,
    onClose: handleClose,
    open,
    suppressUserClose,
  }
}

type UseModalReturn = ReturnType<typeof useModal>

const [ModalProvider, useModalContext] = createContext<UseModalReturn>({
  name: 'ModalContext',
})

export {
  ModalProvider,
  type UseModalProps,
  type UseModalReturn,
  useModal,
  useModalContext,
}
