import type { VideoDuration } from '@mntn-dev/domain-types'
import { Text } from '../../text'

export const BladeMediaDurationTime = ({
  time,
  timeInterval,
}: {
  time: VideoDuration
  timeInterval: string
}) => {
  // todo: move this somewhere more helpful
  const getMinMaxDuration = (time: VideoDuration) => {
    const durationIsObject =
      typeof time === 'object' && 'min' in time && 'max' in time
    const durationIsNumber = typeof time === 'number'

    if (!(durationIsObject || durationIsNumber)) {
      return undefined
    }

    return {
      min: durationIsObject ? time.min : time,
      max: durationIsObject ? time.max : time,
    }
  }

  const { min, max } = getMinMaxDuration(time) ?? {}

  return (
    <>
      <Text fontWeight="semibold" textColor="info" fontSize="xs">
        {min}
      </Text>

      {min !== max && (
        <>
          <Text
            textColor="secondary"
            textTransform="uppercase"
            fontWeight="semibold"
            fontSize="xs"
            className="px-0.5"
          >
            {'-'}
          </Text>
          <Text fontWeight="semibold" textColor="info" fontSize="xs">
            {max}
          </Text>
        </>
      )}
      <Text
        textColor="tertiary"
        textTransform="uppercase"
        fontSize="xs"
        fontWeight="semibold"
        className="pl-1"
      >
        {timeInterval}
      </Text>
    </>
  )
}
