export type MultivalueObjectValue = { label: string; value: string }

export type MultivalueValue = string | MultivalueObjectValue

const equals = <T extends MultivalueValue>(multivalue: T, value: string) => {
  if (typeof multivalue === 'string') {
    return multivalue === value
  }
  return multivalue.value === value
}

export const getMultivalueItem = <T extends MultivalueValue>(
  value: string,
  values?: T[]
) => {
  return values?.find((v) => equals(v, value))
}

export const removeMultivalueItem = <T extends MultivalueValue>(
  value: string,
  values?: T[]
) => {
  return values?.filter((v) => !equals(v, value))
}

export const hasMultivalueItem = <T extends MultivalueValue>(
  value: string,
  values?: T[]
) => {
  return values?.some((v) => equals(v, value))
}

export const getLabel = <T extends MultivalueValue>(value: T) => {
  if (typeof value === 'string') {
    return value
  }
  return value.label
}

export const getValue = <T extends MultivalueValue>(value: T) => {
  if (typeof value === 'string') {
    return value
  }
  return value.value
}

export const getNormalizedValue = <T extends MultivalueValue>(
  value: T
): MultivalueObjectValue => ({
  label: getLabel(value),
  value: getValue(value),
})
