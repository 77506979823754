import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'
import type { PropsWithChildren } from 'react'

import { themeTextColorMap } from '@mntn-dev/ui-theme'

import { type UseSizingProps, useSizing } from '../../hooks'
import { type TestIds, getTestProps } from '../../utils'
import { TableBody } from './table-body.tsx'
import { TableCell } from './table-cell.tsx'
import { TableFoot } from './table-foot.tsx'
import { TableHead } from './table-head.tsx'
import { TableHeaderCell } from './table-header-cell.tsx'
import { TableRow } from './table-row.tsx'

type TableProps = PropsWithChildren<
  Readonly<
    React.TableHTMLAttributes<HTMLTableElement> & TestIds & UseSizingProps
  >
>

const Table = ({
  dataTestId,
  dataTrackingId,
  id,
  className = '',
  children,
  ...tableProps
}: TableProps) => {
  const { widthClassName } = useSizing(tableProps)
  return (
    <table
      {...tableProps}
      className={cn(
        `text-left ${themeTextColorMap.primary} ${className}`,
        widthClassName || 'w-fit'
      )}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
    </table>
  )
}

const TableNamespace = Object.assign(Table, {
  Body: TableBody,
  Cell: TableCell,
  Foot: TableFoot,
  Head: TableHead,
  HeaderCell: TableHeaderCell,
  Row: TableRow,
})

export { TableNamespace as Table, type TableProps }
