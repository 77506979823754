'use client'

import { Text, type TextProps } from '../../text/text.tsx'

export const InfoModalDescription = (props: TextProps) => {
  return (
    <Text
      className="text-center"
      fontSize="lg"
      fontWeight="medium"
      textColor="secondary"
      {...props}
    />
  )
}
