'use client'

import { Text } from '../../text/text.tsx'
import { useCardContext } from '../use-card.ts'

type ImageCardSubtitleProps = Readonly<{
  children: string
}>

const ImageCardSubtitle = ({ children }: ImageCardSubtitleProps) => {
  const { getSubtitleProps } = useCardContext()
  return (
    <Text fontSize="med" fontWeight="light" {...getSubtitleProps()}>
      {children}
    </Text>
  )
}

export { ImageCardSubtitle, type ImageCardSubtitleProps }
