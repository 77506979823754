import type { SvgIconProps } from '../types/props.ts'

const Packages = (props: SvgIconProps) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    aria-label="Packages icon"
    role="img"
    width="1em"
    height="1em"
  >
    <path d="M15 4V9.39518C13.4331 7.9028 10.9528 7.92592 9.41421 9.46454C7.89196 10.9868 7.85313 13.4307 9.29773 15H2V7C2 5.34314 3.34315 4 5 4H15Z" />
    <path d="M17 4V9.34802C18.5693 7.90343 21.0132 7.94225 22.5355 9.46451C24.0577 10.9868 24.0965 13.4307 22.6519 15H30V7C30 5.34315 28.6569 4 27 4H17Z" />
    <path d="M30 17H18.0224C18.275 19.8032 20.631 22 23.5 22C24.0523 22 24.5 22.4477 24.5 23C24.5 23.5523 24.0523 24 23.5 24C20.7215 24 18.296 22.4891 17 20.2442V28H27C28.6569 28 30 26.6569 30 25V17Z" />
    <path d="M15 28V20.2442C13.704 22.4891 11.2785 24 8.5 24C7.94772 24 7.5 23.5523 7.5 23C7.5 22.4477 7.94772 22 8.5 22C11.369 22 13.725 19.8032 13.9776 17H2V25C2 26.6569 3.34315 28 5 28H15Z" />
    <path d="M14.7806 13.8047C14.8206 14.2018 14.8312 14.5714 14.8287 14.879C14.5211 14.8815 14.1515 14.8709 13.7544 14.8309C12.6439 14.7191 11.5187 14.3974 10.8284 13.7072C10.0474 12.9261 10.0474 11.6598 10.8284 10.8788C11.6095 10.0977 12.8758 10.0977 13.6569 10.8788C14.3471 11.569 14.6688 12.6942 14.7806 13.8047Z" />
    <path d="M17.1691 13.8047C17.1291 14.2018 17.1185 14.5714 17.121 14.879C17.4286 14.8815 17.7982 14.8709 18.1953 14.8309C19.3058 14.7191 20.431 14.3974 21.1212 13.7071C21.9023 12.9261 21.9023 11.6598 21.1212 10.8787C20.3402 10.0977 19.0739 10.0977 18.2928 10.8787C17.6026 11.569 17.2809 12.6942 17.1691 13.8047Z" />
  </svg>
)

export { Packages }
