import type { HTMLAttributes, PropsWithChildren } from 'react'

import { Text } from '../../text/text.tsx'

type InformationalDescriptionProps = PropsWithChildren<
  Readonly<HTMLAttributes<HTMLElement>>
>

const InformationalDescription = ({
  children,
}: InformationalDescriptionProps) => {
  return (
    <div className="flex shrink-0 grow basis-0 self-stretch leading-[150%]">
      <Text fontSize="sm" fontWeight="medium" textColor="tertiary">
        {children}
      </Text>
    </div>
  )
}

export { InformationalDescription, type InformationalDescriptionProps }
