import { useMemo } from 'react'

import { getGridChildClassName, type ThemeGridChild } from '@mntn-dev/ui-theme'

function useGridChild({
  alignSelf,
  columnSpan,
  columnStart,
  columnEnd,
  justifySelf,
  placeSelf,
  rowSpan,
  rowStart,
  rowEnd,
}: ThemeGridChild) {
  const gridChildClassName = useMemo(
    () =>
      getGridChildClassName({
        alignSelf,
        columnSpan,
        columnStart,
        columnEnd,
        justifySelf,
        placeSelf,
        rowSpan,
        rowStart,
        rowEnd,
      }),
    [
      alignSelf,
      columnSpan,
      columnStart,
      columnEnd,
      justifySelf,
      placeSelf,
      rowSpan,
      rowStart,
      rowEnd,
    ]
  )

  return { gridChildClassName }
}

export { useGridChild }
