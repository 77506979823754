'use client'

import type { PropsWithChildren } from 'react'
import { Decorator } from '../decorator/decorator.tsx'
import { useAvatarContext } from './use-avatar.ts'

type AvatarDecoratorProps = PropsWithChildren

const AvatarDecorator = ({ children }: AvatarDecoratorProps) => {
  const { getDecoratorProps, decoratorType } = useAvatarContext()

  const decoratorProps = getDecoratorProps()

  if (!(decoratorType && decoratorProps)) {
    return children
  }

  return <Decorator {...decoratorProps}>{children}</Decorator>
}

export { AvatarDecorator }
