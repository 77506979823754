'use client'

import { Modal, type ModalProps } from '../modal.tsx'
import { InfoModalBody } from './info-modal-body.tsx'
import { InfoModalButton } from './info-modal-button.tsx'
import { InfoModalDescription } from './info-modal-description.tsx'
import { InfoModalFooter } from './info-modal-footer.tsx'
import { InfoModalImage } from './info-modal-image.tsx'
import { InfoModalSubtitle } from './info-modal-subtitle.tsx'
import { InfoModalTitle } from './info-modal-title.tsx'

const InfoModal = ({ children, ...rest }: ModalProps) => {
  return (
    <Modal {...rest}>
      <div className="max-w-md">{children}</div>
    </Modal>
  )
}

const InfoModalNamespace = Object.assign(InfoModal, {
  Body: InfoModalBody,
  Title: InfoModalTitle,
  Subtitle: InfoModalSubtitle,
  Description: InfoModalDescription,
  Image: InfoModalImage,
  Footer: InfoModalFooter,
  Button: InfoModalButton,
})

export { InfoModalNamespace as InfoModal }
