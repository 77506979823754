import { useMemo } from 'react'

import { getHeightClassName, type ThemeHeight } from '@mntn-dev/ui-theme'

type UseHeightProps = ThemeHeight

function useHeight({ height, maxHeight, minHeight }: UseHeightProps) {
  const heightClassName = useMemo(
    () => getHeightClassName({ height, maxHeight, minHeight }),
    [height, maxHeight, minHeight]
  )

  return { heightClassName }
}

export { useHeight, type UseHeightProps }
