import { themeTextColorMap } from '@mntn-dev/ui-theme'

import { useFormFieldContext } from './use-form-field.ts'

type FormFieldHelpProps = Readonly<{
  children: string
}>

const FormFieldHelp = ({ children }: FormFieldHelpProps) => {
  const context = useFormFieldContext()
  const helpProps = context?.getHelpProps() ?? {}

  if (context?.hasError || context?.hasSuccess || context?.hasWarning) {
    return null
  }

  return (
    <p
      {...helpProps}
      className={`text-sm leading-normal ${themeTextColorMap.tertiary}`}
    >
      {children}
    </p>
  )
}
export { FormFieldHelp, type FormFieldHelpProps }
