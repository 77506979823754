import type { PropsWithChildren } from 'react'

import { themeBackgroundMap, type ThemeBackground } from '@mntn-dev/ui-theme'

type TableHeadProps = PropsWithChildren<{
  className?: string
  background?: ThemeBackground | null
}>

const TableHead = ({
  className = '',
  background = 'container',
  children,
}: TableHeadProps) => {
  return (
    <thead
      className={`${background ? themeBackgroundMap[background] : ''} rounded-tl-lg rounded-tr-lg ${className}`}
    >
      {children}
    </thead>
  )
}

export { TableHead, type TableHeadProps }
