'use client'

import { Switch } from '@headlessui/react'
import { cn } from '@mntn-dev/ui-utilities'
import { type MouseEvent, type PropsWithChildren, useState } from 'react'

import { themeBackgroundMap } from '@mntn-dev/ui-theme'

import { Icon } from '../icon'

type ToggleProps = Readonly<
  PropsWithChildren<{
    checked: boolean
    disabled: boolean
    onChange: () => void
  }>
>

const Toggle = ({ checked, disabled, onChange, children }: ToggleProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked)
  const handleChange = (value: boolean) => {
    setIsChecked(value)
    onChange()
  }
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => e.stopPropagation() // Click event shouldn't bubble up. E.g., A Toggle on a table row shouldn't also trigger click event on the row.

  return (
    <div className="flex items-center gap-2">
      <Switch
        checked={isChecked}
        disabled={disabled}
        onChange={handleChange}
        onClick={handleClick}
        className={cn(
          `group inline-flex h-8 w-14 items-center rounded-full transition ${themeBackgroundMap['secondary-inverse']}`
        )}
      >
        <span
          className={cn(
            'group-data-[checked]:translate-x-6.5 flex h-7 w-7 shrink-0 translate-x-0.5 items-center justify-center rounded-full transition',
            {
              [`${themeBackgroundMap.info} shadow-glow-blue-md`]: isChecked,
              [`${themeBackgroundMap.tertiary}`]: !isChecked,
              'cursor-not-allowed opacity-50': disabled,
            }
          )}
        >
          <Icon
            name={isChecked ? 'CheckIcon' : 'XMarkIcon'}
            size={'xs'}
            fill={'outline'}
            color="primary-inverse"
          />
        </span>
      </Switch>
      {children}
    </div>
  )
}

export { Toggle, type ToggleProps }
