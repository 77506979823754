import type React from 'react'

import type { IconProps } from '../../types/props.ts'
import { CelebrationIcon } from '../celebration-icon.tsx'
import { CircleIcon } from '../circle-icon.tsx'
import { DotIcon } from '../dot-icon.tsx'
import { EditIcon } from '../edit-icon.tsx'
import { ErrorIcon } from '../error-icon.tsx'
import { InfoIcon } from '../info-icon.tsx'
import { LoadingDots } from '../loading-dots.tsx'
import { BarChart } from '../navigation/bar-chart.tsx'
import { Bell } from '../navigation/bell.tsx'
import { ColorWheel } from '../navigation/color-wheel.tsx'
import { Folder } from '../navigation/folder.tsx'
import { Packages } from '../navigation/packages.tsx'
import { Projects } from '../navigation/projects.tsx'
import { Users } from '../navigation/users.tsx'
import { ProductionCameraIcon } from '../production-camera-icon.tsx'
import { SuccessIcon } from '../success-icon.tsx'
import { VideoIcon } from '../video-icon.tsx'
import { WarningIcon } from '../warning-icon.tsx'
import type { SvgIconName } from './names.ts'

export const svgIconMap: Record<SvgIconName, React.ComponentType<IconProps>> = {
  'nav-bar-chart': BarChart,
  'nav-bell': Bell,
  'nav-color-wheel': ColorWheel,
  'nav-folder': Folder,
  'nav-packages': Packages,
  'nav-projects': Projects,
  'nav-users': Users,
  CelebrationIcon,
  CircleIcon,
  DotIcon,
  EditIcon,
  ErrorIcon,
  InfoIcon,
  LoadingDots,
  ProductionCameraIcon,
  SuccessIcon,
  VideoIcon,
  WarningIcon,
}
