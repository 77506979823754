'use client'

import { cn } from '@mntn-dev/ui-utilities'

import {
  type ThemeSpacing,
  paddingBottomMap,
  paddingTopMap,
  paddingXMap,
} from '@mntn-dev/ui-theme'

import { type HTMLProps, createContext, useContext } from 'react'
import { Stack, type StackProps } from '../stack/index.ts'

type OverflowProps = Omit<StackProps, 'padding' | 'direction'> & {
  padding: ThemeSpacing
}

const OverflowContext = createContext<{ padding: ThemeSpacing }>({
  padding: '0',
})

const useOverflowContext = () => useContext(OverflowContext)

const Overflow = ({
  padding,
  className,
  children,
  ...props
}: OverflowProps) => (
  <OverflowContext.Provider value={{ padding }}>
    <Stack {...props} direction="col" className={cn(className, 'grow')}>
      {children}
    </Stack>
  </OverflowContext.Provider>
)

type OverflowSectionProps = HTMLProps<HTMLDivElement>

const OverflowNamespace = Object.assign(Overflow, {
  Header: ({ children, className, ...props }: OverflowSectionProps) => {
    const { padding } = useOverflowContext()
    return (
      <div
        {...props}
        className={cn(className, paddingTopMap[padding], paddingXMap[padding])}
      >
        {children}
      </div>
    )
  },
  Body: ({ children, className, ...props }: OverflowSectionProps) => {
    const { padding } = useOverflowContext()
    return (
      <div
        {...props}
        className={cn(
          className,
          'overflow-y-auto grow shrink',
          paddingXMap[padding]
        )}
      >
        {children}
      </div>
    )
  },
  Footer: ({ children, className, ...props }: OverflowSectionProps) => {
    const { padding } = useOverflowContext()
    return (
      <div
        {...props}
        className={cn(
          className,
          paddingBottomMap[padding],
          paddingXMap[padding]
        )}
      >
        {children}
      </div>
    )
  },
})

export { OverflowNamespace as Overflow, type OverflowProps }
