'use client'

import type { SetOptional } from 'type-fest'
import { Icon, type IconProps } from '../icon/index.ts'

type AvatarIconProps = SetOptional<IconProps, 'size'>

const AvatarIcon = (iconProps: AvatarIconProps) => {
  return <Icon {...iconProps} size="full" />
}

export { AvatarIcon, type AvatarIconProps }
