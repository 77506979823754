import type { PropsWithChildren } from 'react'
import { Stack } from '../stack/stack.tsx'

type FormFieldOverlineProps = PropsWithChildren

const FormFieldOverline = ({ children }: FormFieldOverlineProps) => (
  <Stack width="full" justifyContent="between" alignItems="center">
    {children}
  </Stack>
)

export { FormFieldOverline, type FormFieldOverlineProps }
