'use client'

import { Surface, type SurfaceProps } from '../surface/surface.tsx'
import { useFormFieldControl } from './use-form-field-control.ts'

function FormFieldSurface({ children, border, ...props }: SurfaceProps) {
  const { hasError } = useFormFieldControl({})
  return (
    <Surface
      {...props}
      border={border || hasError}
      borderColor={hasError ? 'negative' : 'muted'}
      borderRadius="med"
    >
      {children}
    </Surface>
  )
}

export { FormFieldSurface }
