import { cn } from '@mntn-dev/ui-utilities'
import type { InputHTMLAttributes, ReactNode, RefAttributes } from 'react'

import { type UseWidthProps, useWidth } from '../../hooks/use-width.ts'
import { forwardRef } from '../../utils/forward-ref.ts'
import type { TestIds } from '../../utils/testing.ts'
import {
  type UseFormFieldControlProps,
  useFormFieldControl,
} from '../form-field/use-form-field-control.ts'
import { getInputStyles } from './get-input-styles.ts'

type InputProps = Readonly<
  Omit<InputHTMLAttributes<HTMLInputElement>, 'readOnly'> &
    RefAttributes<HTMLInputElement> &
    UseWidthProps &
    UseFormFieldControlProps<HTMLInputElement> &
    TestIds & {
      value?: string
      iconRight?: ReactNode
      iconLeft?: ReactNode
      unStyled?: boolean
    }
>
const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      autoComplete = 'off',
      className,
      dataTestId,
      dataTrackingId,
      disabled,
      hasError: hasErrorProp,
      hasSuccess: hasSuccessProp,
      hasWarning: hasWarningProp,
      iconLeft,
      iconRight,
      maxWidth,
      minWidth,
      id,
      onBlur,
      readOnly,
      unStyled,
      width = 'full',
      ...inputProps
    },
    ref
  ) => {
    const { hasError, hasSuccess, hasWarning, ...fieldProps } =
      useFormFieldControl<HTMLInputElement>({
        disabled,
        id,
        hasError: hasErrorProp,
        hasSuccess: hasSuccessProp,
        hasWarning: hasWarningProp,
        dataTestId,
        dataTrackingId,
        onBlur,
        readOnly,
      })

    const { widthClassName } = useWidth({ maxWidth, minWidth, width })

    return (
      <div className={cn('relative flex items-center', widthClassName)}>
        {iconLeft && (
          <span className="absolute left-3 h-5 w-5 flex justify-center items-center shrink-0">
            {iconLeft}
          </span>
        )}
        <input
          {...inputProps}
          {...fieldProps}
          autoComplete={autoComplete}
          className={cn(
            getInputStyles({
              hasError,
              hasSuccess,
              hasWarning,
              disabled: fieldProps.disabled,
              readOnly: fieldProps.readOnly,
            }),
            { 'pr-11': !!iconRight, 'pl-11': !!iconLeft },
            className
          )}
          ref={ref}
        />
        {iconRight && (
          <span className="absolute right-3 h-5 w-5 flex justify-center items-center shrink-0">
            {iconRight}
          </span>
        )}
      </div>
    )
  }
)

Input.id = 'Input'

export { Input, type InputProps }
