'use client'

import { cn } from '@mntn-dev/ui-utilities'
import { type TestIds, getTestProps } from '../../utils/testing.ts'
import { Icon } from '../icon'
import { Text } from '../text/text'

const CollapsibleButton = ({
  dataTestId,
  dataTrackingId,
  title,
  open,
  onClick,
}: { title: string; open: boolean; onClick: () => void } & TestIds) => {
  return (
    <button
      type="button"
      className="flex w-full h-10 pl-8 pr-2 py-2 items-center gap-4 self-stretch justify-between"
      onClick={onClick}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      <Text
        textColor="secondary"
        fontSize="xs"
        textTransform="uppercase"
        fontWeight="semibold"
      >
        {title}
      </Text>
      <Icon
        name="ChevronDownIcon"
        color="info"
        size="sm"
        className={cn(open && 'rotate-180', 'w-6 m-3')}
      />
    </button>
  )
}

export { CollapsibleButton }
