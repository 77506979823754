'use client'

import { ListboxOption } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import { MultiselectOption } from './multiselect-option.tsx'
import {
  type MultiselectOptionItem,
  useMultiselectContext,
} from './use-multiselect.ts'

type MultiselectPopoutOptionProps = {
  item: MultiselectOptionItem
}

const MultiselectPopoutOption = ({ item }: MultiselectPopoutOptionProps) => {
  const { isItemVisible, registerItem } = useMultiselectContext()

  useEffect(() => {
    registerItem(item)
  }, [item, registerItem])

  const visible = isItemVisible(item)

  return visible ? (
    <ListboxOption key={item.id} value={item} as={Fragment}>
      <MultiselectOption item={item} />
    </ListboxOption>
  ) : null
}

export { MultiselectPopoutOption }
