'use client'

import { createContext } from '../../utils'

type TabType = 'simple' | 'bubble'

type useTabsProps<TId extends string> = {
  current: string
  type: TabType
  onClick: (id: TId) => void
}

function useTabs<TId extends string>({
  current,
  type,
  onClick,
}: useTabsProps<TId>) {
  return {
    current,
    type,
    onClick,
  }
}

type UseTabsReturn = ReturnType<typeof useTabs>

const [TabsProvider, useTabsContext] = createContext<UseTabsReturn>({
  name: 'TabsProvider',
})

export {
  TabsProvider,
  useTabs,
  useTabsContext,
  type useTabsProps,
  type TabType,
}
