'use client'

import type { HTMLAttributes, PropsWithChildren } from 'react'

import { themeBackgroundBlurMap, themeBackgroundMap } from '@mntn-dev/ui-theme'

import { type TestIds, getTestProps } from '../../../utils/testing.ts'
import { InformationalContent } from './informational-content.tsx'
import { InformationalDescription } from './informational-description.tsx'
import { InformationalFooter } from './informational-footer.tsx'
import { InformationalHeader } from './informational-header.tsx'
import { InformationalPrice } from './informational-price.tsx'
import { InformationalStatus } from './informational-status.tsx'

type InformationalCardProps = PropsWithChildren<
  Readonly<HTMLAttributes<HTMLElement> & TestIds>
>

const InformationalCard = ({
  dataTestId,
  dataTrackingId,
  children,
}: InformationalCardProps) => {
  return (
    <div
      {...getTestProps({ dataTestId, dataTrackingId })}
      className={`flex-start flex w-[485px] rounded ${themeBackgroundBlurMap['blur-md-shadow']} flex-col gap-6 p-6 ${themeBackgroundMap['container-secondary']}`}
    >
      {children}
    </div>
  )
}

const InformationalCardComponent = Object.assign(InformationalCard, {
  Status: InformationalStatus,
  Header: InformationalHeader,
  Description: InformationalDescription,
  Price: InformationalPrice,
  Footer: InformationalFooter,
  Content: InformationalContent,
})

export { InformationalCardComponent as InformationalCard }
