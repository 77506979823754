'use client'

import { useCallback, useState } from 'react'
import { createContext } from '../../utils/context.ts'
import type { MultiselectOptionItem } from './types.ts'

type UseMultiselectProps = {
  onChange: (items: MultiselectOptionItem[]) => void
  selectedItems?: MultiselectOptionItem[]
}

function useMultiselect({ onChange, selectedItems }: UseMultiselectProps) {
  const [items, setItems] = useState<MultiselectOptionItem[]>([])
  const [search, setSearch] = useState('')

  const registerItem = useCallback(
    (newItem: MultiselectOptionItem) => {
      if (!items.some((i) => i.id === newItem.id)) {
        setItems((items) => items.concat(newItem))
      }
    },
    [items]
  )

  const handleRemove = useCallback(
    (itemToRemove: MultiselectOptionItem) => {
      onChange(
        selectedItems?.filter(
          (selectedItem) => selectedItem.id !== itemToRemove.id
        ) ?? []
      )
    },
    [onChange, selectedItems]
  )

  const isItemVisible = useCallback(
    (item: MultiselectOptionItem) =>
      search ? item.name?.toLowerCase().includes(search?.toLowerCase()) : true,
    [search]
  )

  const handleSearch = useCallback((search: string) => {
    setSearch(search)
  }, [])

  return {
    isItemVisible,
    items,
    onChange,
    onRemove: handleRemove,
    onSearch: handleSearch,
    registerItem,
    search,
    selectedItems,
  }
}

const [MultiselectProvider, useMultiselectContext] = createContext<
  ReturnType<typeof useMultiselect>
>({
  name: 'MultiselectContext',
})

export {
  MultiselectProvider,
  useMultiselect,
  useMultiselectContext,
  type MultiselectOptionItem,
  type UseMultiselectProps,
}
