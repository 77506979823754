'use client'

import type { ThemeGridParent } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'
import { useGridParent } from '../../hooks/use-grid-parent.ts'
import { type UseWidthProps, useWidth } from '../../hooks/use-width.ts'
import { DataListDescription } from './data-list-description.tsx'
import { DataListItem } from './data-list-item.tsx'
import { DataListTitle } from './data-list-title.tsx'
import {
  DataListProvider,
  type UseDataListProps,
  useDataList,
} from './use-data-list.ts'

type DataListProps = PropsWithChildren<
  UseDataListProps & ThemeGridParent & UseWidthProps
>

const defaultRowGap = '8'
const defaultColumnGap = '4'

const DataListComponent = ({ children, ...props }: DataListProps) => {
  const context = useDataList(props)

  const { widthClassName } = useWidth(props)
  const { gridParentClassName } = useGridParent({
    ...props,
    rowGap: props.rowGap ?? defaultRowGap,
    columnGap: props.columnGap ?? defaultColumnGap,
  })

  return (
    <DataListProvider value={context}>
      <dl role="list" className={cn(gridParentClassName, widthClassName)}>
        {children}
      </dl>
    </DataListProvider>
  )
}

const DataListNamespace = Object.assign(DataListComponent, {
  Item: DataListItem,
  Title: DataListTitle,
  Description: DataListDescription,
})

export { DataListNamespace as DataList, type DataListProps }
