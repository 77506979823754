import { useMemo } from 'react'

import {
  getGridParentClassName,
  type ThemeGridParent,
} from '@mntn-dev/ui-theme'

function useGridParent({
  alignContent,
  alignItems,
  autoColumns,
  autoFlow,
  autoRows,
  columns,
  inline,
  justifyContent,
  justifyItems,
  placeContent,
  placeItems,
  rows,
  gap,
  rowGap,
  columnGap,
}: ThemeGridParent) {
  const gridParentClassName = useMemo(
    () =>
      getGridParentClassName({
        alignContent,
        alignItems,
        autoColumns,
        autoFlow,
        autoRows,
        columns,
        inline,
        justifyContent,
        justifyItems,
        placeContent,
        placeItems,
        rows,
        gap,
        rowGap,
        columnGap,
      }),
    [
      alignContent,
      alignItems,
      autoColumns,
      autoFlow,
      autoRows,
      columns,
      inline,
      justifyContent,
      justifyItems,
      placeContent,
      placeItems,
      rows,
      gap,
      rowGap,
      columnGap,
    ]
  )

  return { gridParentClassName }
}

export { useGridParent }
