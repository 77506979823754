import type { HTMLAttributes, PropsWithChildren } from 'react'

type InformationalContentProps = PropsWithChildren<
  Readonly<HTMLAttributes<HTMLElement>>
>

const InformationalContent = ({ children }: InformationalContentProps) => {
  return (
    <div className="flex shrink-0 grow basis-0 flex-col items-start justify-start gap-1 self-stretch">
      {children}
    </div>
  )
}

export { InformationalContent, type InformationalContentProps }
