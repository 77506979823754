export type Person = {
  firstName: string
  lastName: string
  imageUrl?: string
}

export const getPersonFullName = (person: Person) =>
  `${person.firstName} ${person.lastName}`

export const getPersonInitials = ({ firstName, lastName }: Person) =>
  firstName && lastName
    ? `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
    : 'QF'
