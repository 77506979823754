'use client'

import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid'
import { cn } from '@mntn-dev/ui-utilities'
import { type ButtonHTMLAttributes, forwardRef } from 'react'

import { themeBackgroundMap } from '@mntn-dev/ui-theme'

import { type TestIds, getTestProps } from '../../utils/testing.ts'

type CircleButtonProps = Readonly<
  ButtonHTMLAttributes<HTMLButtonElement> &
    TestIds & {
      disabled?: boolean
      fullWidth?: boolean
      leftNavigation?: boolean
    }
>

const CircleButton = forwardRef<HTMLButtonElement, CircleButtonProps>(
  (
    {
      dataTestId,
      dataTrackingId,
      fullWidth,
      leftNavigation,
      className,
      type,
      ...props
    },
    ref
  ) => {
    return (
      <button
        type={type ?? 'button'}
        ref={ref}
        {...props}
        {...getTestProps({ dataTestId, dataTrackingId })}
        className={cn(
          fullWidth && 'w-full',
          `flex h-14 w-14 flex-col items-center justify-center rounded-full ${themeBackgroundMap.container} shrink-0 cursor-default px-3 opacity-70`,
          'hover:shadow-glow-blue active:opacity-80 disabled:opacity-10 disabled:shadow-none',
          className
        )}
        aria-disabled={props.disabled}
      >
        {leftNavigation ? (
          <ArrowLeftIcon className={'text-primary-blue h-5'} />
        ) : (
          <ArrowRightIcon className={'text-primary-blue h-5'} />
        )}
      </button>
    )
  }
)

export { CircleButton, type CircleButtonProps }
