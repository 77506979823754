'use client'

import { type ThemeSpacing, spacingToPixels } from '@mntn-dev/ui-theme'
import { type PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useScroll } from '../layout/sidebar/sidebar-layout-main.tsx'

export const StickyDiv = ({
  margin,
  children,
}: PropsWithChildren<{
  margin: ThemeSpacing
}>) => {
  const marginY = spacingToPixels(margin)
  const margin2Y = marginY * 2

  const ref = useRef<HTMLDivElement>(null)
  const [offsetTopPx, setOffsetTopPx] = useState<number>(0)
  const [height, setHeight] = useState<number>(window.innerHeight - offsetTopPx)
  const { scrollY } = useScroll()

  useEffect(() => {
    if (ref.current) {
      setOffsetTopPx(ref.current.getBoundingClientRect().top + marginY)
    }
  }, [marginY])

  useEffect(() => {
    setHeight(window.innerHeight - Math.max(offsetTopPx - scrollY, margin2Y))
  }, [scrollY, margin2Y, offsetTopPx])

  return (
    <div
      ref={ref}
      className="sticky"
      style={{ height: `${height}px`, top: `${marginY}px` }}
    >
      {children}
    </div>
  )
}
