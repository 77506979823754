import { Icon } from '../../icon'
import { useConfirmationModalContext } from './use-confirmation-modal.tsx'

export const ConfirmationModalClose = () => {
  const { getConfirmationModalProps } = useConfirmationModalContext()
  const { onClose } = getConfirmationModalProps()
  return (
    <button
      type="button"
      className="absolute right-0 top-0 z-20 flex h-8 w-8 cursor-pointer items-start justify-end"
      onClick={onClose}
    >
      <Icon
        name="XMarkIcon"
        color="primary"
        fill="solid"
        className="transition-all duration-200 ease-in-out hover:text-blue-100"
        size="md"
      />
    </button>
  )
}
