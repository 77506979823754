'use client'

import { themeTextColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'
import { type DataListVariant, useDataListContext } from './use-data-list.ts'

type DataListTitleProps = PropsWithChildren

const variantColorMap: Record<DataListVariant, string> = {
  default: themeTextColorMap.secondary,
  subtle: themeTextColorMap.tertiary,
  inverted: themeTextColorMap.primary,
}

const DataListTitle = ({ children }: DataListTitleProps) => {
  const { fontSizeClassName, variant } = useDataListContext()

  return (
    <dt
      className={cn(
        'leading-normal whitespace-nowrap overflow-hidden text-ellipsis',
        fontSizeClassName,
        variantColorMap[variant]
      )}
    >
      {children}
    </dt>
  )
}

export { DataListTitle }
