import type { UIEvent } from 'react'

import { Icon } from '../../icon/icon.tsx'
import { PopoutMenu } from '../../popout/popout-menu.tsx'

type BladeMediaMenuProps = {
  canDelete?: boolean
  canView?: boolean
  onDelete: (event: UIEvent) => void
  onView?: (event: UIEvent) => void
}

const BladeMediaMenu = ({
  canDelete,
  canView,
  onDelete,
  onView,
}: BladeMediaMenuProps) => {
  return (
    (canDelete || canView) && (
      <PopoutMenu
        dataTestId="blade-media-dropdown-menu"
        dataTrackingId="blade-media-dropdown-menu"
      >
        {canView && (
          <PopoutMenu.Item
            icon={<Icon fill="solid" size="sm" name="EyeIcon" color="info" />}
            title="View"
            onItemSelect={onView ?? undefined}
            dataTestId="blade-media-view"
            dataTrackingId="blade-media-view"
          />
        )}
        {canDelete && (
          <PopoutMenu.Item
            icon={
              <Icon fill="solid" size="sm" name="TrashIcon" color="negative" />
            }
            title="Delete"
            onItemSelect={onDelete}
            dataTestId="blade-media-delete"
            dataTrackingId="blade-media-delete"
          />
        )}
      </PopoutMenu>
    )
  )
}

export { BladeMediaMenu, type BladeMediaMenuProps }
