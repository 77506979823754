'use client'

import { type ThemeHeight, themeTextColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'
import { useSizing } from '../../hooks/use-sizing.ts'
import { type DataListVariant, useDataListContext } from './use-data-list.ts'

type DataListDescriptionProps = PropsWithChildren<ThemeHeight> & {
  singleLine?: boolean
}

const variantColorMap: Record<DataListVariant, string> = {
  default: themeTextColorMap.primary,
  subtle: themeTextColorMap.secondary,
  inverted: themeTextColorMap.secondary,
}

const DataListDescription = ({
  children,
  singleLine,
  ...heightProps
}: DataListDescriptionProps) => {
  const { fontSizeClassName, variant } = useDataListContext()
  const { heightClassName } = useSizing(heightProps)

  return (
    <dd
      className={cn(
        'text-ellipsis overflow-hidden',
        fontSizeClassName,
        variantColorMap[variant],
        heightClassName,
        {
          'whitespace-nowrap': singleLine,
        }
      )}
    >
      {children}
    </dd>
  )
}

export { DataListDescription }
