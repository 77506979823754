'use client'

import { themeDivideColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'

type BladeListProps = {
  divide?: boolean
  children: React.ReactNode
}

const BladeList = ({ divide, children }: BladeListProps) => {
  return (
    <ul
      className={cn('flex w-full flex-col', [
        divide ? `divide-y ${themeDivideColorMap.muted}` : null,
      ])}
    >
      {children}
    </ul>
  )
}

export { BladeList, type BladeListProps }
