'use client'

import { fontSizeMap } from '../../classes/font-size.ts'
import type { FontSize } from '../../types/font.ts'
import { createContext } from '../../utils/context.ts'

type DataListVariant = 'default' | 'subtle' | 'inverted'

type UseDataListProps = {
  fontSize?: FontSize
  variant?: DataListVariant
}

const useDataList = ({
  fontSize = 'med',
  variant = 'default',
}: UseDataListProps) => {
  return { fontSizeClassName: fontSizeMap[fontSize], variant }
}

const [DataListProvider, useDataListContext] = createContext<
  ReturnType<typeof useDataList>
>({ name: 'DataListContext' })

export {
  DataListProvider,
  useDataListContext,
  useDataList,
  type DataListVariant,
  type UseDataListProps,
}
