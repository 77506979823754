import { themeTextColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { LoadingSpinner } from '../loading/loading-spinner.tsx'
import { useAvatarContext } from './use-avatar.ts'

const AvatarLoading = () => {
  const { sizeClassName } = useAvatarContext()
  return (
    <div
      className={cn('absolute flex items-center justify-center', sizeClassName)}
    >
      <LoadingSpinner className={cn('w-4/5 h-4/5', themeTextColorMap.info)} />
    </div>
  )
}

export { AvatarLoading }
