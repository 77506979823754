import type { TupleToUnion } from 'type-fest'

export const textSizes = [
  'inherit',
  '3xs',
  '2xs',
  'xs',
  'sm',
  'med',
  'lg',
] as const
export type TextSize = TupleToUnion<typeof textSizes>

export const headingSizes = [
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl',
  'display',
  'displayXl',
] as const
export type HeadingSize = TupleToUnion<typeof headingSizes>

export const fontSizes = [...textSizes, ...headingSizes] as const
export type FontSize = TupleToUnion<typeof fontSizes>

export const fontWeights = [
  'light',
  'normal',
  'medium',
  'semibold',
  'bold',
  'black',
] as const
export type FontWeight = TupleToUnion<typeof fontWeights>

export const fontFamily = ['sans', 'heading'] as const
export type FontFamily = TupleToUnion<typeof fontFamily>

export const textTransform = [
  'uppercase',
  'lowercase',
  'capitalize',
  'normal',
] as const
export type TextTransform = TupleToUnion<typeof textTransform>
