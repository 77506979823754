import type { PropsWithChildren } from 'react'

import { themeBackgroundMap } from '@mntn-dev/ui-theme'

type TableFootProps = PropsWithChildren<{ className?: string }>

const TableFoot = ({ className = '', children }: TableFootProps) => {
  return (
    <tfoot className={`${themeBackgroundMap.container} ${className}`}>
      {children}
    </tfoot>
  )
}

export { TableFoot, type TableFootProps }
