'use client'
import { useEffect } from 'react'
import { MultiselectOption } from './multiselect-option.tsx'
import {
  type MultiselectOptionItem,
  useMultiselectContext,
} from './use-multiselect.ts'

type MultiselectInlineOptionProps = {
  item: MultiselectOptionItem
}

const MultiselectInlineOption = ({ item }: MultiselectInlineOptionProps) => {
  const { isItemVisible, registerItem, onChange, selectedItems } =
    useMultiselectContext()

  useEffect(() => {
    registerItem(item)
  }, [item, registerItem])

  const visible = isItemVisible(item)

  const selected = selectedItems?.some(
    (selectedItem) => selectedItem.id === item.id
  )

  const handleClick = () => {
    if (!selectedItems) {
      onChange([item])
      return
    }

    onChange(
      selected
        ? selectedItems.filter((i) => i.id !== item.id)
        : selectedItems.concat(item)
    )
  }

  return visible ? (
    <MultiselectOption item={item} onClick={handleClick} />
  ) : null
}

export { MultiselectInlineOption }
