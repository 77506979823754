'use client'

import { Checkbox as CheckboxUI, Field } from '@headlessui/react'
import { cn } from '@mntn-dev/ui-utilities'
import { type PropsWithChildren, useEffect, useState } from 'react'

import { themeBackgroundMap } from '@mntn-dev/ui-theme'

import { forwardRef } from '../../utils'
import {
  type UseFormFieldControlProps,
  useFormFieldControl,
} from '../form-field'
import { Icon } from '../icon'

type CheckboxProps = Readonly<
  PropsWithChildren<
    {
      value?: boolean
      onChange?: (value: boolean) => void
      className?: string
    } & UseFormFieldControlProps<HTMLInputElement>
  >
>

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      value,
      onChange,
      disabled: disabledProp,
      id,
      hasError: hasErrorProp,
      hasWarning: hasWarningProp,
      hasSuccess: hasSuccessProp,
      readOnly: readOnlyProp,
      children,
      dataTestId,
      dataTrackingId,
      ...checkboxProps
    },
    ref
  ) => {
    const {
      disabled,
      hasError,
      hasSuccess,
      hasWarning,
      readOnly,
      ...fieldProps
    } = useFormFieldControl<HTMLInputElement>({
      disabled: disabledProp,
      id,
      hasError: hasErrorProp,
      hasWarning: hasWarningProp,
      hasSuccess: hasSuccessProp,
      readOnly: readOnlyProp,
      dataTestId,
      dataTrackingId,
    })

    const [isChecked, setChecked] = useState<boolean | undefined>(value)
    const isIndeterminate = isChecked === undefined

    useEffect(() => {
      setChecked(value)
    }, [value])

    const handleChange = (value: boolean) => {
      setChecked(value)

      if (onChange) {
        onChange(value)
      }
    }

    return (
      <Field className="flex gap-x-3 items-center">
        <CheckboxUI
          ref={ref}
          checked={isChecked}
          indeterminate={isIndeterminate}
          onChange={handleChange}
          {...checkboxProps}
          {...fieldProps}
        >
          <span
            className={cn(
              `flex h-[28px] w-[28px] shrink-0 items-center rounded-sm border p-1.5 ${themeBackgroundMap['container-secondary']}`,
              'hover:shadow-glow-blue-md',
              'active:border-opacity-50 active:shadow-none',
              {
                'border-primary-blue': isChecked || isIndeterminate,
                'border-primary-red': hasError,
                'border-white/20': !(isChecked || isIndeterminate || hasError),
                'cursor-not-allowed opacity-50 hover:shadow-none': disabled,
                'hover:border-primary-blue': !disabled,
              }
            )}
          >
            {isChecked && (
              <Icon
                fill="solid"
                name="CheckIcon"
                size="sm"
                className={cn('fill-primary-blue')}
              />
            )}

            {isIndeterminate && (
              <Icon
                fill="solid"
                name="MinusIcon"
                size="sm"
                className={cn('fill-primary-blue')}
              />
            )}
          </span>
        </CheckboxUI>

        <span className={cn(hasError && '*:text-primary-red')}>{children}</span>
      </Field>
    )
  }
)

export { Checkbox, type CheckboxProps }
