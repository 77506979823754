import type { ThemeFlexParent, ThemeGap } from '@mntn-dev/ui-theme'
import { type PropsWithChildren, type RefAttributes, forwardRef } from 'react'
import { Stack } from '../stack/stack.tsx'

type ChipsProps = Readonly<
  PropsWithChildren<
    RefAttributes<HTMLDivElement> &
      Pick<ThemeFlexParent, 'direction'> &
      Pick<ThemeGap, 'gap'> & { className?: string }
  >
>

const Chips = forwardRef<HTMLDivElement, ChipsProps>(
  ({ children, className, direction, gap = '2' }: ChipsProps, ref) => {
    return (
      <Stack direction={direction} gap={gap} className={className} ref={ref}>
        {children}
      </Stack>
    )
  }
)

export { Chips }
