'use client'

import type React from 'react'

type ImageCardDescriptionProps = Readonly<{
  children: React.ReactNode
}>

const ImageCardDescription = ({ children }: ImageCardDescriptionProps) => {
  return <div className="flex flex-col gap-2">{children}</div>
}

export { type ImageCardDescriptionProps, ImageCardDescription }
