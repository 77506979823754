'use client'

import type { PropsWithChildren } from 'react'

import { Text } from '../text/text.tsx'

type RatingLabelProps = Readonly<PropsWithChildren>

const RatingLabel = ({ children }: RatingLabelProps) => {
  return (
    <Text className="w-32 text-right" fontSize="med" textColor="primary">
      {children}
    </Text>
  )
}

export { RatingLabel, type RatingLabelProps }
