'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type { ReactNode } from 'react'

import { type PageBackground, pageBackgroundMap } from '@mntn-dev/ui-theme'

import {
  SidebarLayoutProvider,
  useSidebarLayout,
} from './use-sidebar-layout.ts'

type SidebarLayoutProps = Readonly<{
  background?: PageBackground
  children?: ReactNode
}>

const SidebarLayout = ({
  background = 'default',
  children,
}: SidebarLayoutProps) => {
  const context = useSidebarLayout()

  return (
    <SidebarLayoutProvider value={context}>
      <div
        className={cn(
          'h-screen bg-cover bg-center bg-fixed relative overflow-hidden',
          pageBackgroundMap[background]
        )}
      >
        {children}
      </div>
    </SidebarLayoutProvider>
  )
}

export { SidebarLayout, type SidebarLayoutProps }
