'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'
import type { HTMLAttributes } from 'react'

import {
  type ThemeBackground,
  type ThemeBorderColor,
  themeBackgroundMap,
  themeBorderColorMap,
  themeDivideColorMap,
} from '@mntn-dev/ui-theme'

import { borderRadiusMap } from '../../classes/border-radius.ts'
import { type UseSizingProps, useSizing } from '../../hooks/use-sizing.ts'
import type { BorderRadiusSize } from '../../types/border-radius.ts'
import { type TestIds, getTestProps } from '../../utils/index.ts'
import { SurfaceSection } from './surface-section.tsx'
import {
  SurfaceProvider,
  type UseSurfaceProps,
  useSurface,
  useSurfaceContext,
} from './use-surface.ts'

type SurfaceProps = HTMLAttributes<HTMLElement> &
  UseSurfaceProps &
  UseSizingProps &
  TestIds & {
    as?: React.ElementType
    backgroundColor?: ThemeBackground
    border?: boolean
    borderColor?: ThemeBorderColor
    borderRadius?: BorderRadiusSize
    children?: React.ReactNode
    className?: string
    divide?: boolean
  }

function SurfaceComponent(props: SurfaceProps) {
  const context = useSurface(props)

  return (
    <SurfaceProvider value={context}>
      <SurfaceContent {...props} />
    </SurfaceProvider>
  )
}

function SurfaceContent({
  backgroundColor = 'container-secondary',
  border,
  borderColor,
  borderRadius = 'lg',
  children,
  className = '',
  divide = true,
  elevation,
  gap,
  height,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  size,
  width,
  dataTestId,
  dataTrackingId,
  ...props
}: SurfaceProps) {
  const {
    elevationClassName,
    gapClassName,
    hasSurfaceChildren,
    marginClassName,
    paddingClassName,
  } = useSurfaceContext()

  const sizing = useSizing({
    height,
    maxHeight,
    minHeight,
    size,
    width,
    maxWidth,
    minWidth,
  })

  const { as: Component = 'section' } = props

  return (
    <Component
      {...props}
      className={cn(
        `flex flex-col ${themeBackgroundMap[backgroundColor]} ${borderRadiusMap[borderRadius]} ${className}`,
        ...Object.values(sizing),
        {
          [`border ${themeBorderColorMap[borderColor ?? 'muted']}`]: border,
          [`divide-y ${themeDivideColorMap.muted}`]:
            hasSurfaceChildren && divide,
          [`${marginClassName}`]: !!marginClassName,
          [`${paddingClassName}`]: !hasSurfaceChildren && !!paddingClassName,
          [`${elevationClassName}`]: !!elevationClassName,
          [`${gapClassName}`]: !!gapClassName,
        }
      )}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
    </Component>
  )
}

const SurfaceNamespace = Object.assign(SurfaceComponent, {
  Header: SurfaceSection,
  Section: SurfaceSection,
  Body: SurfaceSection,
  Footer: SurfaceSection,
})

export { SurfaceNamespace as Surface, type SurfaceProps }
