'use client'

import { cn } from '@mntn-dev/ui-utilities'
import { type ButtonHTMLAttributes, forwardRef } from 'react'

import {
  type ThemeHeightOption,
  type ThemeSpacing,
  type ThemeTextColor,
  themeTextColorMap,
} from '@mntn-dev/ui-theme'
import { fontSizeMap } from '../../classes/font-size.ts'
import { useHeight } from '../../hooks/use-height.ts'
import { useSpacing } from '../../hooks/use-spacing.ts'
import type { FontSize } from '../../types/font.ts'
import { type TestIds, getTestProps } from '../../utils/testing.ts'

type LinkButtonSize = 'xs' | 'sm' | 'lg'

type LinkButtonProps = Readonly<
  ButtonHTMLAttributes<HTMLButtonElement> &
    TestIds & {
      children: string
      disabled?: boolean
      size?: LinkButtonSize
      textColor?: ThemeTextColor
    }
>

const sizeClassMap: Record<
  LinkButtonSize,
  { fontSize: FontSize; height?: ThemeHeightOption; paddingX?: ThemeSpacing }
> = {
  xs: { fontSize: 'xs' },
  sm: { fontSize: 'sm' },
  lg: { fontSize: 'lg', height: '14', paddingX: '6' },
}

const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>(
  (
    {
      children,
      dataTestId,
      dataTrackingId,
      className,
      size = 'sm',
      textColor = 'link',
      type,
      ...props
    },
    ref
  ) => {
    const { fontSize, height, paddingX } = sizeClassMap[size]
    const { heightClassName } = useHeight({ height })
    const { paddingClassName } = useSpacing({ paddingX })

    return (
      <button
        type={type ?? 'button'}
        ref={ref}
        {...props}
        {...getTestProps({ dataTestId, dataTrackingId })}
        className={cn(
          'font-normal underline',
          fontSizeMap[fontSize],
          heightClassName,
          paddingClassName,
          {
            [`${themeTextColorMap.disabled} cursor-text`]: props.disabled,
            [themeTextColorMap[textColor]]: !props.disabled,
          },
          className
        )}
      >
        {children}
      </button>
    )
  }
)

export { LinkButton, type LinkButtonProps, type LinkButtonSize }
