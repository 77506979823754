'use client'

import { Button, type ButtonProps } from '../../buttons/button.tsx'

type DialogModalButtonProps = Readonly<ButtonProps>

const DialogModalButton = ({ children, ...props }: DialogModalButtonProps) => {
  return (
    <Button width="full" {...props}>
      {children}
    </Button>
  )
}

export { DialogModalButton, type DialogModalButtonProps }
