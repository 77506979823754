import type {
  Position,
  Positioning,
  ThemePositioning,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { usePositioning } from '../../hooks/use-positioning.ts'

type DecoratorAnchor = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

type UseDecoratorProps = {
  anchor: DecoratorAnchor
  offsetX?: Positioning
  offsetY?: Positioning
}

function useDecorator({ anchor, offsetX, offsetY }: UseDecoratorProps) {
  const [y, x] = anchor.split('-') as [Position, Position]

  const position: ThemePositioning = {
    [x]: offsetX ?? '0',
    [y]: offsetY ?? '0',
  }

  const { positionClassName } = usePositioning(position)

  return { className: cn('absolute leading-none z-20', positionClassName) }
}

export { useDecorator, type DecoratorAnchor, type UseDecoratorProps }
