import { useMemo } from 'react'

import { getWidthClassName, type ThemeWidth } from '@mntn-dev/ui-theme'

type UseWidthProps = ThemeWidth

function useWidth({ width, maxWidth, minWidth }: UseWidthProps) {
  const widthClassName = useMemo(
    () => getWidthClassName({ width, maxWidth, minWidth }),
    [width, maxWidth, minWidth]
  )

  return { widthClassName }
}

export { useWidth, type UseWidthProps }
