'use client'

import { type InputHTMLAttributes, forwardRef } from 'react'

import type { TestIds } from '../../utils/testing.ts'
import {
  type UseFormFieldControlProps,
  useFormFieldControl,
} from '../form-field/use-form-field-control.ts'

export type RadioProps = Readonly<
  UseFormFieldControlProps<HTMLInputElement> &
    InputHTMLAttributes<HTMLInputElement> &
    TestIds & {
      disabled?: boolean
      fullWidth?: boolean
    }
>

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      disabled,
      id,
      hasError: hasErrorProp,
      readOnly,
      dataTestId,
      dataTrackingId,
      ...radioProps
    },
    ref
  ) => {
    const { hasError: _, ...fieldProps } =
      useFormFieldControl<HTMLInputElement>({
        disabled,
        id,
        hasError: hasErrorProp,
        readOnly,
        dataTestId,
        dataTrackingId,
      })

    return (
      <input
        {...radioProps}
        {...fieldProps}
        type="radio"
        className="text-brand-blue checked:ring-brand-blue rounded-full checked:bg-none checked:ring-2 checked:ring-inset checked:ring-offset-2 focus:ring-transparent enabled:cursor-pointer disabled:cursor-not-allowed disabled:opacity-70"
        aria-disabled={disabled}
        ref={ref}
      />
    )
  }
)

export { Radio }
