'use client'

import { type ReactNode, useCallback } from 'react'
import { Chip } from '../chip/chip.tsx'
import { Chips } from '../chip/chips.tsx'
import { Stack } from '../stack/stack.tsx'
import {
  type MultivalueObjectValue,
  type MultivalueValue,
  getNormalizedValue,
  getValue,
  hasMultivalueItem,
  removeMultivalueItem,
} from './utils.ts'

type AddFunction<T extends MultivalueValue> = (value: T) => void

type MultivalueRenderProps<T extends MultivalueValue> = {
  onAdd: AddFunction<T>
}

type MultivalueRenderPropsFunction<T extends MultivalueValue> = (
  props: MultivalueRenderProps<T>
) => ReactNode

type MultivalueProps<T extends MultivalueValue> = {
  children: ReactNode | MultivalueRenderPropsFunction<T>
  onChange?: (value: T[]) => void
  values?: T[]
}

const Multivalue = <T extends MultivalueValue>({
  children,
  onChange,
  values,
}: MultivalueProps<T>) => {
  const handleRemove = (value: string) => () => {
    if (onChange) {
      const newValues = removeMultivalueItem(value, values)
      onChange(newValues ?? [])
    }
  }

  const handleAdd = useCallback(
    (value: T) => {
      if (hasMultivalueItem(getValue(value), values)) {
        return
      }

      if (onChange) {
        values ? onChange([...values, value]) : onChange([value])
      }
    },
    [onChange, values]
  )

  return (
    <Stack direction="col" gap="3">
      {values && values.length > 0 && (
        <Chips direction="col">
          {values.map((v) => {
            const { label, value } = getNormalizedValue(v)

            return (
              <Chip
                key={value}
                dismissable
                onDismissClick={handleRemove(value)}
              >
                {label}
              </Chip>
            )
          })}
        </Chips>
      )}
      {typeof children === 'function'
        ? children({ onAdd: handleAdd })
        : children}
    </Stack>
  )
}

export {
  Multivalue,
  type AddFunction,
  type MultivalueProps,
  type MultivalueValue,
  type MultivalueObjectValue,
}
