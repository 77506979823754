'use client'

import type React from 'react'
import { useCallback, useId } from 'react'

import { createContext } from '../../utils/context.ts'
import { getTestProps, type TestIds } from '../../utils/testing.ts'

type UseCardProps = TestIds & {
  id?: string
}

function useCard({ id: idProp, dataTestId, dataTrackingId }: UseCardProps) {
  const uuid = useId()
  const id = idProp || uuid

  const titleId = `${id}-title`
  const subtitleId = `${id}-subtitle`

  const getTitleProps = useCallback<() => React.HTMLAttributes<HTMLElement>>(
    () => ({
      id: titleId,
      ...getTestProps({
        dataTestId: dataTestId && `${dataTestId}-title`,
        dataTrackingId: dataTrackingId && `${dataTrackingId}-title`,
      }),
    }),
    [dataTestId, dataTrackingId, titleId]
  )

  const getSubtitleProps = useCallback<() => React.HTMLAttributes<HTMLElement>>(
    () => ({
      id: subtitleId,
      ...getTestProps({
        dataTestId: dataTestId && `${dataTestId}-subtitle`,
        dataTrackingId: dataTrackingId && `${dataTrackingId}-subtitle`,
      }),
    }),
    [dataTestId, dataTrackingId, subtitleId]
  )

  return {
    id,
    dataTestId,
    dataTrackingId,
    getSubtitleProps,
    getTitleProps,
    titleId,
  }
}

type UseCardReturn = ReturnType<typeof useCard>

const [CardProvider, useCardContext] = createContext<UseCardReturn>({
  name: 'CardProvider',
})

export {
  CardProvider,
  type UseCardProps,
  type UseCardReturn,
  useCard,
  useCardContext,
}
