'use client'

import type { ThemeFlexParent } from '@mntn-dev/ui-theme'
import type { PropsWithChildren } from 'react'
import { Stack } from '../../../stack/stack.tsx'

type FooterContentProps = Readonly<
  PropsWithChildren<
    Pick<ThemeFlexParent, 'justifyContent' | 'alignItems' | 'gap'>
  >
>

const FooterContent = ({
  children,
  alignItems = 'center',
  justifyContent = 'end',
  gap = '6',
}: FooterContentProps) => (
  <Stack
    width="full"
    columnGap={gap}
    alignItems={alignItems}
    justifyContent={justifyContent}
  >
    {children}
  </Stack>
)

export { FooterContent }
