'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'

import { useTextareaFormContext } from './use-textarea-form.tsx'

type TextareaFormToolbarProps = Readonly<{
  children: React.ReactNode
}>

const TextareaFormToolbar = ({ children }: TextareaFormToolbarProps) => {
  const { hasToolbarMenu } = useTextareaFormContext()
  return (
    <div
      className={cn(
        'absolute inset-x-0 bottom-0 flex justify-end py-2 pl-3 pr-2',
        { 'justify-between': hasToolbarMenu }
      )}
    >
      {children}
    </div>
  )
}

export { TextareaFormToolbar, type TextareaFormToolbarProps }
