'use client'

import { Menu, MenuItems } from '@headlessui/react'
import type { PropsWithChildren } from 'react'

import { type TestIds, getTestProps } from '../../utils'
import { PopoutButton } from './popout-button.tsx'
import { PopoutItem } from './popout-item.tsx'

type PopoutMenuProps = PropsWithChildren<TestIds>

const PopoutMenu = ({
  children,
  dataTestId,
  dataTrackingId,
}: PopoutMenuProps) => {
  return (
    <Menu
      as="div"
      className="relative flex text-left"
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      <PopoutButton />
      <MenuItems
        anchor="bottom end"
        className="w-fit z-30 mt-2 flex flex-col items-center"
      >
        {children}
      </MenuItems>
    </Menu>
  )
}

const PopoutMenuComponent = Object.assign(PopoutMenu, {
  Button: PopoutButton,
  Item: PopoutItem,
})

export { PopoutMenuComponent as PopoutMenu }
