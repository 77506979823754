'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type { ReactNode } from 'react'
import { useSidebarLayoutContext } from './use-sidebar-layout.ts'

type SidebarLayoutContentProps = {
  children: ReactNode
}

const SidebarLayoutContent = ({ children }: SidebarLayoutContentProps) => {
  const { clampWidthClassName, contentHeightStyle } = useSidebarLayoutContext()

  return (
    <div className="w-full" style={contentHeightStyle}>
      <div className={cn('lg:p-8 mx-auto', clampWidthClassName)}>
        {children}
      </div>
    </div>
  )
}

export { SidebarLayoutContent }
