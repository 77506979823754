import { useMemo } from 'react'

import {
  getMarginClassName,
  getPaddingClassName,
  type ThemeMargin,
  type ThemePadding,
} from '@mntn-dev/ui-theme'

type UseSpacingProps = ThemeMargin & ThemePadding

function useSpacing({
  margin,
  marginX,
  marginY,
  marginTop,
  marginLeft,
  marginBottom,
  marginRight,
  padding,
  paddingX,
  paddingY,
  paddingTop,
  paddingLeft,
  paddingBottom,
  paddingRight,
}: UseSpacingProps) {
  const marginClassName = useMemo(
    () =>
      getMarginClassName({
        margin,
        marginX,
        marginY,
        marginTop,
        marginLeft,
        marginBottom,
        marginRight,
      }),
    [margin, marginX, marginY, marginTop, marginLeft, marginBottom, marginRight]
  )

  const paddingClassName = useMemo(
    () =>
      getPaddingClassName({
        padding,
        paddingX,
        paddingY,
        paddingTop,
        paddingLeft,
        paddingBottom,
        paddingRight,
      }),
    [
      padding,
      paddingX,
      paddingY,
      paddingTop,
      paddingLeft,
      paddingBottom,
      paddingRight,
    ]
  )

  return { marginClassName, paddingClassName }
}

export { useSpacing, type UseSpacingProps }
