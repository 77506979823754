'use client'

import type { PropsWithChildren } from 'react'

import type { ThemeFlexParent } from '@mntn-dev/ui-theme'

import { Stack } from '../../stack/index.ts'
import { ModalHeading } from '../modal-heading.tsx'
import { ModalSubheading } from '../modal-subheading.tsx'
import { DialogModalHeaderBanner } from './dialog-modal-header-banner.tsx'
import { DialogModalHeaderHeadline } from './dialog-modal-header-headline.tsx'

const DialogModalHeader = ({
  gap = '4',
  children,
}: PropsWithChildren<ThemeFlexParent>) => {
  return (
    <Stack direction="col" gap={gap}>
      {children}
    </Stack>
  )
}

const DialogModalHeaderNamespace = Object.assign(DialogModalHeader, {
  Banner: DialogModalHeaderBanner,
  Headline: DialogModalHeaderHeadline,
  Heading: ModalHeading,
  Subheading: ModalSubheading,
})

export { DialogModalHeaderNamespace as DialogModalHeader }
