'use client'

import type { PropsWithChildren } from 'react'

import { Surface, type SurfaceProps } from '../surface'

type ModalSurfaceProps = Readonly<PropsWithChildren<SurfaceProps>>

const ModalSurface = ({ children, ...props }: ModalSurfaceProps) => {
  return (
    <Surface border={true} padding={props.padding ?? '8'} {...props}>
      {children}
    </Surface>
  )
}

const ModalSurfaceNamespace = Object.assign(ModalSurface, {
  Header: Surface.Header,
  Body: Surface.Body,
  Footer: Surface.Footer,
})

export { ModalSurfaceNamespace as ModalSurface, type ModalSurfaceProps }
