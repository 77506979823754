'use client'

import { Icon, type IconProps } from '../icon/icon.tsx'
import type { IconSize } from '../icon/index.ts'
import type { BadgeSize } from './types.ts'

type BadgeIconProps = Pick<IconProps, 'name' | 'fill'> & { size: BadgeSize }

const badgeIconSizeMap: Record<BadgeSize, IconSize> = {
  '3xs': '3xs',
  xs: '2xs',
  sm: '2xs',
  md: 'xs',
  lg: 'sm',
}

const BadgeIcon = ({ fill = 'outline', name, size }: BadgeIconProps) => {
  if (size === '3xs') {
    return null
  }

  return (
    <Icon
      name={name}
      size={badgeIconSizeMap[size]}
      fill={fill}
      color="current"
    />
  )
}

export { BadgeIcon, type BadgeIconProps }
