'use client'
import { cn } from '@mntn-dev/ui-utilities'
import { NativeImage } from '@mntn-dev/ui-utilities'
import type { AbstractImage } from '@mntn-dev/ui-utilities'
import { useEffect, useState } from 'react'
import {
  type Person,
  getPersonFullName,
  getPersonInitials,
} from '../../types/index.ts'
import { Heading } from '../heading/heading.tsx'
import { AvatarLoading } from './avatar-loading.tsx'
import { useAvatarContext } from './use-avatar.ts'

type AvatarUserProps = Readonly<{
  person: Person
  image?: AbstractImage
  loading?: boolean
}>

type LoadState = 'loading' | 'loaded' | 'error' | 'no-url'

const AvatarUser = ({
  person: { firstName, lastName, imageUrl },
  image: Image = NativeImage,
}: AvatarUserProps) => {
  const {
    borderColor,
    pixelSize,
    sizeClassName,
    textSizeClassName,
    setHasUserImage,
  } = useAvatarContext()
  const [loadState, setLoadState] = useState<LoadState>(
    imageUrl ? 'loading' : 'no-url'
  )

  useEffect(() => {
    if (imageUrl) {
      setLoadState('loading')
    }
  }, [imageUrl])

  const alt = getPersonFullName({ firstName, lastName })

  const handleLoadingError = () => {
    setLoadState('error')
    setHasUserImage(false)
  }

  const handleLoadComplete = () => {
    setLoadState('loaded')
    setHasUserImage(true)
  }

  return loadState !== 'no-url' && loadState !== 'error' ? (
    <>
      <Image
        onLoad={handleLoadComplete}
        onError={handleLoadingError}
        alt={alt}
        src={imageUrl}
        height={pixelSize}
        width={pixelSize}
        className={cn('object-cover rounded-full overflow-hidden', {
          invisible: loadState === 'loading',
        })}
      />
      <div
        className={cn('absolute rounded-full', sizeClassName, {
          '-m-0.5 shadow-raised-inner': !!borderColor,
        })}
      />
      {loadState === 'loading' && <AvatarLoading />}
    </>
  ) : (
    <Heading
      textColor={borderColor}
      className={cn('leading-none', textSizeClassName)}
    >
      {getPersonInitials({ firstName, lastName })}
    </Heading>
  )
}

export { AvatarUser, type AvatarUserProps }
