import type { SvgIconProps } from '../types/props.ts'

const Users = (props: SvgIconProps) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    aria-label="Users icon"
    role="img"
    width="1em"
    height="1em"
  >
    <path
      d="M5.3,8c0-3.3,2.6-5.9,5.9-5.9s5.9,2.6,5.9,5.9s-2.6,5.9-5.9,5.9S5.3,11.2,5.3,8z M19.2,11.2c0-2.7,2.2-4.8,4.8-4.8
	s4.8,2.2,4.8,4.8S26.7,16,24,16S19.2,13.8,19.2,11.2z M1,26.2C1,20.6,5.6,16,11.2,16s10.2,4.6,10.2,10.2v0l0,0.2
	c0,0.4-0.2,0.7-0.5,0.9c-2.9,1.8-6.3,2.7-9.7,2.7c-3.5,0-6.8-1-9.7-2.7C1.2,27.1,1,26.7,1,26.4C1,26.4,1,26.2,1,26.2z M23.5,26.2
	l0,0.2c0,0.5-0.1,0.9-0.3,1.4c2.5,0.2,5-0.3,7.2-1.4c0.4-0.2,0.6-0.5,0.6-0.9c0.2-3.8-2.8-7.1-6.7-7.3c-1.1,0-2.3,0.2-3.3,0.7
	C22.6,20.9,23.5,23.5,23.5,26.2L23.5,26.2L23.5,26.2z"
    />
  </svg>
)

export { Users }
