import type { PropsWithChildren } from 'react'
import { Badge, type BadgeProps } from '../badge/badge.tsx'
import { type UseDecoratorProps, useDecorator } from './use-decorator.ts'

type DecoratorProps = PropsWithChildren<BadgeProps & UseDecoratorProps>

const Decorator = ({
  anchor,
  children,
  offsetX,
  offsetY,
  ...badgeProps
}: DecoratorProps) => {
  const { className } = useDecorator({ anchor, offsetX, offsetY })
  return (
    <div className="relative">
      <div className={className}>
        <Badge {...badgeProps} />
      </div>
      {children}
    </div>
  )
}

export { Decorator, type DecoratorProps }
