import { Modal, type ModalProps } from '../modal/modal.tsx'
import { LoadingCenter } from './loading-center.tsx'

const LoadingModal = ({ ...props }: Omit<ModalProps, 'children'>) => (
  <Modal {...props}>
    <LoadingCenter />
  </Modal>
)

export { LoadingModal }
