'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type { ElementType, PropsWithChildren } from 'react'

import type { ThemeGridChild } from '@mntn-dev/ui-theme'

import { useGridChild } from '../../hooks/use-grid-child.ts'
import { type UseSpacingProps, useSpacing } from '../../hooks/use-spacing.ts'

type GridItemProps = Readonly<
  PropsWithChildren<
    ThemeGridChild &
      UseSpacingProps & {
        as?: ElementType
        className?: string
      }
  >
>

const GridItem = ({
  as: Component = 'div',
  children,
  className,
  ...props
}: GridItemProps) => {
  const { gridChildClassName } = useGridChild(props)
  const { marginClassName, paddingClassName } = useSpacing(props)

  return (
    <Component
      className={cn(
        gridChildClassName,
        marginClassName,
        paddingClassName,
        className
      )}
    >
      {children}
    </Component>
  )
}

export { GridItem, type GridItemProps }
