import { Icon } from '../icon/icon.tsx'
import { useModalContext } from './use-modal.ts'

const ModalClose = () => {
  const { getCloseProps, suppressUserClose } = useModalContext()

  return suppressUserClose ? null : (
    <button
      type="button"
      className="fixed right-6 top-6 z-20 h-8 w-8 cursor-pointer"
      {...getCloseProps()}
    >
      <Icon
        name="XMarkIcon"
        color="info"
        fill="solid"
        className="transition-all duration-200 ease-in-out hover:text-blue-100"
        size="xl"
      />
    </button>
  )
}

export { ModalClose }
