'use client'

import type React from 'react'

import { themeHoverTextColorMap, themeTextColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { type TestIds, getTestProps } from '../../utils'

type TextareaFormToolbarMenuButtonProps = Readonly<
  TestIds & {
    children: React.ReactNode
    label: string
  }
>

const TextareaFormToolbarMenuButton = ({
  children,
  label,
  dataTestId,
  dataTrackingId,
}: TextareaFormToolbarMenuButtonProps) => (
  <div className="flex items-center">
    <button
      type="button"
      className={cn(
        '-m-2.5 flex h-10 w-10 items-center justify-center rounded-full',
        themeTextColorMap.secondary,
        themeHoverTextColorMap.primary
      )}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
      <span className="sr-only">{label}</span>
    </button>
  </div>
)

export {
  TextareaFormToolbarMenuButton,
  type TextareaFormToolbarMenuButtonProps,
}
