import type { SvgIconProps } from '../types/props.ts'

const ColorWheel = (props: SvgIconProps) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    aria-label="Color wheel icon"
    role="img"
    width="1em"
    height="1em"
  >
    <path
      d="M8.9,14.9c0.2-1.1,0.6-2.2,1.3-3.2L5.1,6.7c-2,2.3-3.2,5.2-3.5,8.3H8.9z M11.8,10.3c0.9-0.7,2-1.1,3.2-1.3V1.7
    c-3.1,0.2-6,1.5-8.3,3.5L11.8,10.3z M17.1,1.7v7.2c1.2,0.2,2.3,0.7,3.2,1.4l5.1-5.1C23.1,3.1,20.2,1.9,17.1,1.7z M10.3,20.3
    c-0.7-0.9-1.1-2-1.3-3.2H1.7c0.2,3,1.4,5.9,3.4,8.3L10.3,20.3z M14.9,23.1c-1.1-0.2-2.2-0.6-3.2-1.3l-5.1,5.1c2.3,2,5.2,3.2,8.3,3.5
    V23.1z M21.7,11.8c0.7,0.9,1.1,2,1.3,3.2h7.2C30,11.9,28.8,9,26.8,6.7L21.7,11.8z M20.3,21.7c-0.9,0.7-2,1.1-3.2,1.3v7.2
    c3-0.2,5.9-1.4,8.3-3.4L20.3,21.7z M23.1,17.1c-0.2,1.2-0.6,2.3-1.3,3.2l5.1,5.1c2-2.3,3.3-5.2,3.5-8.3H23.1z"
    />
  </svg>
)

export { ColorWheel }
