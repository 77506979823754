'use client'

import type { ButtonHTMLAttributes, PropsWithChildren } from 'react'

import { Button } from '../../../buttons/button.tsx'

const FooterContentButton = ({
  children,
  ...props
}: PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>) => (
  <Button
    {...props}
    size="lg"
    iconRight="ArrowRightIcon"
    className="w-[336px] min-w-[336px]"
  >
    {children}
  </Button>
)

export { FooterContentButton }
