'use client'

import type { SortDirection } from '@tanstack/react-table'
import type { PropsWithChildren } from 'react'

import { type TestIds, getTestProps } from '../../utils'
import { Icon } from '../icon/icon.tsx'

type TableHeaderCellProps = PropsWithChildren<{
  className?: string
  canSort?: boolean
  direction?: SortDirection | false
  onClick?: (event: unknown) => void
}> &
  TestIds

const TableHeaderCell = ({
  className = '',
  canSort,
  direction,
  children,
  onClick,
  dataTestId,
  dataTrackingId,
}: TableHeaderCellProps) => {
  return (
    <th
      className={`justify-between px-4 font-medium first:rounded-tl-lg last:rounded-tr-lg ${className}`}
      onClick={onClick}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      <div className="flex flex-row items-center gap-2">
        {children}

        {canSort &&
          (direction ? (
            <Icon
              name={direction === 'asc' ? 'ArrowUpIcon' : 'ArrowDownIcon'}
              size="md"
              color="info"
              fill="outline"
              dataTestId={`${dataTestId}-sort-icon`}
              dataTrackingId={`${dataTrackingId}-sort-icon`}
            />
          ) : (
            <Icon
              name="ArrowsUpDownIcon"
              size="md"
              color="info"
              fill="outline"
              dataTestId={`${dataTestId}-sort-icon`}
              dataTrackingId={`${dataTrackingId}-sort-icon`}
            />
          ))}
      </div>
    </th>
  )
}

export { TableHeaderCell, type TableHeaderCellProps }
