'use client'

import type { PropsWithChildren } from 'react'

import { ConfirmationModalButton } from './confirmation-modal-button.tsx'
import { useConfirmationModalContext } from './use-confirmation-modal.tsx'

type ConfirmationModalFooterProps = PropsWithChildren<{
  disabled?: boolean
  closeText: string
  confirmText: string
}>

export const ConfirmationModalFooter = ({
  disabled,
  closeText,
  confirmText,
}: ConfirmationModalFooterProps) => {
  const { getConfirmationModalProps } = useConfirmationModalContext()
  const { onClose, onConfirm } = getConfirmationModalProps()

  return (
    <div className="flex justify-end gap-6 pt-12">
      <ConfirmationModalButton
        disabled={disabled}
        variant="text"
        onClick={onClose}
      >
        {closeText}
      </ConfirmationModalButton>
      <ConfirmationModalButton
        disabled={disabled}
        variant="primary"
        onClick={onConfirm}
      >
        {confirmText}
      </ConfirmationModalButton>
    </div>
  )
}
