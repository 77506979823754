import { cn } from '@mntn-dev/ui-utilities'
import type { ButtonHTMLAttributes } from 'react'

import { themeHoverTextColorMap } from '@mntn-dev/ui-theme'

import { Icon, type IconProps } from '../icon/icon.tsx'
import type { IconHoverColor } from '../icon/types/core.ts'

type IconButtonProps = IconProps &
  Readonly<
    Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
      hoverColor?: IconHoverColor
      centered?: boolean
    }
  >

const IconButton = (props: IconButtonProps) => {
  const { hoverColor, disabled, centered } = props
  return (
    <Icon
      {...props}
      className={cn(
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        hoverColor && !disabled ? themeHoverTextColorMap[hoverColor] : null,
        centered ? 'self-center' : null
      )}
    />
  )
}

export { IconButton, type IconButtonProps }
