'use client'

import type React from 'react'

type ImageCardContentProps = Readonly<{
  children: React.ReactNode
}>

const ImageCardContent = ({ children }: ImageCardContentProps) => {
  return <div className="flex flex-col gap-6 bg-white p-6">{children}</div>
}

export { ImageCardContent, type ImageCardContentProps }
