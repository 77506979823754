'use client'

import { Text, type TextProps } from '../text/index.ts'

type FeedSummaryProps = TextProps

const FeedSummary = ({ children }: FeedSummaryProps) => (
  <Text textColor="tertiary">{children}</Text>
)

export { FeedSummary, type FeedSummaryProps }
