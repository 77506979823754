'use client'

import type { PropsWithChildren } from 'react'

import { RatingLabel } from './rating-label.tsx'
import { RatingRow } from './rating-row.tsx'
import { RatingStars } from './rating-stars.tsx'

type RatingProps = Readonly<PropsWithChildren>

const RatingComponent = ({ children }: RatingProps) => {
  return <div className="my-4 flex flex-col items-center gap-2">{children}</div>
}

const RatingNamespace = Object.assign(RatingComponent, {
  Row: RatingRow,
  Label: RatingLabel,
  Stars: RatingStars,
})

export { RatingNamespace as Rating, RatingComponent, type RatingProps }
