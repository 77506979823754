'use client'

type StatusIconProps = Readonly<{
  src?: string
}>

const StatusIcon = ({ src }: StatusIconProps) => {
  return <img className="pr-4" src={src} alt="status-icon" />
}

export { StatusIcon, type StatusIconProps }
