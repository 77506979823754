'use client'

import type { ThemeTextColor } from '@mntn-dev/ui-theme'

import { Text } from '../../../text/text.tsx'

type FooterContentMessageProps = Readonly<{
  message: string
  textColor: ThemeTextColor
}>

const FooterContentMessage = ({
  message,
  textColor,
}: FooterContentMessageProps) => (
  <span className="flex items-end justify-end">
    <Text fontSize="med" textColor={textColor}>
      {message}
    </Text>
  </span>
)

export { FooterContentMessage }
