'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'

import {
  type ThemeFlexChild,
  type ThemeFlexParent,
  getFlexChildClassName,
  getFlexParentClassName,
} from '@mntn-dev/ui-theme'

import { type RefAttributes, forwardRef } from 'react'
import { type UseSizingProps, useSizing } from '../../hooks/use-sizing.ts'
import { type UseSpacingProps, useSpacing } from '../../hooks/use-spacing.ts'
import { type TestIds, getTestProps } from '../../utils/testing.ts'

type StackProps = Readonly<
  React.PropsWithChildren<
    RefAttributes<HTMLElement> &
      ThemeFlexParent &
      ThemeFlexChild &
      UseSizingProps &
      UseSpacingProps &
      TestIds & { as?: React.ElementType; className?: string }
  >
>

const Stack = forwardRef<HTMLElement, StackProps>(
  (
    {
      as: Component = 'div',
      children,
      className,
      dataTestId,
      dataTrackingId,
      ...props
    }: StackProps,
    ref
  ) => {
    const { widthClassName, heightClassName } = useSizing(props)
    const { marginClassName, paddingClassName } = useSpacing(props)

    return (
      <Component
        className={cn(
          getFlexParentClassName(props),
          getFlexChildClassName(props),
          widthClassName,
          heightClassName,
          marginClassName,
          paddingClassName,
          className
        )}
        ref={ref}
        {...getTestProps({ dataTestId, dataTrackingId })}
      >
        {children}
      </Component>
    )
  }
)

export { Stack, type StackProps }
