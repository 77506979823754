import {
  type AsYouType,
  type ParsedPhoneNumber,
  type PhoneNumberPossibility,
  parsePhoneNumber,
} from 'awesome-phonenumber'
import type { KeyboardEvent } from 'react'

const safePossibilities: PhoneNumberPossibility[] = [
  'unknown',
  'too-short',
  'is-possible',
]

const stripNonNumeric = (value: string) => {
  return value.replace(/\D/g, '')
}

const validateAndUpdateFormatter = (
  formatter: AsYouType,
  e: KeyboardEvent<HTMLInputElement>
) => {
  if (e.key === 'Backspace') {
    formatter.removeChar()
    return true
  }

  // don't allow leading
  if (e.currentTarget.value === '' && e.key === '1') {
    return false
  }

  if (e.key !== 'Backspace') {
    if (e.key !== 'Enter') {
      formatter.addChar(e.key)
    }

    const parsed = formatter.getPhoneNumber()

    // check for valid character, else remove it
    // possibility field tracks whether the number is possibly valid as you type
    if (!(parsed.valid || safePossibilities.includes(parsed.possibility))) {
      formatter.removeChar()

      return false
    }
  }

  return true
}

const getParsedPhoneNumber = (e164PhoneNumber: string): ParsedPhoneNumber => {
  return parsePhoneNumber(e164PhoneNumber, { regionCode: 'US' })
}

const getPhoneObjectFromParsed = (
  parsed: ParsedPhoneNumber
): { label: string; value: string } => {
  if (!parsed.number) {
    return {
      label: '',
      value: '',
    }
  }

  return {
    label: parsed.number.national,
    value: parsed.number.e164,
  }
}

const getPhoneObjectFromPhoneNumber = (e164PhoneNumber: string) => {
  return getPhoneObjectFromParsed(getParsedPhoneNumber(e164PhoneNumber))
}

const getPhoneObjectsFromArray = (e164PhoneNumbers: string[]) => {
  return e164PhoneNumbers
    .map((e164PhoneNumber) => {
      const parsed = getParsedPhoneNumber(e164PhoneNumber)

      if (!parsed.number) {
        return null
      }

      return getPhoneObjectFromParsed(parsed)
    })
    .filter((v) => v !== null)
}

export {
  getParsedPhoneNumber,
  getPhoneObjectFromParsed,
  getPhoneObjectFromPhoneNumber,
  getPhoneObjectsFromArray,
  stripNonNumeric,
  validateAndUpdateFormatter,
}
