import {
  type Position,
  type ThemePositioning,
  positionMapMap,
} from '@mntn-dev/ui-theme'

export function usePositioning(props: ThemePositioning) {
  const positions = Object.keys(props) as Position[]

  const positionClassName = positions.reduce<string>((className, position) => {
    const map = positionMapMap[position]
    const value = props[position]

    if (!value) {
      return className
    }

    return `${className} ${map[value]}`
  }, '')

  return { positionClassName }
}
