'use client'

import { cn } from '@mntn-dev/ui-utilities'
import {
  type ButtonHTMLAttributes,
  type PropsWithChildren,
  forwardRef,
} from 'react'

import { type UseWidthProps, useWidth } from '../../hooks/use-width.ts'
import { type TestIds, getTestProps } from '../../utils/testing.ts'
import { Icon } from '../icon/icon.tsx'
import type { IconName } from '../icon/index.ts'
import type { IconFill } from '../icon/types/core.ts'
import {
  type ButtonSize,
  type ButtonVariant,
  type UseButtonStylesProps,
  buttonSizeNames,
  buttonVariantNames,
  useButtonStyles,
} from './styles.ts'

type ButtonProps = Readonly<
  PropsWithChildren<
    UseWidthProps &
      Partial<UseButtonStylesProps> &
      ButtonHTMLAttributes<HTMLButtonElement> &
      TestIds &
      Partial<{
        disabled: boolean
        iconLeft: IconName
        iconRight: IconName
        iconFill: IconFill
        loading: boolean
        wrap: boolean
      }>
  >
>

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      circular,
      className,
      dataTestId,
      dataTrackingId,
      iconLeft,
      iconRight,
      maxWidth,
      minWidth,
      size = 'md',
      type,
      variant = 'primary',
      width,
      borderColor: borderColorProp,
      textColor: textColorProp,
      iconColor: iconColorProp,
      iconFill = 'solid',
      loading,
      wrap,
      ...props
    },
    ref
  ) => {
    const { buttonStyles, iconColor, iconSize } = useButtonStyles({
      size,
      variant,
      textColor: textColorProp,
      iconColor: iconColorProp,
      borderColor: borderColorProp,
      circular,
    })

    const { widthClassName } = useWidth({ maxWidth, minWidth, width })

    return (
      <button
        type={type ?? 'button'}
        ref={ref}
        {...props}
        {...getTestProps({ dataTestId, dataTrackingId })}
        className={cn(
          widthClassName,
          buttonStyles,
          {
            'text-opacity-30': props.disabled && textColorProp,
            'pointer-events-none': loading,
          },
          !wrap && 'whitespace-nowrap',
          className
        )}
        aria-disabled={props.disabled}
      >
        {iconLeft && (
          <Icon
            fill={iconFill}
            color={iconColor ?? 'current'}
            className={cn({
              'opacity-30': props.disabled && iconColor,
              invisible: loading,
            })}
            name={iconLeft}
            size={iconSize}
          />
        )}

        {children && !circular && (
          <span className={cn({ invisible: loading })}>{children}</span>
        )}
        {loading && !circular && (
          <Icon
            className="absolute"
            color="primary-inverse"
            name="LoadingDots"
            size="2xl"
          />
        )}
        {iconRight && !circular && (
          <Icon
            fill={iconFill}
            color={iconColor ?? 'current'}
            className={cn({
              'opacity-30': props.disabled && iconColor,
              invisible: loading,
            })}
            name={iconRight}
            size={iconSize}
          />
        )}
      </button>
    )
  }
)

export {
  Button,
  buttonSizeNames,
  buttonVariantNames,
  type ButtonProps,
  type ButtonSize,
  type ButtonVariant,
}
