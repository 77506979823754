import type { SvgIconProps } from '../types/props.ts'

const Folder = (props: SvgIconProps) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    aria-label="Folder icon"
    role="img"
    width="1em"
    height="1em"
  >
    <path
      d="M3.8,27.2c-0.8,0-1.4-0.3-2-0.8c-0.5-0.5-0.8-1.2-0.8-2V7.6c0-0.8,0.3-1.4,0.8-2c0.5-0.5,1.2-0.8,2-0.8h8.4L15,7.6h11.2
	c0.8,0,1.4,0.3,2,0.8c0.5,0.5,0.8,1.2,0.8,2H3.8v14l3.3-11.2H31l-3.6,12c-0.2,0.6-0.5,1.1-1,1.4c-0.5,0.4-1.1,0.5-1.7,0.5H3.8z"
    />
  </svg>
)

export { Folder }
