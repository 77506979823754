import type { ThemeBackground, ThemeTextColor } from '@mntn-dev/ui-theme'
import type { TupleToUnion } from 'type-fest'
import type { TestIds } from '../../utils/testing.ts'
import type { IconProps } from '../icon/icon.tsx'

const badgeSizeNames = ['3xs', 'xs', 'sm', 'md', 'lg'] as const
type BadgeSize = TupleToUnion<typeof badgeSizeNames>

const badgeSizeStyleMap: Record<BadgeSize, string> = {
  '3xs': 'h-2 w-2 rounded',
  xs: 'h-4 min-w-4 max-w-fit rounded-lg text-2xs px-[5px]',
  sm: 'h-5 min-w-5 max-w-fit rounded-[10px] text-xs px-1.5',
  md: 'h-6 min-w-6 max-w-fit rounded-xl text-sm px-2',
  lg: 'h-7 min-w-7 max-w-fit rounded-[14px] px-2',
}

type BadgeProps = TestIds & {
  ariaLabel?: string
  backgroundColor?: ThemeBackground
  color?: ThemeTextColor
  decorator?: boolean
  icon?: Pick<IconProps, 'name' | 'fill'>
  size?: BadgeSize
  value?: string | number
}

export { badgeSizeNames, badgeSizeStyleMap, type BadgeProps, type BadgeSize }
