'use client'

import { type PropsWithChildren, useState } from 'react'
import type { TestIds } from '../../utils/testing.ts'
import { CollapsibleButton } from './collapsible-button.tsx'
import { CollapsiblePanel } from './collapsible-panel.tsx'

const Collapsible = ({
  dataTestId,
  dataTrackingId,
  title,
  children,
  isOpen = true,
}: PropsWithChildren<
  {
    title: string
    isOpen?: boolean
  } & TestIds
>) => {
  const [open, setOpen] = useState(isOpen)
  return (
    <div>
      <CollapsibleButton
        dataTestId={`${dataTestId}-button`}
        dataTrackingId={`${dataTrackingId}-button`}
        title={title}
        open={open}
        onClick={() => setOpen(!open)}
      />

      <CollapsiblePanel
        open={open}
        dataTestId={`${dataTestId}-panel`}
        dataTrackingId={`${dataTrackingId}-panel`}
      >
        {children}
      </CollapsiblePanel>
    </div>
  )
}

export { Collapsible }
