'use client'

import { Heading } from '../../heading/heading.tsx'
import { useCardContext } from '../use-card.ts'

type ImageCardTitleProps = Readonly<{
  children: string
}>

const ImageCardTitle = ({ children }: ImageCardTitleProps) => {
  const { getTitleProps } = useCardContext()
  return (
    <Heading className="self-center" {...getTitleProps()}>
      {children}
    </Heading>
  )
}

export { ImageCardTitle, type ImageCardTitleProps }
