'use client'

import {
  type InputHTMLAttributes,
  type RefAttributes,
  forwardRef,
  useEffect,
} from 'react'
import { type UseFormReturn, useFormContext } from 'react-hook-form'

type HiddenProps = Readonly<
  Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> &
    RefAttributes<HTMLInputElement>
>

/** A version of hidden input that synchronizes it's value with any underlying react-hook-form state.  */
const Hidden = forwardRef<HTMLInputElement, HiddenProps>(
  ({ value, name, ...props }: HiddenProps, ref) => {
    const { setValue }: UseFormReturn = useFormContext()

    useEffect(() => {
      if (name) {
        setValue(name, value)
      }
    }, [setValue, name, value])

    return <input ref={ref} type="hidden" {...props} />
  }
)

export { Hidden, type HiddenProps }
