'use client'

import type React from 'react'

import { FormGroupSubmit } from './form-group-submit.tsx'
import { FormGroupProvider, useFormGroup } from './use-form-group.ts'

type FormGroupProps = {
  children: React.ReactNode
}
const FormGroupComponent = ({ children }: FormGroupProps) => {
  const context = useFormGroup({})
  return <FormGroupProvider value={context}>{children}</FormGroupProvider>
}

const FormGroupNamespace = Object.assign(FormGroupComponent, {
  Submit: FormGroupSubmit,
})

export { FormGroupNamespace as FormGroup }
