'use client'

import type React from 'react'

type ImageCardFooterProps = Readonly<{
  children: React.ReactNode
}>

const ImageCardFooter = ({ children }: ImageCardFooterProps) => (
  <div className="align-center flex justify-center">{children}</div>
)

export { ImageCardFooter, type ImageCardFooterProps }
