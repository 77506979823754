'use client'

import type { HTMLProps } from 'react'

export const InfoModalFooter = ({
  className = '',
  ...rest
}: HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className={`mt-6 flex flex-col items-center gap-6 ${className}`}
      {...rest}
    />
  )
}
