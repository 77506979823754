'use client'

import { type ThemeWidthOption, widthMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'
import { MediaCardAsset } from './media-card-asset.tsx'
import { MediaCardMain } from './media-card-main.tsx'
import { MediaCardRemoveButton } from './media-card-remove-button.tsx'
import { MediaCardUploadButton } from './media-card-upload-button.tsx'

type Props = PropsWithChildren<{
  width?: ThemeWidthOption
}>

const MediaCard = ({ children, width }: Props) => {
  return (
    <div className={cn('flex flex-col gap-2', width && widthMap[width])}>
      {children}
    </div>
  )
}

const MediaCardComponent = Object.assign(MediaCard, {
  Asset: MediaCardAsset,
  Main: MediaCardMain,
  RemoveButton: MediaCardRemoveButton,
  UploadButton: MediaCardUploadButton,
})

export { MediaCardComponent as MediaCard }
