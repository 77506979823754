import type { ThemeTextColor } from '@mntn-dev/ui-theme'

import { Icon, type IconName } from '../../icon'

export const ConfirmationModalIcon = ({
  iconName,
  iconColor,
}: {
  iconName: IconName
  iconColor: ThemeTextColor
}) => {
  return <Icon fill="solid" name={iconName} size="2xl" color={iconColor} />
}
