import type { PropsWithChildren } from 'react'

import { BannerBottom } from './banner-bottom.tsx'
import { BannerLeft } from './banner-left.tsx'
import { BannerRight } from './banner-right.tsx'
import { BannerTop } from './banner-top.tsx'

type Props = Readonly<PropsWithChildren>

export const BannerInfo = ({ children }: Props) => {
  return (
    <div className="flex w-full justify-between p-8 max-xl:flex-col xl:gap-12">
      {children}
    </div>
  )
}

BannerInfo.Top = BannerTop
BannerInfo.Bottom = BannerBottom
BannerInfo.Left = BannerLeft
BannerInfo.Right = BannerRight
