'use client'

import type { ThemeGridChild } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'
import { useGridChild } from '../../hooks/use-grid-child.ts'

type DataListItemProps = PropsWithChildren<ThemeGridChild>

const DataListItem = ({ children, ...gridChildProps }: DataListItemProps) => {
  const { gridChildClassName } = useGridChild(gridChildProps)
  return (
    <div
      role="listitem"
      className={cn('flex flex-col gap-2 overflow-hidden', gridChildClassName)}
    >
      {children}
    </div>
  )
}

export { DataListItem }
