'use client'

import type React from 'react'

type FeedToolbarProps = {
  children?: React.ReactNode
}

const FeedToolbar = ({ children }: FeedToolbarProps) => (
  <div className="flex flex-row justify-between">{children}</div>
)

export { FeedToolbar, type FeedToolbarProps }
