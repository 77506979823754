'use client'

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react'
import { cn } from '@mntn-dev/ui-utilities'
import { type ReactNode, useState } from 'react'

import { themeBackgroundBlurMap, themeBackgroundMap } from '@mntn-dev/ui-theme'

import { Icon } from '../../icon/icon.tsx'

type SidebarLayoutSidebarProps = {
  children?: ReactNode
  translations?: {
    closeSidebarLabel?: string
  }
}

const SidebarLayoutSidebar = ({
  children,
  translations,
}: SidebarLayoutSidebarProps) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Dialog open={open} onClose={setOpen} className="relative z-10 lg:hidden">
        <DialogBackdrop
          transition
          className={cn(
            'fixed inset-0 transition-opacity duration-300 ease-linear data-[closed]:opacity-0',
            themeBackgroundMap.container,
            themeBackgroundBlurMap['blur-md']
          )}
        />

        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className="relative mr-16 flex w-full max-w-xs flex-1 transform duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <TransitionChild>
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <button
                  type="button"
                  onClick={handleClose}
                  className="-m-2.5 p-2.5"
                >
                  {translations?.closeSidebarLabel && (
                    <span className="sr-only">Close sidebar</span>
                  )}
                  <Icon name="XMarkIcon" size="lg" color="primary" />
                </button>
              </div>
            </TransitionChild>
            {children}
          </DialogPanel>
        </div>
      </Dialog>

      <div
        className={cn(
          'hidden overflow-x-hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-20 lg:flex-col lg:border-r lg:border-white/10',
          themeBackgroundMap.container,
          themeBackgroundBlurMap['blur-md']
        )}
      >
        {children}
      </div>
    </>
  )
}

export { SidebarLayoutSidebar }
