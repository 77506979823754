import { themeTextColorMap } from '@mntn-dev/ui-theme'
import type { SvgIconProps } from './types/props.ts'
import { iconSizeClassMap } from '../types/maps.ts'

export const CircleIcon = (props: SvgIconProps) => {
  const { color, size, className } = props
  return (
    <svg
      {...props}
      width="6"
      height="6"
      viewBox="0 0 6 6"
      aria-hidden="true"
      role="img"
      className={`${iconSizeClassMap[size]} ${color ? themeTextColorMap[color] : null}  fill-current ${className}`}
    >
      <circle cx={3} cy={3} r={3} />
    </svg>
  )
}
