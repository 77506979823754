'use client'

import type { AbstractImage } from '@mntn-dev/ui-utilities'
import type { Person } from '../../../types/index.ts'
import { type TestIds, getTestProps } from '../../../utils/testing.ts'
import { Avatar, type AvatarBorderColor } from '../../avatar/index.ts'
import { NavigationSelectIndicator } from './navigation-select-indicator.tsx'

type NavigationAvatarItemProps = {
  borderColor?: AvatarBorderColor
  person: Person
  image: AbstractImage
  onClick?: () => void
  selected: () => boolean
} & TestIds

const NavigationAvatarItem = ({
  borderColor = 'info',
  image,
  selected: selectedFn,
  person,
  onClick,
  dataTestId,
  dataTrackingId,
}: NavigationAvatarItemProps) => {
  const selected = selectedFn()

  return (
    <li className="relative inline-flex flex-col items-center">
      <button
        type="button"
        onClick={onClick}
        {...getTestProps({ dataTestId, dataTrackingId })}
      >
        <Avatar size="sm" borderColor={borderColor} selected={selected}>
          <Avatar.User person={person} image={image} />
        </Avatar>
        {selected && <NavigationSelectIndicator />}
      </button>
    </li>
  )
}

export { NavigationAvatarItem, type NavigationAvatarItemProps }
