'use client'

import { themeHoverBackgroundMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { KeyboardEvent } from 'react'
import { Checkbox } from '../input/checkbox.tsx'
import { Text } from '../text/text.tsx'
import {
  type MultiselectOptionItem,
  useMultiselectContext,
} from './use-multiselect.ts'

type MultiselectOptionProps = {
  item: MultiselectOptionItem
  onClick?: () => void
}

const MultiselectOption = ({ item, onClick }: MultiselectOptionProps) => {
  const { selectedItems } = useMultiselectContext()

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (onClick && e.key === 'Space') {
      onClick()
    }
  }

  return (
    <div
      className={cn(
        'p-3 flex justify-start items-center gap-2.5 min-w-[500px] cursor-pointer',
        themeHoverBackgroundMap.tertiary
      )}
      onKeyDown={handleKeyDown}
      onClick={onClick}
    >
      <div className="grow-0 shrink-0 w-fit">
        <Checkbox
          value={
            !!selectedItems?.find((selectedItem) => selectedItem.id === item.id)
          }
          className="pointer-events-none"
          onChange={onClick}
        />
      </div>
      <Text>{item.name}</Text>
    </div>
  )
}

export { MultiselectOption }
