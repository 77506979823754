'use client'

import {
  type ThemeFlexJustifyContent,
  type ThemeWidthOption,
  themeBorderColorMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'
import { Stack } from '../../../stack/stack.tsx'

type FooterContentGroupProps = PropsWithChildren<{
  justifyContent?: ThemeFlexJustifyContent
  width?: ThemeWidthOption
}>

const FooterContentGroup = ({
  children,
  justifyContent,
  width,
}: FooterContentGroupProps) => (
  <Stack
    width={width}
    alignItems="center"
    justifyContent={justifyContent}
    className={cn(
      '[&:not(:first-child)]:border-l [&:not(:first-child)]:pl-10 [&:not(:first-child)]:ml-10',
      themeBorderColorMap.medium
    )}
  >
    {children}
  </Stack>
)

export { FooterContentGroup }
