'use client'

import type React from 'react'

import { type ThemeBackground, themeDivideColorMap } from '@mntn-dev/ui-theme'

import { Surface } from '../surface/surface.tsx'

type FeedItemListProps = Readonly<{
  backgroundColor?: ThemeBackground
  children: React.ReactNode
}>

const FeedItemList = ({
  backgroundColor = 'container-secondary',
  children,
}: FeedItemListProps) => (
  <Surface border backgroundColor={backgroundColor}>
    <ul className={`flex flex-col divide-y ${themeDivideColorMap.muted}`}>
      {children}
    </ul>
  </Surface>
)

export { FeedItemList, type FeedItemListProps }
