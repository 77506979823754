'use client'

import { cn } from '@mntn-dev/ui-utilities'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'

import { type LineClamp, lineClampMap } from '@mntn-dev/ui-theme'

import { Text, type TextProps } from './text.tsx'

type TruncatedTextProps = Readonly<{
  text: string
  lines: LineClamp
  t: (key: 'read-more') => string
  onReadMoreClicked: () => void
}> &
  Omit<TextProps, 'children'>

const ellipsis = '... '

const calculateMaxChars = (
  text: string,
  element: HTMLElement,
  lines: number,
  ellipsis: string
) => {
  const elementStyle = getComputedStyle(element)

  const lineHeight = Number.parseFloat(elementStyle.lineHeight)
  const containerHeight = lines * lineHeight
  let tempText = text

  // Clone the container for measurement
  const tempElement = element.cloneNode(true) as HTMLElement
  tempElement.style.position = 'absolute'
  tempElement.style.whiteSpace = 'pre-wrap'
  tempElement.style.width = elementStyle.width
  document.body.appendChild(tempElement)

  tempElement.innerText = tempText

  while (tempElement.scrollHeight > containerHeight) {
    tempText = tempText.slice(0, -1)
    tempElement.innerText = `${tempText}${ellipsis}`
  }

  document.body.removeChild(tempElement)

  return tempText.length
}

const TruncatedText = ({
  text: originalText,
  lines,
  t,
  className,
  onReadMoreClicked,
  ...props
}: TruncatedTextProps) => {
  const textRef = useRef<HTMLSpanElement>(null)
  const [truncatedText, setTruncatedText] = useState<string | undefined>(
    undefined
  )

  const readMoreText = useMemo(() => t('read-more'), [t])

  useLayoutEffect(() => {
    if (textRef.current && truncatedText === undefined) {
      const lineHeight = Number.parseFloat(
        getComputedStyle(textRef.current).lineHeight
      )
      const maxHeight = lineHeight * lines

      if (textRef.current.scrollHeight > maxHeight) {
        const maxChars = calculateMaxChars(
          originalText,
          textRef.current,
          lines,
          `${ellipsis}${readMoreText}`
        )

        setTruncatedText(`${originalText.slice(0, maxChars)}${ellipsis}`)
      }
    }
  }, [truncatedText, lines, originalText, readMoreText])

  return (
    <Text
      ref={textRef}
      {...props}
      className={cn(className, `${lineClampMap[lines]} whitespace-pre-line`)}
    >
      {truncatedText || originalText}
      {truncatedText ? (
        <Text onClick={onReadMoreClicked} textColor="info">
          {readMoreText}
        </Text>
      ) : null}
    </Text>
  )
}

export { TruncatedText, type TextProps }
