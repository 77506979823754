import type { AbstractLink } from '@mntn-dev/ui-utilities'
import { type TestIds, getTestProps } from '../../utils'

type BackButtonProps = TestIds & {
  link: AbstractLink
  href: string
}
const BackButton = ({
  dataTestId,
  dataTrackingId,
  link: Link,
  href,
}: BackButtonProps) => {
  return (
    <li {...getTestProps({ dataTestId, dataTrackingId })}>
      <div>
        <Link href={href} className="text-brand-blue hover:text-gray-500">
          <svg
            className="fill-brand-blue mr-2 h-5 w-5 shrink-0"
            viewBox="0 0 24 25"
            role="img"
            aria-label="chevron"
          >
            <path d="M16.9678 5.61605V3.80433C16.9678 3.6473 16.7873 3.56058 16.6655 3.65668L6.09984 11.909C6.01007 11.9788 5.93743 12.0682 5.88746 12.1704C5.8375 12.2725 5.81152 12.3848 5.81152 12.4985C5.81152 12.6122 5.8375 12.7244 5.88746 12.8266C5.93743 12.9287 6.01007 13.0181 6.09984 13.0879L16.6655 21.3403C16.7897 21.4364 16.9678 21.3496 16.9678 21.1926V19.3809C16.9678 19.2661 16.9139 19.1559 16.8248 19.0856L8.38734 12.4996L16.8248 5.91137C16.9139 5.84105 16.9678 5.7309 16.9678 5.61605Z" />
          </svg>
        </Link>
      </div>
    </li>
  )
}

export { BackButton }
