'use client'

import type { PropsWithChildren } from 'react'

import { ConfirmationModalClose } from './confirmation-modal-close.tsx'

export const ConfirmationModalHeader = ({ children }: PropsWithChildren) => {
  return (
    <div className="relative flex-col justify-between">
      <div className="flex items-center gap-2 text-left">{children}</div>
      <ConfirmationModalClose />
    </div>
  )
}
