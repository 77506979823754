'use client'

import type { PropsWithChildren } from 'react'
import { type TestIds, getTestProps } from '../../utils'

type TableCellProps = PropsWithChildren<{
  className?: string
  colspan?: number
}> &
  TestIds

const TableCell = ({
  className = '',
  colspan = 1,
  children,
  dataTestId,
  dataTrackingId,
}: TableCellProps) => {
  return (
    <td
      colSpan={colspan}
      className={`justify-between px-4 font-medium group-last/table-row:first:rounded-bl-lg group-last/table-row:last:rounded-br-lg ${className}`}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
    </td>
  )
}

export { TableCell, type TableCellProps }
