'use client'

import { Heading, type HeadingProps } from '../heading'
import { ModalHeadingBreadcrumb } from './modal-heading-breadcrumb.tsx'
import { ModalHeadingBreadcrumbs } from './modal-heading-breadcrumbs.tsx'

type ModalHeadingProps = Readonly<HeadingProps>

const ModalHeading = ({
  children,
  fontSize = '4xl',
  ...props
}: ModalHeadingProps) => {
  return (
    <Heading fontSize={fontSize} textColor="primary" {...props}>
      {children}
    </Heading>
  )
}

const ModalHeadingNamespace = Object.assign(ModalHeading, {
  Breadcrumbs: ModalHeadingBreadcrumbs,
  Breadcrumb: ModalHeadingBreadcrumb,
})

export { ModalHeadingNamespace as ModalHeading, type ModalHeadingProps }
