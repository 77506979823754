import { themeIconColorMap } from '@mntn-dev/ui-theme'
import { iconSizeClassMap } from '../types/maps.ts'
import type { SvgIconProps } from './types/props.ts'

const EditIcon = ({ size, color, className, ...props }: SvgIconProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      aria-label="Edit icon"
      role="img"
      width="1em"
      height="1em"
      className={`${iconSizeClassMap[size]} ${color ? themeIconColorMap[color] : null}  fill-current ${className}`}
    >
      <path d="M3.5,12.2s0,0,.1,0l2.9-.5s0,0,0,0l7.4-7.4s0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0l-2.9-2.9s0,0-.1,0,0,0-.1,0l-7.4,7.4s0,0,0,0l-.5,2.9c0,0,0,.2,0,.3,0,0,0,.2.1.2.1.1.3.2.4.2ZM4.7,9.1l6.3-6.3,1.3,1.3-6.3,6.3-1.6.3.3-1.6ZM14.4,13.7H1.6c-.3,0-.6.3-.6.6v.6c0,0,0,.1.1.1h13.7c0,0,.1,0,.1-.1v-.6c0-.3-.3-.6-.6-.6Z" />
    </svg>
  )
}

export { EditIcon }
