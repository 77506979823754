'use client'

import React, { useRef } from 'react'

import {
  themeBackgroundMap,
  themeDisabledTextColorMap,
  themeFocusRingColorMap,
  themeHoverRingColorMap,
  themePlaceholderTextColorMap,
  themeRingColorMap,
  themeTextColorMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { mergeRefs } from '@mntn-dev/utilities'
import type { TestIds } from '../../utils/testing.ts'
import { TextareaFormToolbarActionButton } from './textarea-form-toolbar-action-button.tsx'
import { TextareaFormToolbarAction } from './textarea-form-toolbar-action.tsx'
import { TextareaFormToolbarMenuButton } from './textarea-form-toolbar-menu-button.tsx'
import { TextareaFormToolbarMenu } from './textarea-form-toolbar-menu.tsx'
import { TextareaFormToolbar } from './textarea-form-toolbar.tsx'
import {
  TextareaFormProvider,
  type UseTextareaFormProps,
  useTextareaForm,
} from './use-textarea-form.tsx'

type TextareaFormProps = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'onSubmit'
> &
  TestIds &
  UseTextareaFormProps & {
    children: React.ReactNode
    label: string
  }

const TextareaForm = React.forwardRef<HTMLTextAreaElement, TextareaFormProps>(
  (
    {
      children,
      dataTestId,
      dataTrackingId,
      id: idProp,
      hasError: hasErrorProp,
      hasSuccess: hasSuccessProp,
      hasWarning: hasWarningProp,
      label,
      readOnly,
      disabled,
      onSubmit,
      ...textAreaProps
    },
    ref
  ) => {
    const context = useTextareaForm({
      dataTestId,
      dataTrackingId,
      id: idProp,
      hasError: hasErrorProp,
      hasSuccess: hasSuccessProp,
      hasWarning: hasWarningProp,
      disabled,
      onSubmit,
      readOnly,
    })

    const {
      id,
      hasError,
      hasSuccess,
      hasWarning,
      getTextareaProps,
      onFormSubmit,
    } = context

    const textareaRef = useRef<HTMLTextAreaElement>(null)

    const mergedRefs = mergeRefs(ref, textareaRef)

    const { rows = 3 } = textAreaProps
    const moreTextAreaProps = getTextareaProps(mergedRefs)

    const borderOverridden = hasError || hasSuccess || hasWarning
    return (
      <TextareaFormProvider value={context}>
        <form className="relative flex-auto" onSubmit={onFormSubmit}>
          <div
            className={cn(
              'overflow-hidden rounded shadow-sm ring-1 ring-inset focus-within:ring-2',
              themeBackgroundMap['container-secondary'],
              {
                [cn(
                  themeRingColorMap.muted,
                  themeHoverRingColorMap.muted,
                  themeFocusRingColorMap.muted
                )]: !borderOverridden,
                [cn(
                  themeRingColorMap.positive,
                  themeFocusRingColorMap.positive
                )]: hasSuccess,
                [cn(
                  themeRingColorMap.negative,
                  themeFocusRingColorMap.negative
                )]: hasError,
                [cn(themeRingColorMap.caution, themeFocusRingColorMap.caution)]:
                  hasWarning,
              }
            )}
          >
            <label htmlFor={id} className="sr-only">
              {label}
            </label>
            <textarea
              {...textAreaProps}
              rows={rows}
              {...moreTextAreaProps}
              className={cn(
                'block w-full resize-none border-0 bg-transparent py-1.5 text-slate-900 placeholder:text-slate-400 focus:ring-0 sm:text-sm sm:leading-6',
                themePlaceholderTextColorMap.primary,
                themeTextColorMap.primary,
                themeDisabledTextColorMap.primary
              )}
            />

            {/* Spacer element to match the height of the toolbar */}
            {context.hasToolbarMenu && (
              <div className="py-2" aria-hidden="true">
                {/* Matches height of button in toolbar (1px border + 36px content height) */}
                <div className="py-px">
                  <div className="h-9" />
                </div>
              </div>
            )}
          </div>
          {children}
        </form>
      </TextareaFormProvider>
    )
  }
)

const TextareaFormNamespace = Object.assign(TextareaForm, {
  Toolbar: TextareaFormToolbar,
  ToolbarMenu: TextareaFormToolbarMenu,
  ToolbarMenuButton: TextareaFormToolbarMenuButton,
  ToolbarAction: TextareaFormToolbarAction,
  ToolbarActionButton: TextareaFormToolbarActionButton,
})

export { TextareaFormNamespace as TextareaForm, type TextareaFormProps }
