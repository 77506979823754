'use client'

import type { PropsWithChildren } from 'react'

type StatusTitleProps = PropsWithChildren<
  Readonly<{
    title?: string
  }>
>

const StatusTitle = ({ children, title }: StatusTitleProps) => {
  return (
    <span className="text-slate-500">
      <div className="font-bold">{title}</div>
      {children}
    </span>
  )
}

export { StatusTitle, type StatusTitleProps }
