'use client'

import { themeBackgroundMap, themeTextColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { type ChangeEvent, type KeyboardEvent, useState } from 'react'
import { fontSizeMap } from '../../classes/font-size.ts'
import { Icon } from '../icon/icon.tsx'
import { useMultiselectContext } from './use-multiselect.ts'

type MultiselectSearchProps = {
  placeholder?: string
}

const MultiselectSearch = ({ placeholder }: MultiselectSearchProps) => {
  const { onSearch } = useMultiselectContext()
  const [searchValue, setSearchValue] = useState('')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()

      onSearch(searchValue)
    }
  }

  return (
    <div className="relative overflow-hidden w-full">
      <input
        type="text"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        className={cn(
          fontSizeMap.sm,
          themeBackgroundMap.container,
          themeTextColorMap.primary,
          'leading-6 p-3 pr-11 w-full border-none'
        )}
      />
      <Icon
        name="MagnifyingGlassIcon"
        size="md"
        color="info"
        className="absolute right-3 top-1/2 transform -translate-y-1/2"
      />
    </div>
  )
}

export { MultiselectSearch }
