'use client'

import type React from 'react'

import { getTestProps, type TestIds } from '../../utils'

type CardsProps = Readonly<
  TestIds & {
    children: React.ReactNode
  }
>

const Cards = ({ children, dataTestId, dataTrackingId }: CardsProps) => {
  return (
    <ul
      className="flex flex-wrap justify-center gap-8"
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
    </ul>
  )
}

export { Cards, type CardsProps }
