import type { PropsWithChildren } from 'react'

import {
  themeBackgroundMap,
  themeDivideColorMap,
  type ThemeBackground,
} from '@mntn-dev/ui-theme'

type TableBodyProps = PropsWithChildren<{
  className?: string
  background?: ThemeBackground | null
}>

const TableBody = ({
  className = '',
  background = 'container-secondary',
  children,
}: TableBodyProps) => {
  return (
    <tbody
      className={`${background ? themeBackgroundMap[background] : ''} divide-y ${themeDivideColorMap.muted} ${className}`}
    >
      {children}
    </tbody>
  )
}

export { TableBody, type TableBodyProps }
