import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'

import {
  type ThemeFlexChild,
  type ThemeFlexParent,
  getFlexChildClassName,
  getFlexParentClassName,
} from '@mntn-dev/ui-theme'

import {
  type UseSizingProps,
  type UseSpacingProps,
  useSizing,
  useSpacing,
} from '../../hooks'

type BladeColumnProps = React.PropsWithChildren<
  ThemeFlexChild & ThemeFlexParent & UseSizingProps & UseSpacingProps
>

const BladeColumn = ({ children, ...props }: BladeColumnProps) => {
  const flexChildClassName = getFlexChildClassName(props)
  const flexParentClassName = getFlexParentClassName(props)
  const { widthClassName } = useSizing({
    ...props,
  })
  const { marginClassName, paddingClassName } = useSpacing(props)

  return (
    <div
      className={cn(
        flexChildClassName,
        flexParentClassName,
        widthClassName,
        paddingClassName,
        marginClassName
      )}
    >
      {children}
    </div>
  )
}

export { BladeColumn, type BladeColumnProps }
