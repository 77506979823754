import type { SvgIconProps } from './types/props.ts'

export const ErrorIcon = (props: SvgIconProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      aria-label="error icon"
      role="img"
    >
      <rect width="24" height="24" rx="6" fill="#FF3333" />
      <path
        d="M15 9.00002L9 15M8.99997 9L14.9999 15"
        stroke="black"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
