'use client'

import { cn } from '@mntn-dev/ui-utilities'

import type { AbstractImage, AbstractImageProps } from '@mntn-dev/ui-utilities'

type InfoModalImageProps = AbstractImageProps &
  Readonly<{
    image: AbstractImage
  }>

export const InfoModalImage = ({
  className,
  image: Image,
  ...rest
}: InfoModalImageProps) => {
  return <Image className={cn('rounded opacity-90', className)} {...rest} />
}
