import type { VideoDuration } from '@mntn-dev/domain-types'
import { Fragment } from 'react'
import { Text } from '../../text/text.tsx'
import { BladeMediaDurationTime } from './blade-media-duration-time.tsx'

export const BladeMediaDuration = ({
  duration,
  timeInterval,
  t,
}: {
  duration: VideoDuration
  timeInterval: string
  t: (key: 'or') => string
}) => {
  const durationArray = Array.isArray(duration) ? duration : [duration]

  return durationArray.map((time, index) => (
    <Fragment key={`duration-${index + 1}`}>
      <BladeMediaDurationTime time={time} timeInterval={timeInterval} />
      {index !== durationArray.length - 1 && (
        <Text
          fontSize="xs"
          textColor="secondary"
          textTransform="uppercase"
          fontWeight="semibold"
          className="px-0.5"
        >
          {t('or')}
        </Text>
      )}
    </Fragment>
  ))
}
