'use client'

import { Modal, type ModalProps } from '../modal.tsx'
import { ConfirmationModalButton } from './confirmation-modal-button.tsx'
import { ConfirmationModalClose } from './confirmation-modal-close.tsx'
import { ConfirmationModalFooter } from './confirmation-modal-footer.tsx'
import { ConfirmationModalHeader } from './confirmation-modal-header.tsx'
import { ConfirmationModalIcon } from './confirmation-modal-icon.tsx'
import { ConfirmationModalText } from './confirmation-modal-text.tsx'
import { ConfirmationModalTitle } from './confirmation-modal-title.tsx'
import {
  ConfirmationModalProvider,
  useConfirmationModal,
} from './use-confirmation-modal.tsx'

type ConfirmationModalProps = Readonly<
  ModalProps & {
    onConfirm: () => void
  }
>

const ConfirmationModal = ({
  children,
  onClose,
  onConfirm,
  ...rest
}: ConfirmationModalProps) => {
  const context = useConfirmationModal({
    onClose: onClose,
    onConfirm: onConfirm,
  })

  return (
    <ConfirmationModalProvider value={context}>
      <Modal onClose={onClose} suppressUserClose {...rest}>
        <Modal.Surface className="w-[480px]">
          <Modal.Surface.Body className="w-[480px]">
            {children}
          </Modal.Surface.Body>
        </Modal.Surface>
      </Modal>
    </ConfirmationModalProvider>
  )
}

const ConfirmationModalNamespace = Object.assign(ConfirmationModal, {
  Button: ConfirmationModalButton,
  Close: ConfirmationModalClose,
  Content: ConfirmationModalText,
  Footer: ConfirmationModalFooter,
  Header: ConfirmationModalHeader,
  Icon: ConfirmationModalIcon,
  Title: ConfirmationModalTitle,
})

export { ConfirmationModalNamespace as ConfirmationModal }
