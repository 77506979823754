import { themeTextColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { Icon } from '../icon/icon.tsx'
import { useFormFieldContext } from './use-form-field.ts'

type FormFieldWarningProps = Readonly<{
  children?: string
}>

const FormFieldWarning = ({ children }: FormFieldWarningProps) => {
  const context = useFormFieldContext()

  if (!context?.hasWarning) {
    return null
  }

  const warningProps = context?.getWarningProps() ?? {}

  return (
    <div className="flex gap-1 items-center">
      <Icon
        name="ExclamationCircleIcon"
        color="caution"
        fill="solid"
        size="sm"
      />
      <p {...warningProps} className={cn('text-sm', themeTextColorMap.caution)}>
        {children}
      </p>
    </div>
  )
}
export { FormFieldWarning, type FormFieldWarningProps }
