import type { FontSize } from '../types/font.ts'

export const fontSizeMap: Record<FontSize, string> = {
  inherit: 'text-[length:inherit]',
  '3xs': 'text-3xs',
  '2xs': 'text-2xs',
  xs: 'text-xs',
  sm: 'text-sm',
  med: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-3xl',
  '4xl': 'text-4xl',
  '5xl': 'text-5xl',
  display: 'text-display',
  displayXl: 'text-displayXl',
}
