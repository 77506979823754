'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'

import { type PageBackground, pageBackgroundMap } from '@mntn-dev/ui-theme'

type CenteredLayoutProps = Readonly<{
  background?: PageBackground
  children?: React.ReactNode
}>

const CenteredLayout = ({ background, children }: CenteredLayoutProps) => (
  <div
    className={cn(
      'flex h-screen w-full items-center justify-center overflow-y-auto',
      { [`${pageBackgroundMap[background || 'default']} bg-cover`]: background }
    )}
  >
    <main className="z-10 flex h-full min-w-fit max-w-7xl flex-col items-center justify-center text-center">
      {children}
    </main>
  </div>
)

export { CenteredLayout, type CenteredLayoutProps }
