'use client'

import { cn } from '@mntn-dev/ui-utilities'
import { type HTMLAttributes, forwardRef } from 'react'

import { uuid } from '@mntn-dev/utilities'

import { type TestIds, getTestProps } from '../../utils/testing.ts'

type ProgressCircleProps = Readonly<
  HTMLAttributes<HTMLElement> &
    TestIds & {
      disabled?: boolean
      fullWidth?: boolean
      completed?: number
      progressCircleCount: number
    }
>

const ProgressCircles = forwardRef<HTMLElement, ProgressCircleProps>(
  (
    {
      completed = 0,
      progressCircleCount,
      dataTestId,
      dataTrackingId,
      fullWidth,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <nav
        aria-label="Progress"
        ref={ref}
        {...getTestProps({ dataTestId, dataTrackingId })}
      >
        <ol className="flex items-center gap-4">
          {[...Array(progressCircleCount)].map((_, i) => (
            <li
              key={uuid()}
              className={cn(
                {
                  'cursor-not-allowed opacity-70': props.disabled,
                  'w-full': fullWidth,
                },
                className
              )}
            >
              {i < completed ? (
                <div className="shadow-glow-blue relative flex items-center justify-center rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 25"
                    fill="currentColor"
                    className="h-6 w-6"
                    role="img"
                    aria-label="filled-circle"
                  >
                    <path
                      opacity="0.8"
                      d="M24 12.5C24 14.8734 23.2962 17.1934 21.9776 19.1668C20.6591 21.1402 18.7849 22.6783 16.5922 23.5865C14.3995 24.4948 11.9867 24.7324 9.65892 24.2694C7.33115 23.8064 5.19295 22.6635 3.51472 20.9853C1.83649 19.307 0.693605 17.1689 0.230582 14.8411C-0.232441 12.5133 0.00519943 10.1005 0.913451 7.9078C1.8217 5.71508 3.35977 3.84094 5.33316 2.52236C7.30655 1.20379 9.62663 0.5 12 0.5C15.1816 0.50336 18.2319 1.76872 20.4816 4.01843C22.7313 6.26814 23.9966 9.31843 24 12.5Z"
                      fill="black"
                      fillOpacity="0.25"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="11"
                    viewBox="0 0 14 11"
                    fill="none"
                    className="absolute"
                    role="img"
                    aria-label="check"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.7363 0.79413C13.1262 1.18534 13.1251 1.8185 12.7339 2.20834L4.70587 10.2083C4.31515 10.5977 3.68294 10.5971 3.29289 10.2071L0.292893 7.20711C-0.0976311 6.81658 -0.0976311 6.18342 0.292893 5.79289C0.683417 5.40237 1.31658 5.40237 1.70711 5.79289L4.00123 8.08702L11.3221 0.791659C11.7133 0.401818 12.3465 0.402924 12.7363 0.79413Z"
                      fill="#41C0FF"
                    />
                  </svg>
                </div>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 25"
                  fill="none"
                  className="relative flex h-6 w-6 items-center justify-center"
                  role="img"
                  aria-label="dot"
                >
                  <circle
                    cx="12"
                    cy="12.5"
                    r="11"
                    stroke="black"
                    strokeOpacity="0.25"
                    strokeWidth="2"
                  />
                </svg>
              )}
            </li>
          ))}
        </ol>
      </nav>
    )
  }
)

export { ProgressCircles, type ProgressCircleProps }
