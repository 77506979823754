import type { SvgIconProps } from './types/props.ts'

export const VideoIcon = (props: SvgIconProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      aria-label="video icon"
      role="img"
    >
      <path
        d="M82.22 52.67L58.22 36.67C57.6175 36.268 56.9171 36.0371 56.1936 36.0021C55.4701 35.967 54.7506 36.1291 54.1121 36.471C53.4735 36.8129 52.9397 37.3217 52.5677 37.9432C52.1958 38.5648 51.9995 39.2757 52 40V72C51.9995 72.7243 52.1958 73.4352 52.5677 74.0567C52.9397 74.6783 53.4735 75.1871 54.1121 75.529C54.7506 75.8709 55.4701 76.033 56.1936 75.9979C56.9171 75.9629 57.6175 75.732 58.22 75.33L82.22 59.33C82.7687 58.9649 83.2186 58.4698 83.5298 57.8889C83.841 57.3079 84.0039 56.6591 84.0039 56C84.0039 55.3409 83.841 54.6921 83.5298 54.1111C83.2186 53.5302 82.7687 53.0351 82.22 52.67ZM60 64.525V47.5L72.79 56L60 64.525ZM108 20H20C17.8783 20 15.8434 20.8429 14.3431 22.3431C12.8429 23.8434 12 25.8783 12 28V84C12 86.1217 12.8429 88.1566 14.3431 89.6569C15.8434 91.1571 17.8783 92 20 92H108C110.122 92 112.157 91.1571 113.657 89.6569C115.157 88.1566 116 86.1217 116 84V28C116 25.8783 115.157 23.8434 113.657 22.3431C112.157 20.8429 110.122 20 108 20ZM108 84H20V28H108V84ZM116 104C116 105.061 115.579 106.078 114.828 106.828C114.078 107.579 113.061 108 112 108H16C14.9391 108 13.9217 107.579 13.1716 106.828C12.4214 106.078 12 105.061 12 104C12 102.939 12.4214 101.922 13.1716 101.172C13.9217 100.421 14.9391 100 16 100H112C113.061 100 114.078 100.421 114.828 101.172C115.579 101.922 116 102.939 116 104Z"
        fill="#41C0FF"
      />
    </svg>
  )
}
