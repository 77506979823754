'use client'

import type React from 'react'

type TextareaFormToolbarActionProps = Readonly<{
  children: React.ReactNode
}>

const TextareaFormToolbarAction = ({
  children,
}: TextareaFormToolbarActionProps) => <div className="shrink-0">{children}</div>

export { TextareaFormToolbarAction, type TextareaFormToolbarActionProps }
