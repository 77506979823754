import type { SvgIconProps } from './types/props.ts'

export const CelebrationIcon = (props: SvgIconProps) => {
  return (
    <svg
      {...props}
      width="504"
      height="185"
      viewBox="0 0 504 185"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Celebration Icon"
      role="img"
    >
      <circle
        cx="241.349"
        cy="94.298"
        r="89.8488"
        fill="url(#paint0_linear_30274_140375)"
        fillOpacity="0.5"
      />
      <circle
        cx="242.612"
        cy="93.0379"
        r="75.9774"
        fill="url(#paint1_linear_30274_140375)"
      />
      <g clipPath="url(#clip0_30274_140375)">
        <g filter="url(#filter0_d_30274_140375)">
          <path
            d="M188.5 164.834L227.775 48.2012L305.345 125.481L188.5 164.834Z"
            fill="url(#paint2_radial_30274_140375)"
          />
          <path
            d="M305.33 125.478L188.513 164.821L227.778 48.2161L305.33 125.478Z"
            stroke="url(#paint3_linear_30274_140375)"
            strokeOpacity="0.3"
            strokeWidth="0.0161565"
          />
        </g>
        <path
          d="M298.075 126.701C293.853 126.701 289.05 125.683 283.82 123.637C273.349 119.539 261.985 111.684 251.819 101.519C241.654 91.3529 233.799 79.9882 229.702 69.5175C225.1 57.7595 225.696 48.1543 231.38 42.4713C237.063 36.788 246.668 36.1922 258.426 40.7932C268.897 44.8903 280.262 52.7453 290.427 62.9111C300.593 73.0768 308.447 84.4415 312.545 94.9122C317.146 106.67 316.55 116.275 310.867 121.958C307.711 125.114 303.346 126.701 298.075 126.701Z"
          fill="url(#paint4_linear_30274_140375)"
        />
        <path
          d="M244.183 47.3594C241.545 47.3594 239.477 47.999 238.191 49.2851C235.463 52.0132 235.643 58.2644 238.673 66.0067C242.296 75.2672 249.384 85.4593 258.631 94.7061C267.877 103.953 278.069 111.04 287.33 114.664C295.073 117.694 301.323 117.874 304.052 115.145C306.78 112.417 306.6 106.166 303.57 98.4236C299.946 89.163 292.858 78.971 283.612 69.7242C274.365 60.4778 264.174 53.3899 254.913 49.7665C250.819 48.1646 247.143 47.3594 244.183 47.3594Z"
          fill="url(#paint5_linear_30274_140375)"
        />
        <path
          d="M274.942 85.2046L268.129 78.3916C274.321 72.1993 280.581 71.9972 285.613 71.8348C289.957 71.6945 293.096 71.5932 296.594 68.0951C300.092 64.5976 300.193 61.4586 300.333 57.1148C300.495 52.0843 300.697 45.8235 306.889 39.6315C313.081 33.4395 319.341 33.2374 324.372 33.0753C328.716 32.9354 331.855 32.8342 335.353 29.3367C338.85 25.8388 338.952 22.7001 339.092 18.356C339.254 13.3255 339.456 7.06501 345.648 0.873047L352.461 7.68609C348.963 11.1836 348.862 14.3226 348.722 18.6664C348.56 23.6969 348.358 29.9577 342.166 36.1497C335.974 42.3417 329.713 42.5438 324.682 42.7059C320.339 42.8458 317.2 42.9471 313.702 46.4445C310.204 49.9424 310.103 53.0811 309.963 57.4252C309.801 62.4557 309.599 68.7165 303.407 74.9085C297.215 81.1008 290.954 81.3029 285.923 81.4653C281.579 81.6053 278.44 81.7061 274.942 85.2046Z"
          fill="#41C0FF"
        />
        <path
          d="M258.892 66.4779C257.685 62.3023 256.814 59.2856 259.062 54.8794C261.31 50.4735 264.264 49.4091 268.353 47.9356C273.088 46.2296 278.981 44.1063 282.961 36.3064C286.941 28.5068 285.202 22.4887 283.805 17.6533C282.598 13.478 281.726 10.461 283.974 6.05509L275.391 1.67578C271.411 9.47571 273.15 15.4934 274.548 20.3289C275.755 24.5041 276.627 27.5212 274.379 31.9271C272.131 36.333 269.176 37.3974 265.087 38.8708C260.352 40.5768 254.459 42.7001 250.479 50.5C246.499 58.3003 248.238 64.318 249.636 69.1531C250.843 73.3287 251.715 76.3454 249.467 80.7513C249.09 81.4903 248.685 82.1424 248.248 82.7331C250.14 85.2809 252.217 87.8177 254.458 90.3098C255.848 88.8274 257.039 87.1098 258.049 85.131C262.029 77.3307 260.289 71.313 258.892 66.4779Z"
          fill="#09A4DC"
        />
        <path
          d="M308.666 104.611C312.607 107.741 317.515 111.638 326.239 110.92C334.967 110.203 339.17 105.559 342.548 101.828C345.464 98.6054 347.572 96.2771 352.502 95.8725L351.713 86.2695C342.985 86.9864 338.782 91.6303 335.404 95.3619C332.488 98.5843 330.38 100.913 325.45 101.317C320.521 101.722 318.061 99.7694 314.658 97.0663C310.717 93.9362 305.813 90.0393 297.084 90.7574C288.357 91.4743 284.153 96.1182 280.775 99.8498C277.994 102.923 275.943 105.178 271.484 105.732C274.888 108.2 278.304 110.331 281.652 112.069C284.276 110.337 286.204 108.209 287.919 106.316C290.835 103.093 292.943 100.765 297.873 100.36C302.804 99.9546 305.262 101.908 308.666 104.611Z"
          fill="#0F6F95"
        />
        <path
          d="M252.705 29.1166C256.252 29.1166 259.128 26.2407 259.128 22.6931C259.128 19.1455 256.252 16.2695 252.705 16.2695C249.157 16.2695 246.281 19.1455 246.281 22.6931C246.281 26.2407 249.157 29.1166 252.705 29.1166Z"
          fill="#0F6F95"
        />
        <path
          d="M340.916 128.794C344.463 128.794 347.339 125.918 347.339 122.371C347.339 118.823 344.463 115.947 340.916 115.947C337.368 115.947 334.492 118.823 334.492 122.371C334.492 125.918 337.368 128.794 340.916 128.794Z"
          fill="#0F6F95"
        />
        <path
          d="M327.857 78.9076C331.405 78.9076 334.281 76.0317 334.281 72.4841C334.281 68.9365 331.405 66.0605 327.857 66.0605C324.31 66.0605 321.434 68.9365 321.434 72.4841C321.434 76.0317 324.31 78.9076 327.857 78.9076Z"
          fill="#75D1FF"
        />
        <path
          d="M313.404 22.6947C316.952 22.6947 319.828 19.8188 319.828 16.2712C319.828 12.7236 316.952 9.84766 313.404 9.84766C309.856 9.84766 306.98 12.7236 306.98 16.2712C306.98 19.8188 309.856 22.6947 313.404 22.6947Z"
          fill="#09A4DC"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_30274_140375"
          x="188.241"
          y="48.1365"
          width="117.363"
          height="117.15"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.193878" />
          <feGaussianBlur stdDeviation="0.129252" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0569704 0 0 0 0 0.642802 0 0 0 0 0.539026 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_30274_140375"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_30274_140375"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_30274_140375"
          x1="202.924"
          y1="42.6128"
          x2="277.645"
          y2="115.736"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_30274_140375"
          x1="210.119"
          y1="49.3322"
          x2="273.305"
          y2="111.166"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.2" />
          <stop offset="1" stopOpacity="0.1" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_30274_140375"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(246.922 164.834) rotate(-90) scale(116.633 74.8252)"
        >
          <stop stopColor="#41C0FF" />
          <stop offset="1" stopColor="#003752" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_30274_140375"
          x1="246.922"
          y1="48.2012"
          x2="246.922"
          y2="164.834"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_30274_140375"
          x1="245.519"
          y1="86.5374"
          x2="308.14"
          y2="133.143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0F6F95" />
          <stop offset="1" stopColor="#41C0FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_30274_140375"
          x1="271.121"
          y1="50.9319"
          x2="271.121"
          y2="124.081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#08192B" />
          <stop offset="0.8" stopColor="#09A4DC" />
        </linearGradient>
        <clipPath id="clip0_30274_140375">
          <rect
            width="164"
            height="164"
            fill="white"
            transform="translate(188.5 0.853516)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
