import type { SvgIconProps } from '../types/props.ts'

const Bell = (props: SvgIconProps) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    aria-label="Bell icon"
    role="img"
    width="1em"
    height="1em"
  >
    <path
      d="M25.5,24h-0.8V13.4c0-4.4-3.3-8.1-7.5-8.7V3.5c0-0.7-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2v1.2C10.5,5.3,7.2,9,7.2,13.4
	V24H6.5c-0.6,0-1,0.4-1,1v1c0,0.1,0.1,0.2,0.2,0.2h6.8c0,1.9,1.6,3.5,3.5,3.5c1.9,0,3.5-1.6,3.5-3.5h6.8c0.1,0,0.2-0.1,0.2-0.2v-1
	C26.5,24.4,26.1,24,25.5,24z M16,27.8c-0.8,0-1.5-0.7-1.5-1.5h3C17.5,27.1,16.8,27.8,16,27.8z"
    />
  </svg>
)

export { Bell }
