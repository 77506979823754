'use client'

import {
  createContext as createReactContext,
  useContext as useReactContext,
} from 'react'

type CreateContextOptions = {
  name: string
}

export function createOptionalContext<T>({ name }: CreateContextOptions) {
  const reactContext = createReactContext<T | undefined>(undefined)

  reactContext.displayName = name

  function useOptionalContext() {
    const context = useReactContext(reactContext)
    return context
  }

  return <const>[reactContext.Provider, useOptionalContext]
}

export function createContext<T>(options: CreateContextOptions) {
  const [Provider, useOptionalContext] = createOptionalContext<T>(options)

  function useRequiredContext() {
    const context = useOptionalContext()

    if (!context) {
      const error = new Error(
        `Context ${options.name} is missing, but required`
      )
      error.name = 'ContextMissingError'
      throw error
    }

    return context
  }

  return <const>[Provider, useRequiredContext]
}
