import { themeTextColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { Icon } from '../icon/icon.tsx'
import { useFormFieldContext } from './use-form-field.ts'

type FormFieldSuccessProps = Readonly<{
  children?: string
}>

const FormFieldSuccess = ({ children }: FormFieldSuccessProps) => {
  const context = useFormFieldContext()

  if (!context?.hasSuccess) {
    return null
  }

  const successProps = context?.getSuccessProps() ?? {}

  return (
    <div className="flex gap-1 items-center">
      <Icon name="CheckCircleIcon" color="positive" fill="solid" size="sm" />
      <p
        {...successProps}
        className={cn('text-sm', themeTextColorMap.positive)}
      >
        {children}
      </p>
    </div>
  )
}
export { FormFieldSuccess, type FormFieldSuccessProps }
