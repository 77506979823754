import type { SvgIconProps } from './types/props.ts'

export const InfoIcon = (props: SvgIconProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      aria-label="info icon"
      role="img"
    >
      <rect width="24" height="24" rx="6" fill="#41C0FF" />
      <path
        d="M12 16V11"
        stroke="black"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle
        cx="1"
        cy="1"
        r="1"
        transform="matrix(1 0 0 -1 11 9)"
        fill="black"
        fillOpacity="0.9"
      />
    </svg>
  )
}
