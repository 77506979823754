import type { SvgIconProps } from './types/props.ts'

export const WarningIcon = (props: SvgIconProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      aria-label="warning icon"
      role="img"
    >
      <rect width="24" height="24" rx="6" fill="#E6C319" />
      <path
        d="M12 9V14"
        stroke="black"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="12" cy="17" r="1" fill="black" fillOpacity="0.9" />
    </svg>
  )
}
