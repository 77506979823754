import type { SvgIconProps } from './types/props.ts'

export const SuccessIcon = (props: SvgIconProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      aria-label="success icon"
      role="img"
    >
      <rect width="24" height="24" rx="6" fill="#1FC38F" />
      <path
        d="M8.5 12.5L10.5 14.5L15.5 9.5"
        stroke="black"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
