import { cn } from '@mntn-dev/ui-utilities'

import {
  themeBackgroundMap,
  themeDisabledRingColorMap,
  themeDisabledTextColorMap,
  themeFocusRingColorMap,
  themeHoverRingColorMap,
  themePlaceholderTextColorMap,
  themeReadonlyRingColorMap,
  themeRingColorMap,
  themeTextColorMap,
} from '@mntn-dev/ui-theme'
import type { UseFormFieldProps } from '../form-field/use-form-field.ts'

export const getInputStyles = (
  {
    disabled,
    readOnly,
    hasError,
    hasSuccess,
    hasWarning,
  }: Pick<
    UseFormFieldProps,
    'disabled' | 'readOnly' | 'hasError' | 'hasSuccess' | 'hasWarning'
  > = {
    disabled: false,
    readOnly: false,
    hasError: false,
    hasSuccess: false,
    hasWarning: false,
  }
) => {
  const borderOverridden =
    hasError || hasSuccess || hasWarning || disabled || readOnly
  return cn(
    'flex h-12 w-full leading-6 rounded text-base p-3 outline-none border-none font-medium items-center self-stretch justify-start disabled:placeholder:invisible aria-readonly:placeholder:invisible',
    themeTextColorMap.primary,
    themeBackgroundMap['container-secondary'],
    themePlaceholderTextColorMap.primary,
    themeDisabledTextColorMap.primary,
    themeDisabledRingColorMap.muted,
    themeReadonlyRingColorMap.muted,
    {
      'ring-1 ring-inset focus:ring-inset': !readOnly,
      [cn(
        themeRingColorMap.muted,
        themeHoverRingColorMap.muted,
        themeFocusRingColorMap.muted
      )]: !borderOverridden,
      [cn(themeRingColorMap.positive, themeFocusRingColorMap.positive)]:
        hasSuccess,
      [cn(themeRingColorMap.negative, themeFocusRingColorMap.negative)]:
        hasError,
      [cn(themeRingColorMap.caution, themeFocusRingColorMap.caution)]:
        hasWarning,
    }
  )
}

export function getPlainTextInputStyles(invalid?: boolean) {
  const sharedClasses =
    'bg-transparent outline-0 ring-0 focus:outline-none focus:border focus:ring-0 focus:ring-offset-0'
  if (invalid) {
    return `border border-primary-red focus:border-primary-red ${sharedClasses}`
  }
  return `border-0 focus:border-none ${sharedClasses} `
}
