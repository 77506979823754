'use client'

import { Heading, type HeadingProps } from '../heading'

type ModalSubheadingProps = Readonly<HeadingProps>

const ModalSubheading = ({ children, ...props }: ModalSubheadingProps) => {
  return (
    <Heading fontSize="xl" textColor="info" {...props}>
      {children}
    </Heading>
  )
}

export { ModalSubheading, type ModalSubheadingProps }
