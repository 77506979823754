'use client'

import { cn } from '@mntn-dev/ui-utilities'
import React, {
  type ElementType,
  type HTMLAttributes,
  type PropsWithChildren,
  type ReactElement,
} from 'react'
import { type TextOptions, getTextBaseClasses } from '../../classes/text.ts'
import type {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
  TextSize,
} from '../../types/index.ts'
import { type TestIds, getTestProps } from '../../utils/index.ts'

type TextProps = PropsWithChildren<
  Pick<HTMLAttributes<HTMLElement>, 'className' | 'onClick'> &
    TestIds &
    Omit<TextOptions, 'fontSize'> & {
      fontSize?: TextSize
    }
>

type TextPropsWithRef<T extends ElementType> = PolymorphicComponentPropsWithRef<
  T,
  TextProps
>

type TextComponent = <T extends ElementType = 'span'>(
  props: TextPropsWithRef<T>
) => ReactElement | null

const Text = React.forwardRef(
  <T extends ElementType = 'span'>(
    {
      as,
      children,
      dataTestId,
      dataTrackingId,
      className,
      onClick,
      ...textOptions
    }: TextPropsWithRef<T>,
    ref: PolymorphicRef<T>
  ) => {
    const Component = as || 'span'

    return (
      <Component
        ref={ref}
        className={cn(
          'whitespace-pre-wrap',
          getTextBaseClasses({
            ...textOptions,
            textColor: textOptions.textColor ?? 'primary',
            fontSize: textOptions.fontSize ?? 'sm',
            fontWeight: textOptions.fontWeight ?? 'normal',
          }),
          'leading-normal',
          className,
          onClick ? 'cursor-pointer' : ''
        )}
        {...getTestProps({ dataTestId, dataTrackingId })}
        onClick={onClick}
      >
        {children}
      </Component>
    )
  }
) as TextComponent

export { Text, type TextProps }
