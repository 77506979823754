import type React from 'react'

import { Button } from '../buttons/button.tsx'
import { useFormGroupContext } from './use-form-group.ts'

type FormGroupSubmitProps = {
  children: React.ReactNode
  groupName?: string
}

const FormGroupSubmit = ({
  children,
  groupName = 'default',
}: FormGroupSubmitProps) => {
  const { onSubmit } = useFormGroupContext()

  const handleClick = () => {
    onSubmit(groupName)
  }

  return <Button onClick={handleClick}>{children}</Button>
}

export { FormGroupSubmit }
