'use client'

import { type ForwardedRef, useImperativeHandle, useRef } from 'react'
import ReactDatePicker, { type ReactDatePickerProps } from 'react-datepicker'

import { forwardRef } from '../../utils/index.ts'
import {
  type UseFormFieldControlProps,
  useFormFieldControl,
} from '../form-field/index.ts'
import { Icon } from '../icon/index.ts'
import { getInputStyles } from './get-input-styles.ts'
import { Input } from './input.tsx'

type DatePickerProps = Readonly<
  UseFormFieldControlProps<HTMLInputElement> & ReactDatePickerProps
>

const DatePicker = forwardRef<
  HTMLInputElement & {
    focus(): void
  },
  DatePickerProps
>(
  (
    {
      selected,
      disabled,
      readOnly,
      id,
      hasError: hasErrorProp,
      dataTestId,
      dataTrackingId,
      ...datePickerProps
    },
    ref
  ) => {
    const { hasError, ...fieldProps } = useFormFieldControl<HTMLInputElement>({
      disabled,
      readOnly,
      id,
      hasError: hasErrorProp,
      dataTestId,
      dataTrackingId,
    })

    const datePickerRef = useRef<ReactDatePicker>(null)

    // In order for the focus logic to work on validation with react-hook-form, this ref needs to be extended with special focus logic.
    // The form crashes and gets stuck in a frozen state otherwise. The solution was discovered here -> https://github.com/react-hook-form/react-hook-form/issues/9126
    useImperativeHandle(
      ref as ForwardedRef<{
        focus(): void
      }>,
      () => {
        return {
          focus() {
            datePickerRef?.current?.setFocus()
          },
        }
      },
      []
    )

    // todo: QF-998 - fix ref if we decide to keep this library
    return (
      <div className="relative">
        <div className="*:w-full">
          <ReactDatePicker
            className={getInputStyles({ hasError })}
            selected={selected}
            {...datePickerProps}
            {...fieldProps}
            ref={datePickerRef}
            customInput={
              <Input dataTestId={dataTestId} dataTrackingId={dataTrackingId} />
            }
          />
        </div>
        <span className="pointer-events-none absolute right-4 top-4">
          <Icon fill="solid" name="CalendarIcon" size="sm" color="info" />
        </span>
      </div>
    )
  }
)

export { DatePicker, type DatePickerProps }
