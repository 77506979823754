'use client'

import type { PropsWithChildren } from 'react'

import { Text } from '../../text'

export const ConfirmationModalText = ({ children }: PropsWithChildren) => {
  return (
    <span className="flex py-2 text-left">
      <Text fontSize="sm" textColor="secondary">
        {children}
      </Text>
    </span>
  )
}
