'use client'

import { useCallback, useState } from 'react'

export type UseOpenStateProps = {
  initialOpen?: boolean
}

export function useOpenState(props?: UseOpenStateProps) {
  const [open, setOpen] = useState(props?.initialOpen ?? false)

  const onOpen = useCallback(() => setOpen(true), [])

  const onClose = useCallback(() => setOpen(false), [])

  const onToggle = useCallback(() => setOpen((prev) => !prev), [])

  return {
    onClose,
    onOpen,
    onToggle,
    open,
  }
}
