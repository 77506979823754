'use client'

import type { ReactNode } from 'react'
import { RadioGroup } from '@headlessui/react'

import { getTestProps, type TestIds } from '../../utils'
import { RadioBlocksItem } from './radio-blocks-item.tsx'

export type RadioBlocksProps = Readonly<
  TestIds & {
    children: Array<ReactNode>
    disabled?: boolean
    value?: string | null
    onChange: (value: string) => void
  }
>

const RadioBlocks = ({
  disabled,
  value,
  children,
  dataTestId,
  dataTrackingId,
  onChange,
}: RadioBlocksProps) => {
  return (
    <RadioGroup
      className="flex flex-col gap-4"
      disabled={disabled}
      value={value}
      onChange={onChange}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
    </RadioGroup>
  )
}

RadioBlocks.Item = RadioBlocksItem

export { RadioBlocks }
