import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'

import type { AnyValue } from '@mntn-dev/utility-types'
import { type TestIds, getTestProps } from '../../utils'

// needed to allow dynamic row identifier for data test ids
type RowType = {
  [key: string]: AnyValue
}

type TableRowProps = PropsWithChildren<
  { className?: string; onClick?: (row: RowType) => void } & TestIds
>

const TableRow = ({
  className = '',
  children,
  onClick,
  dataTestId,
  dataTrackingId,
}: TableRowProps) => {
  return (
    <tr
      className={cn(
        `group/table-row ${className}`,
        onClick ? 'cursor-pointer' : ''
      )}
      onClick={onClick}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
    </tr>
  )
}

export { TableRow, type TableRowProps, type RowType }
